import * as React from "react";
// import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

export interface Props {
    onSubmit: (direction: string) => unknown;
    sortKey?: string;
    optionMenu?: JSX.Element;
}

const MinimumMenu = styled((props: MenuProps) => <Menu {...props} />)(({ theme }) => ({
    // '.MuiButton-root': {
    "#schedul-article-menu-button": {
        minWidth: 24,
    },
    "& .MuiPaper-root": {
        borderRadius: 2,
        minWidth: 200,
        fontSize: 14,

        "& .MuiList-root": {
            padding: 0,
        },
        "& .MuiDivider-root": {
            marginTop: 0,
            marginBottom: 0,
        },
        "& .optionmenu": {
            marginTop: "16px",
        },
        "& h5": {
            margin: 0,
            padding: 16,
            lineHeight: 1,
            fontWeight: 400,
            fontSize: 14,
        },
    },
}));

// TODO: リストが長いと最下部のボタンが見切れる > ダイアログの表示開始位置を取得して開始位置分高さを縮める

export const ArticleSortMenu = ({ onSubmit, sortKey, optionMenu }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    // const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };
    // 各ボタンの処理
    const handleSortAsc = () => {
        onSubmit("asc");
        close();
    };
    const handleSortDesc = () => {
        onSubmit("desc");
        close();
    };

    const sortMenu = (sortKey:string, order:string) => {
        if(sortKey === "value" && order === "asc"){return "昇順 ( A→Z )";}
        else if(sortKey === "value" && order === "desc"){return "降順 ( Z→A )";}
        else if(sortKey === "id" && order === "asc"){return "ID昇順 ( 1→99 )";}
        else if(sortKey === "id" && order === "desc"){return "ID降順 ( 99→1 )";}
        else return "";
    };

    return (
        <div>
            <Button
                id="schedul-article-menu-button"
                aria-controls={open ? "schedul-article--menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            ></Button>
            <MinimumMenu
                id="schedul-article--menu"
                MenuListProps={{
                    "aria-labelledby": "schedul-article-menu-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={close}
            >
                {sortKey && <> 
                    <h5>並び替え</h5>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={handleSortAsc}>{sortMenu(sortKey, "asc")}</MenuItem>
                    <MenuItem onClick={handleSortDesc}>{sortMenu(sortKey, "desc")}</MenuItem>
                </>}
                {optionMenu && <> 
                    {sortKey && <Divider sx={{ my: 0.5 }} className="optionmenu" />}
                    <h5>フィルター</h5>
                    <Divider sx={{ my: 0.5 }} />
                    {optionMenu}                         
                </>}
            </MinimumMenu>
        </div>
    );
};
