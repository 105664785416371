import { useGetSchemaTypeDefinitionsQuery } from "../gateways/cms/schemaApi";
import { SelectTypeFilter } from "./SelectFilter";

export interface Props {
    value: string[];
    onChange: (value: string[]) => void;
}

/*** コンテンツ管理用のコンテンツスキーマ取得 ***/
export function SelectArticleTypeFilter({ value, onChange }: Props) {
    const SELECT_ARTICLE_TYPE = 0; // 一覧表示で利用できるコンテンツタイプを取得するためのID
    const articleTypes = useGetSchemaTypeDefinitionsQuery(SELECT_ARTICLE_TYPE);
    const filters = articleTypes.data?.map((t) => ({ value: String(t.id), label: t.title, checkDefault: t.checkDefault })) || [];

    return <SelectTypeFilter options={filters} value={value} onChange={onChange} />;
}
