import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { UserGroup } from "./user";

export const userGroupApi = createApi({
    reducerPath: "userGroupApi",
    baseQuery,
    endpoints: (builder) => ({
        getUserGroups: builder.query<UserGroup[], void>({
            query: () => ({ url: "usergroup/list" }),
        }),
    }),
});

export const { useGetUserGroupsQuery } = userGroupApi;
