import { useSearchParams } from "react-router-dom";

import { styled } from "@mui/system";
import { ChangeEvent } from "react";
import Button from "@mui/material/Button";
import iconPager from "../assets/icons/icon_pager.svg";

// style
const PaginationWrapper = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    .setPage {
        margin: 0 8px;

        input {
            height: 32px;
            width: 40px;
            padding: 4px;
            margin-right: 4px;
            text-align: right;
            border: 1px solid #daddde;
            border-radius: 2px;
        }
    }
`;
const ButtonSq = styled(Button)`
    display: block;
    height: 32px;
    width: 32px;
    min-width: 32px !important;
    max-width: 32px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    border-radius: 2px;

    background: transparent url(${iconPager}) top left no-repeat scroll;
    color: transparent !important;
    overflow: hidden;
    user-select: none;
    font-size: 0 !important;

    &.prev {
        transform: rotate(180deg);
    }
    &.next {
    }
    &.Mui-disabled {
        opacity: 0.3;
    }
`;

interface Props {
    meta: {
        current_page: number;
        last_page: number;
        total: number;
    };
}

export function PaginationLinks({ meta }: Props) {
    const [searchParams, setSearchParams] = useSearchParams();

    const onFirstPage = meta.current_page === 1;
    const onLastPage = meta.current_page === meta.last_page;

    const handleChange = (page: number) => () => {
        searchParams.set("page", String(page));
        setSearchParams(searchParams);
    };

    const handleSelectPage = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) return;
        searchParams.set("page", e.target.value);
        setSearchParams(searchParams);
    };

    return (
        <PaginationWrapper>
            {!onFirstPage ? (
                <ButtonSq type="button" className="prev" onClick={handleChange(meta.current_page - 1)}>
                    &lt;
                </ButtonSq>
            ) : (
                <ButtonSq type="button" className="prev" disabled>
                    &lt;
                </ButtonSq>
            )}
            <div className="setPage">
                <input
                    type="text"
                    defaultValue={meta.current_page}
                    value={meta.current_page}
                    min="1"
                    max={meta.last_page}
                    onChange={handleSelectPage}
                />
                / {meta.last_page}
            </div>
            {!onLastPage ? (
                <ButtonSq type="button" className="next" onClick={handleChange(meta.current_page + 1)}>
                    &gt;
                </ButtonSq>
            ) : (
                <ButtonSq type="button" className="next" disabled>
                    &gt;
                </ButtonSq>
            )}
        </PaginationWrapper>
    );
}
