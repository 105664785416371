import { ReactNode } from "react";
import { Link, To, useLocation } from "react-router-dom";

export interface Props {
    to: To;
    children?: ReactNode;
}

export function ModalLink({ to, children }: Props) {
    const location = useLocation();
    return (
        <Link to={to} state={{ backgroundLocation: location }}>
            {children}
        </Link>
    );
}
