import { addDays, format, parse } from "date-fns";
import { useState } from "react";
import { useSelector } from "react-redux";
import ReactLoading from 'react-loading';
import { DeliveryRuleDashboard } from "../../components/DeliveryRuleDashboard";
//import { useGetOrganizationsQuery } from "../../gateways/cms/organizationApi";
//import { useGetDeliveryRulesQuery, useGetDeliveryRuleOrganizationsQuery } from "../../gateways/cms/deliveryRuleApi";
import { useGetDeliveryRulesQuery } from "../../gateways/cms/deliveryRuleApi";
import { selectOrganizationId } from "../../features/cms/organizationBreadcrumbSlice";
import MainLayout from "../../layouts/MainLayout";
import Stack from "@mui/material/Stack";
import ButtonMUI from "@material-ui/core/Button";

import { styled } from "@mui/system";
import iconCircle from "../../assets/icons/icon_schedule_circle.svg";
import iconCross from "../../assets/icons/icon_schedule_cross.svg";
import iconTriangle from "../../assets/icons/icon_schedule_triangle.svg";
import iconMinus from "../../assets/icons/icon_schedule_minus.svg";
import iconCalendar from "../../assets/icons/icon_calendar.svg";


// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
    .legend{
        display:flex;
        align-items: center;
        margin: 0;

        dt{
            height: 32px;
            width: 32px;
            margin-left: 1em;
            background: transparent top left no-repeat scroll ;
            background-attachment: scroll;
            background-color: transparent;
            color: transparent;
            overflow: hidden;
            user-select: none;

            &:nth-of-type(1){background-image: url(${iconCircle});}
            &:nth-of-type(2){background-image: url(${iconCross});}
            &:nth-of-type(3){background-image: url(${iconTriangle});}
            &:nth-of-type(4){background-image: url(${iconMinus});}
        }
        dd{
            margin-left: 0.25em;
        }
    }
`;
const PageMain = styled("section")`
    display: flex;
    flex-direction: column;
    padding: 0 24px 0;
    overflow: hidden;

    & > main{
        overflow: auto;
    }
`;
const BarList = styled("section")`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;
const InputDate = styled("input")`
    padding: 4px;

    &::-webkit-calendar-picker-indicator {
        background: transparent url(${iconCalendar}) center center no-repeat scroll;
        cursor: pointer;
        border-radius: 4px;

        &:hover{
            background-color: #00000018
        }
    }
`;
const Loading = styled("section")`
    position: relative;
    left: 45%;
    top: 35%;
    b {
        font-size: 10px;
        color: #A9A9A9;
        font-weight: normal;
    }
`;

export function DeliveryRuleDashboardPage() {
    const organizationId = Number(useSelector(selectOrganizationId)) || 1;
   // const organizations = useGetOrganizationsQuery();
   // const organizations = useGetDeliveryRuleOrganizationsQuery(organizationId);
    const [date, setDate] = useState(new Date());
    const fromDate = format(date, "yyyy-MM-dd");
    const toDate = format(addDays(date, 30), "yyyy-MM-dd");
    const deliveryRules = useGetDeliveryRulesQuery({ organizationId, fromDate, toDate });
    //console.log(organizations);

    if (!deliveryRules.data) {
        return (
            <MainLayout title="稼働スケジュール管理">
                <Loading>
                    <ReactLoading type="bars" color="#ADD8E6" height={'-1%'} width={'7%'} />
                    <b>稼働スケジュール取得中</b>
                </Loading>
            </MainLayout>
        );
    };

    return (
        <MainLayout title="稼働スケジュール管理">
            <PageTitle>
                <h1>稼働スケジュール管理</h1>

                <dl className="legend">
                    <dt>○</dt>
                    <dd>… 常にモニターON(標準スケジュール間)</dd>
                    <dt>✕</dt>
                    <dd>… 終日モニターOFF</dd>
                    <dt>△</dt>
                    <dd>… モニター一時停止</dd>
                    {/* <dt>－</dt>
                    <dd>… スケジュール未設定</dd> */}
                </dl>
            </PageTitle>

            <PageMain>
                <BarList>
                    <Stack spacing={1} direction="row">
                        <InputDate
                            type="date"
                            value={format(date, "yyyy-MM-dd")}
                            onChange={(e) => setDate(parse(e.target.value, "yyyy-MM-dd", new Date()))}
                        />
                        <Button type="button" variant="outlined" onClick={() => setDate(new Date())}>
                            今日から表示
                        </Button> 
                    </Stack>
                </BarList>

                <main>
                {deliveryRules.data.map((deliveryRule) => (
                    <DeliveryRuleDashboard key={deliveryRule.organizationId} deliveryRule={deliveryRule} fromDate={fromDate} toDate={toDate} />
                ))}
                </main>
            </PageMain>
        </MainLayout>
    );
}
