//import { addDays, format } from "date-fns";
// import { Link } from "react-router-dom";
import { DeliveryRule, DeliveryTerminalSchedule } from "../gateways/cms/deliveryRule";
//import { useGetDeliveryRulesQuery } from "../gateways/cms/deliveryRuleApi";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";
import iconEdit from "../assets/icons/icon_pen.svg";
import iconCircle from "../assets/icons/icon_schedule_circle.svg";
import iconCross from "../assets/icons/icon_schedule_cross.svg";
import iconTriangle from "../assets/icons/icon_schedule_triangle.svg";
import iconMinus from "../assets/icons/icon_schedule_minus.svg";

// [稼働スケジュール管理] スケジュール部分

export interface Props {
    deliveryRule: DeliveryRule;
    fromDate: String;
    toDate: String;
}

// style
const ScheduleHeader = styled("header")`
     display: flex;

     div{
         min-width: calc(10em + 14em + 10em);

         position: sticky;
         background-color: #fff;
         z-index: 1;
         left: 0;
     }
     ul{
         display: flex;
         /* width: 100%; */
         /* margin-left: calc(10em + 14em + 10em + 4px); */

         li{
             /* width: 48px; */
             /* max-width: 48px; */
             min-width: 48px;
             text-align: center;
             font-size: 95%;
         }
     }
 `;
 const Organization = styled("section")`

     position: relative;
     overflow: auto;
     margin-bottom: 24px;

     main{
         display: flex;

         h2, h3, h4{
             margin: 0;
             font-size: 1em;
             font-weight: normal;
             display: flex;
             align-items: center;
             line-height: 1.2;

             position: sticky;
             z-index: 2;

             &::before{
                 content: "";
                 display: block;
                 left: 0;
                 top: 0;
                 position: absolute;
                 background-color: #fff;
                 height: 100%;
                 width: 100%;

                 border-left: 1px solid #ccc;
                 border-bottom: 1px solid #ccc;

             }

             b{
                 display: block;
                 font-weight: normal;
                 z-index: 1;
             }
         }
         h2, h3{
             flex-direction: column;
             justify-content: center;
             padding: 0 8px;

             b{
                 margin-top: calc(32px - 1em);
             }
         }
         h2{
             width: 10em;
             max-width: 10em;
             min-width: 10em;

             left: 0;
             border-top: 1px solid #ccc;
         }
         h3{
             width: 14em;
             max-width: 14em;
             min-width: 14em;

             left: 10em;
         }
         h4{
             justify-content: space-between;
             width: 10em;
             max-width: 10em;
             min-width: 10em;
             padding: 0 8px;
             
             left: 24em;
             border-right: 1px solid #ccc;
         }
         ul{
             display: flex;
             flex-direction: column;

             li{
                 display: flex;
                 height: 100%;

                 &:first-of-type{
                     h3::before,
                     li:first-of-type h4::before,
                     li:first-of-type ul li{
                         border-top: 1px solid #ccc;
                     }
                 }
                 &:last-of-type{
                     border-bottom: 0 none transparent;
                 }

                 ul li{
                     min-height: 48px;

                     ul {
                         position: relative;
                         flex-direction: row;
                         height: 48px;
                         max-height: 48px;
     
                         li{
                             width: 48px;
                             min-width: 48px;

                             &.dateCell{
                                 border-width: 0 0 0 1px;
                                 border-top: 1px solid #ccc;
                                 border-right: 1px solid #ccc;
                                 border-bottom: 1px solid #ccc;

                                 &.today{
                                     background-color: #F0F5FF;
                                 }
                             }
                             &.scheduleCell{
                                 position: absolute;
                                 top: 0;

                                 background: transparent no-repeat scroll center center;
                                 background-attachment: scroll;
                                 background-color: transparent;
                                 background-size: 66.6%;
                                 border-width: 0;
                                 color: transparent;
                                 overflow: hidden;
                                 user-select: none;

                                 &.scheduleType-1{ background-image: url(${iconCircle}); }
                                 &.scheduleType-2{ background-image: url(${iconCross}); }
                                 &.scheduleType-3{ background-image: url(${iconTriangle}); }
                                 &.scheduleType-4{ background-image: url(${iconMinus}); }
                             }
                             dl{
                                &.legend{
                                    display:flex;
                                    align-items: center;
                                    margin: 0;

                                    dt{
                                        height: 32px;
                                        width: 32px;
                                        margin-left: 0.5em;
                                        background: transparent top left no-repeat scroll ;
                                        background-attachment: scroll;
                                        background-color: transparent;
                                        color: transparent;
                                        overflow: hidden;
                                        user-select: none;

                                        &.scheduleType-1{ background-image: url(${iconCircle}); }
                                        &.scheduleType-2{ background-image: url(${iconCross}); }
                                        &.scheduleType-3{ background-image: url(${iconTriangle}); }
                                        &.scheduleType-4{ background-image: url(${iconMinus}); }
                                    }
                                    dd{
                                        margin-left: 0.25em;
                                    }
                                }
                            }
                         }
                     }
                 }
             }
         }
     }
 `;
 const DeliveryGroup = styled("li")`
     ul{
     }
 `;
 const Terminal = styled("li")`
 `;
 const ButtonEdit = styled(ButtonMUI)`
     &.MuiButtonBase-root{
         height: 32px;
         width: 32px;
         max-width: 32px;
         min-width: 32px;
         background: transparent url(${iconEdit}) scroll no-repeat center center;
         background-size: 75%;
         overflow: hidden;
         user-select: none;

         *{
             color: transparent;
             white-space: nowrap;
         }
     }
 `;

export function DeliveryRuleDashboard({ deliveryRule, fromDate, toDate }: Props) {
    const getDeliveryTerminalSchedule = (terminalSchedule: DeliveryTerminalSchedule) => {
        if(terminalSchedule.schedule === 0) return "scheduleType-1";
        else if(terminalSchedule.schedule === 1) return "scheduleType-2";
        else if(terminalSchedule.schedule === 2) return "scheduleType-3";
        else if(terminalSchedule.schedule === 3) return "scheduleType-4";
        return "";
    }

    let todayDate = new Date();
    todayDate.setHours(0);
    todayDate.setMinutes(0);
    todayDate.setSeconds(0);
    let today = new Date(todayDate).getTime(); 
    let baseCell = new Date(fromDate +" 00:00:00").getTime();
    let lastCell = new Date(toDate +" 23:59:59" ).getTime(); 
    
    // TODO: 画面内に何日表示するか不明なので30日分
    let GridCells = Math.round(( lastCell - baseCell ) / 86400000);
    let todayCell = Math.round(( today - baseCell ) / 86400000) + 1;

    return (
        <>
            {deliveryRule && (
                // 店舗
                <Organization>
                    <ScheduleHeader>
                        <div></div>
                        <ul>
                            {/* 日数分の枠を表示 */}
                            {(() => {
                                const cells = [];
                                let cullentDate = new Date(fromDate +" 00:00:00");
                                for (let i = 1; i <= GridCells; i++) {
                                    cells.push(<li className={`dateCell cell${i}`}>{cullentDate.getMonth()+1 +"/"+ cullentDate.getDate()}</li>);
                                    cullentDate.setDate( cullentDate.getDate() + 1 );
                                }
                                return <>{ cells }</>;
                            })()}
                        </ul>
                    </ScheduleHeader>
                    <main>
                        <h2>
                            <b>{deliveryRule.name}</b>
                            <ButtonEdit type="button" href={`/delivery-rules/organizations/${deliveryRule.organizationId}`}>編集</ButtonEdit>
                        </h2>

                        <ul>
                        {/* 配信グループ */}
                        {deliveryRule.deliveryGroup.map((g) => (
                            <DeliveryGroup key={g.deliveryGroupId}>
                                <h3>
                                    <b>{g.name}</b>
                                    <ButtonEdit type="button" href={`/delivery-rules/delivery-groups/${g.deliveryGroupId}`}>編集</ButtonEdit>
                                </h3>

                                <ul>
                                {/* 端末 */}
                                {g.terminal.map((t) => (
                                    <Terminal key={t.terminalId}>
                                        <h4>
                                            <b>{t.name}</b>
                                            <ButtonEdit type="button" href={`/delivery-rules/terminals/${t.terminalId}`}>編集</ButtonEdit>
                                        </h4>
                                        <ul>
                                            {/* TODO:<確認> 表示開始日が先頭にならないことはあるのか */}
                                            {/* TODO:<確認> 画面内に何日表示するか */}
                                            {/* TODO: 当日青表示 (何番目が当日か計算しておく) */}

                                            {/* fromDate の日付から GridCells 分のマスを表示 */}
                                            {(() => {
                                                const cells = [];
                                                cells.push(t.terminalSchedule.map((s, i) => (
                                                    i === todayCell-1
                                                        ?  <li className={`dateCell cell${i} today`}><dl className="legend"><dt className={getDeliveryTerminalSchedule(s)} /></dl></li>
                                                        :  <li className={`dateCell cell${i}`}><dl className="legend"><dt className={getDeliveryTerminalSchedule(s)} /></dl></li>

                                                )));

                                            return <> {cells} </>;} )()}  
                                        </ul>
                                    </Terminal>
                                ))}
                                </ul>
                            </DeliveryGroup>
                        ))}
                        </ul>
                    </main>
                </Organization>
            )}
        </>
    );
}
