import format from "date-fns/format";
import parse from "date-fns/parse";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TaskCreateFormRequest } from "../gateways/cms/task";
import { SelectTaskCategory, TaskCategoryOption } from "./SelectTaskCategory";
import { SelectTaskPriority } from "./SelectTaskPriority";
import { SelectUser } from "./SelectUser";
import { TaskTarget } from "./TaskTarget";

import { styled } from "@mui/system";
import Color from "../styles/colors.js";
import VarConst from "../styles/variables.js";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import iconCalendar from "../assets/icons/icon_calendar.svg";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

// style
const SectionComon = styled("section")`
    h2 {
        margin: 0 0 24px;
        padding: 10px 10px 10px 40px;
        height: 40px;
        font-weight: normal;
        font-size: 20px;
        background-color: #f4f4f4;
        line-height: 1;
    }

    h2 + main {
        margin: 24px 40px 24px;

        h3 {
            font-weight: normal;
            margin: 0 0 8px;

            small {
                margin-left: 1em;
            }
        }
        ul {
            li {
                b {
                    font-weight: normal;
                    margin-right: 0.5em;
                }
            }
        }

        label {
            display: block;
            margin-bottom: 4px;
            width: 100%;
        }
        input[type="text"],
        input[type="date"],
        input[type="time"],
        select {
            height: 32px;
            border: ${VarConst.border1};
            padding: 4px 8px;
        }
        input[type="text"] {
            width: 100%;
        }
        input[type="date"] {
            margin-right: 16px;
        }
        select {
            width: 180px;
        }
    }
    &.task_summary {
        ul {
            display: grid;
            width: 100%;
            max-width: 700px;
            grid-template-columns: auto auto 1fr;

            li {
                margin-bottom: 16px;
                padding-right: 16px;
                /* row: 1/2; */
                &:nth-of-type(1) {
                    grid-row: 1/2;
                    grid-column: 1/2;
                }
                &:nth-of-type(2) {
                    grid-row: 1/2;
                    grid-column: 2/3;
                }
                /* row: 2/3; */
                &:nth-of-type(3) {
                    grid-row: 2/3;
                    grid-column: 1/2;
                }
                &:nth-of-type(4) {
                    grid-row: 2/3;
                    grid-column: 2/3;
                }
                &:nth-of-type(5) {
                    grid-row: 2/3;
                    grid-column: 3/4;
                }
                /* row: 3/4; */
                &:nth-of-type(6) {
                    grid-row: 3/4;
                    grid-column: 1/2;
                }
                &:nth-of-type(7) {
                    grid-row: 3/4;
                    grid-column: 2/3;
                }
                /* row: 4/5; */
                &:nth-of-type(8) {
                    grid-row: 4/5;
                    grid-column: 1/4;
                }
                /* row: 5/6; */
                &:nth-of-type(9) {
                    grid-row: 5/6;
                    grid-column: 1/4;
                }
            }
        }
    }
`;
const BarFooter = styled("footer")`
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 24px 40px;
    background-color: ${Color.bgHead};

    & > div {
        align-items: end;

        div {
            display: flex;
            flex-wrap: wrap;
            width: 30em;
        }
    }
`;
const ButtonFooter = styled(Button)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
`;
const InputDate = styled("input")`
    padding: 4px;

    &::-webkit-calendar-picker-indicator {
        background: transparent url(${iconCalendar}) center center no-repeat scroll;
        cursor: pointer;
        border-radius: 4px;

        &:hover{
            background-color: #00000018
        }
    }
`;
const InputTime = styled("input")`
    padding: 4px;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background-image: none;
    }
`;

export interface Props {
    data: TaskCreateFormRequest;
    categoryOptions: TaskCategoryOption[];
    onSubmit: (data: TaskCreateFormRequest) => unknown;
    onDismiss: () => unknown;
}

export function TaskCreateForm({ data, categoryOptions, onSubmit, onDismiss }: Props) {
    const [title, setTitle] = useState(data.title);
    const [category, setCategory] = useState(categoryOptions.find((c) => c.id === data.category));
    const [priorityId, setPriorityId] = useState(data.priority);
    const [dueDate, setDueDate] = useState(new Date());
    const [dueDateTime, setDueDateTime] = useState(new Date());

    const navigate = useNavigate();
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            ...data,
            title,
            category: category?.id || 0,
            priority: priorityId,
            target: category?.target || "{}",
            limitdate: format(dueDate, "yyyy-MM-dd") + " " + format(dueDateTime, "HH:mm"),
        });
        navigate("/tasks");
    };

    const handleChangeCategory = (option?: TaskCategoryOption) => {
        if (!option) throw new Error("category is required");
        setCategory(option);
    };

    // console.log(data);

    return (
        <form onSubmit={handleSubmit}>
            <SectionComon className="task_summary">
                <h2>タスク内容</h2>
                <main>
                    <ul>
                        <li>
                            <label htmlFor="taskId">タスクID:</label>
                            {/* 新規作成の時はIDなし */}
                            <input type="text" disabled value={""} />
                        </li>
                        <li>
                            <label htmlFor="user">作成者</label>
                            <SelectUser value={String(data.createdBy)} disabled />
                        </li>
                        <li>
                            <label htmlFor="category">カテゴリ</label>
                            <SelectTaskCategory
                                value={category}
                                options={categoryOptions}
                                onChange={handleChangeCategory}
                            />
                        </li>
                        <li>
                            <label htmlFor="priority">優先度</label>
                            <SelectTaskPriority value={priorityId} onChange={setPriorityId} />
                        </li>
                        <li>
                            <label htmlFor="dueDate">期限</label>
                            <InputDate
                                type="date"
                                value={format(dueDate, "yyyy-MM-dd")}
                                required
                                onChange={(e) => setDueDate(parse(e.target.value, "yyyy-MM-dd", new Date()))}
                            />
                            <InputTime
                                type="time"
                                value={format(dueDateTime, "HH:mm")}
                                required
                                onChange={(e) => setDueDateTime(parse(e.target.value, "HH:mm", new Date()))}
                            />
                        </li>

                        <TaskTarget target={category?.target ?? "{}"} />

                        <li>
                            <label htmlFor="title">タイトル</label>
                            <input type="text" value={title} required onChange={(e) => setTitle(e.target.value)} />
                        </li>
                        {/* 新規作成時の担当者はサーバーで埋めることになったので不要 */}
                        {/* <li>
                            <label htmlFor="assignedUser">担当者</label>
                            <input type="text" disabled value={""} />
                        </li> */}
                    </ul>
                </main>
            </SectionComon>

            <BarFooter>
                <Stack spacing={2} direction="row">
                    <ButtonFooter type="button" variant="outlined" onClick={onDismiss}>
                        キャンセル
                    </ButtonFooter>
                    <ButtonFooter type="submit" variant="contained" disableElevation>
                        作成
                    </ButtonFooter>
                </Stack>
            </BarFooter>
        </form>
    );
}
