import assert from "assert";
import { useNavigate } from "react-router-dom";
import { TaskCreateForm } from "../../components/TaskCreateForm";
import { TaskCreateFormRequest, TaskPriorityOptions, TaskStatusOptions } from "../../gateways/cms/task";
import { useCreateTaskMutation } from "../../gateways/cms/taskApi";
import { useGetMyUserQuery } from "../../gateways/cms/userApi";

interface Props {
    terminalId: string;
}

export function TerminalTaskCreatePage({ terminalId }: Props) {
    assert(terminalId);

    const navigate = useNavigate();
    const user = useGetMyUserQuery();
    const [taskCreate] = useCreateTaskMutation();

    if (!user.data) {
        return <div></div>;
    }

    const categoryOptions = [
        { id: 20, name: "障害", target: JSON.stringify({ terminalId }) },
        { id: 30, name: "保守メンテ", target: JSON.stringify({ terminalId }) },
    ];

    const data: TaskCreateFormRequest = {
        title: "",
        category: categoryOptions[0].id,
        status: TaskStatusOptions[0].id,
        priority: TaskPriorityOptions[0].id,
        limitdate: "",
        organizationId: 0,
        target: categoryOptions[0].target,
        createdBy: user.data.id,
    };

    const handleSubmit = (data: TaskCreateFormRequest) => {
        taskCreate(data);
    };

    const handleDismiss = () => {
        navigate(-1);
    };

    return (
        <div>
            <h1>新規タスク</h1>

            <TaskCreateForm
                data={data}
                categoryOptions={categoryOptions}
                onSubmit={handleSubmit}
                onDismiss={handleDismiss}
            />
        </div>
    );
}
