// import { Button, Dialog, DialogActions } from "@mui/material";
import { Dialog } from "@mui/material";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";
import { DeliverySchedule } from "../gateways/cms/deliveryRule";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        header {
            text-align: center;
            padding: 40px 32px 0;

            h1 {
                font-size: 24px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
            .message {
                margin: 40px 0 0;
            }
        }
        main {
            display: flex;
            flex-wrap: wrap;
            width: 75%;
            margin: 0 auto;
            padding: 24px 0 calc(40px + 64px);

            form {
                width: 100%;

                & > section {
                    & + section {
                        margin-top: 48px;
                    }

                    & > h2 {
                        margin-bottom: 16px;
                        padding-left: 32px;
                        font-weight: normal;
                        font-size: 14px;
                    }
                    ul {
                        li {
                            margin: 0 0 16px;

                            input[type="text"],
                            input[type="date"],
                            input[type="time"],
                            select {
                                height: 32px;
                                min-width: 8em;
                            }

                            &:nth-of-type(1) {
                                float: left;
                                margin-right: 32px;

                                span {
                                    padding: 0 0.25em;
                                }
                            }
                        }
                    }
                }
            }

            form > section > ul {
                padding-left: 32px;
            }
        }
        footer {
            position: absolute;
            padding: 0 32px;
            background-color: #f4f4f4;
            bottom: 0;
            left: 0;
            width: 100%;

            .supplement {
                padding-top: 16px;
                text-align: center;
            }
            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
            }

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;
const InputTime = styled("input")`
    padding: 4px;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background-image: none;
    }
`;

const schema = Yup.object({
    startTime: Yup.string().required(),
    endTime: Yup.string().required(),
});
const resolver = yupResolver(schema);

interface Props {
    defaultValues: DeliverySchedule;
    onSubmit: (data: DeliverySchedule) => unknown;
    onDismiss: () => unknown;
}

export function DefaultDeliveryRuleEditModal({ defaultValues, onSubmit, onDismiss }: Props) {
    const { register, handleSubmit } = useForm({ defaultValues, resolver });

    return (
        <DialogOuter open>
            <header>
                <h1>スケジュール設定</h1>
            </header>

            <main>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section>
                        <h2>
                            標準の起動スケジュール
                        </h2>
                        <ul>
                            <li>
                                <InputTime type="time" {...register("startTime")} />
                                <span>〜</span>
                                <InputTime type="time" {...register("endTime")} />
                            </li>
                            <li>
                                <select disabled>
                                    <option value="on">電源ON</option>
                                </select>
                            </li>
                        </ul>
                    </section>

                    <footer>
                        <section className="buttons">
                            <Button type="button" variant="outlined" onClick={onDismiss}>
                                キャンセル
                            </Button>
                            <Button type="submit" variant="contained" disableElevation>
                                保存
                            </Button>
                        </section>
                    </footer>
                </form>
            </main>
        </DialogOuter>
    );
}
