import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutButton from "../LogoutButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuth } from "../../features/auth/useAuth";
import { useRouterModal } from "../../features/shared/useModal";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 0,
        marginTop: theme.spacing(1),
        minWidth: "128px",
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 8px 8px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "0",
        },
        "& li": {
            display: "block !important",
            height: "32px",
            fontSize: "14px",
            letterSpacing: "0",
            padding: "0",
            textAlign: "left",
            lineHeight: 1,
            color: "inherit",
        },
        "& li:nth-of-type(1)": {
            borderBottom: "1px solid #daddde",
        },
        "& button": {
            display: "block",
            height: "32px",
            width: "100%",
            padding: "8px",
            fontSize: "14px",
            letterSpacing: "0",
            textAlign: "left",
            lineHeight: 1,
            borderRadius: "0",
        },
    },
}));

export default function CustomizedMenus() {
    const { user } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openModal = useRouterModal();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenUserModal = () => {
        openModal("/users/me");
        handleClose();
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {user?.name}
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem disableRipple className="account">
                    <Button type="button"  onClick={handleOpenUserModal}>アカウント情報</Button>
                    
                </MenuItem>
                <li className="logout">
                    <LogoutButton />
                </li>

                {/*
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem>
        */}
            </StyledMenu>
        </div>
    );
}
