import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value: number;
    onChange: (value: number) => unknown;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;    
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

const months = Array.from(Array(12), (_, i) => 12 - i).reverse();

export function SelectMonth({ value, onChange }: Props) {
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(Number(e.target.value))}>
            {months.map((month) => (
                <option key={month} value={month}>
                    {month}月
                </option>
            ))}
        </SelectWithIcon>
    );
}
