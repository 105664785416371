import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { BillingRule, BillingRuleForm, BillingData } from "./billingRule";

export const billingRuleApi = createApi({
    reducerPath: "billingRuleApi",
    baseQuery,
    tagTypes: ['BillingRule'],
    endpoints: (builder) => ({
        getBillingRules: builder.query<BillingRule[], Id>({
            query: (organizationId) => `billingrules/list/${organizationId}`,
            providesTags: ['BillingRule'],
        }),
        getBillingRule: builder.query<BillingRule, Id>({
            query: (organizationId) => `billingrules/list/${organizationId}`,
            providesTags: ['BillingRule'],
        }),
        getBillingDatas: builder.query<BillingData[], Id>({
            query: (organizationId) => `billingdata/list/${organizationId}`,
            providesTags: ['BillingRule'],
        }),
        createBillingRule: builder.mutation<void, BillingRuleForm>({
            query: (body) => {
                const { organizationId } = body;
                return {
                    url: `billingrules/${organizationId}`,
                    method: "post",
                    body: { ...body, id: undefined },
                };
            },
            invalidatesTags: ['BillingRule'],
        }),
        updateBillingRule: builder.mutation<void, BillingRuleForm>({
            query: (body) => {
                const { organizationId } = body;
                return {
                    url: `billingrules/${organizationId}`,
                    method: "post",
                    body,
                };
            },
            invalidatesTags: ['BillingRule'],
        }),
        deleteBillingRule: builder.mutation<void, Id>({
            query: (organizationId) => ({
                url: `/billingrules/${organizationId}`,
                method: "delete",
            }),
            invalidatesTags: ['BillingRule'],
        }),
    }),
});

export const {
    useGetBillingRulesQuery,
    useGetBillingRuleQuery,
    useGetBillingDatasQuery,
    useCreateBillingRuleMutation,
    useUpdateBillingRuleMutation,
    useDeleteBillingRuleMutation,
} = billingRuleApi;
