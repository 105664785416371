import { Dialog, Button } from "@material-ui/core";
import { FormEventHandler } from "react";
import { useNavigate } from "react-router-dom";

export default function ExampleModal() {
    const navigate = useNavigate();

    const handleDismiss: FormEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    return (
        <Dialog aria-labelledby="label" open onClose={handleDismiss}>
            <div>
                Hello World
                <Button onClick={handleDismiss}>Close</Button>
            </div>
        </Dialog>
    );
}
