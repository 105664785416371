import { FormEventHandler } from "react";

import { styled } from "@mui/system";
import ButtonMUI from "@mui/material/Button";
import VarConst from "../styles/variables.js";
import iconSearch from "../assets/icons/icon_search.svg";

export interface Props {
    defaultValue?: string;
    onSubmit: (value: string) => void;
    placeholder?: string;
}

export function SearchForm({ defaultValue, placeholder, onSubmit }: Props) {
    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        onSubmit(String(data.get("keyword")));
    };

    // styled
    const FormSearch = styled("form")`
        display: flex;
        margin-bottom: 16px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 4px;
            display: block;
            height: 24px;
            width: 24px;
            background-image: url(${iconSearch});
            background-repeat: no-repeat;
            background-position: center center;
            transform: translate(0, -50%);
        }
    `;
    const InputSearch = styled("input")`
        height: 32px;
        width: 240px;
        padding-left: 2.5em;
        border: ${VarConst.border1};
    `;
    const ButtonSearch = styled(ButtonMUI)`
        height: 32px;
        border-radius: 2px;
    `;

    return (
        <FormSearch onSubmit={handleSubmit}>
            <InputSearch type="text" name="keyword" defaultValue={defaultValue} placeholder={placeholder} />
            <ButtonSearch type="submit" variant="contained" disableElevation>
                検索
            </ButtonSearch>
        </FormSearch>
    );
}
