import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import { makeStyles, createStyles } from "@material-ui/styles";
// import { red } from "@material-ui/core/colors";

import { styled } from "@mui/system";
// import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useLocation, useNavigate } from "react-router-dom";

// interface StyleTypeHint {
//     mediaWrapper: any;
//     img: any;
//     errorHeader: any;
// } 

// const useStyles = makeStyles(
//     () =>
//         createStyles({
//             mediaWrapper: {
//                 width: "100%",
//                 height: "200px",
//                 minHeight: "200px",
//                 maxHeight: "200px",
//                 "& img": {
//                     width: "auto !important",
//                     maxWidth: "100%",
//                     maxHeight: "200px",
//                 },
//             },
//             errorHeader: {
//                 backgroundColor: red[600],
//                 color: "#EEE",
//                 "& .MuiCardHeader-subheader": {
//                     color: "inherit",
//                 },
//             },
//         }) as StyleTypeHint
// );

interface Props {
    title: string;
    subheader: string;
    message: string;
    memo: string;
    image: string;
    background?: "normal" | "error" | "off" | "db_error";
    datetime: string;
    organizationId: number;
    terminalId: number;
    checkAdmin: boolean;
}

// style
const GroupCard = styled(Card)`
    height: 100%;
    padding: 6px;
    color: #545454;
    border: 1px solid  #DADDDE;
    border-radius: 0;

    &.errorHeader{
        background-color: #FFE3E3;
    }
    &.offHeader{
        background-color: #C0C0C0;
    }
    &.dbErrorHeader{
        background-color: #FF6347;
    }
`;
const GroupCardHeader = styled(CardHeader)`
    padding:3px  10px 0 10px;

    span{
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 5px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;

        &:nth-of-type(2){
            padding-left: 1em;
        }
        &:hover{
            white-space: normal;
            overflow: visible;
        }
    }
`;

const GroupCardImg = styled("div")`
    display: flex;
    width: 200px;
    height: 200px;
    margin: 0 auto 8px;
    border: 1px solid #f5f5f5;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    img{
        width:auto;
        height:auto;
        max-width: 100%;
        max-height:100%;
    }
`;
const GroupCardContent = styled(CardContent)`
    height: 100%;
    padding: 3px;
`;
const TypoContent = styled(Typography)`
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 1px;

    &:hover{
        white-space: normal;
        overflow: auto;
        text-overflow: "";
    }
`;
const TypoMemo = styled(Typography)`
    font-size: 13px;
    height: 58px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    margin-bottom: 3px;

    &:hover{
        white-space: normal;
        overflow: auto;
        white-space: normal;
        text-overflow: visible;
    }
`;
const TypoDate = styled(Typography)`
    width: 100%;
    height: 20px;
    text-align: right;
`;

export default function TerminalStatusCard(props: Props) {
    // const classes = useStyles();
    /*const headerClassName =
        props.background === "error" ? "errorHeader" : "";
        // props.background === "error" ? classes.errorHeader : "";
    */    

    const getHeaderName = () => {
        if(props.background === "error") return "errorHeader";
        else if(props.background === "off") return "offHeader";
        else if(props.background === "db_error") return "dbErrorHeader";
        else return "";
    }

    const location = useLocation();
    const navigate = useNavigate();

    const handleDetail = (terminalId: number, organizationId: number) => () => {
        navigate(`/organizations/${organizationId}/terminals/${terminalId}/detail`, {
            state: { backgroundLocation: location },
        });
    };

    return (
        <GroupCard
            className={getHeaderName()}
            elevation={0}
            raised={true}
        >
            {props.checkAdmin ? (
                <GroupCardHeader
                    title={props.title}
                    subheader={props.subheader}
                    onClick={handleDetail(props.terminalId, props.organizationId)}
                />
            ):(<GroupCardHeader
                    title={props.title}
                    subheader={props.subheader}
            />)}
            <GroupCardImg>
                <CardMedia
                    // className={classes.img}
                    component="img"
                    width="auto"
                    image={props.image}
                    alt={props.title}
                />
            </GroupCardImg>
            <GroupCardContent>
                <TypoContent variant="body2" color="text.secondary">
                    {props.message}
                </TypoContent>
                {props.checkAdmin ? (
                    <TypoMemo variant="body2" color="text.secondary">
                        {props.memo}
                    </TypoMemo>
                ):(<GroupCardHeader/>)}
                <TypoDate>
                    {props.datetime.replace(/-/g, "/").slice(0, -3)} 更新
                </TypoDate>
            </GroupCardContent>
        </GroupCard>
    );
}
