// import * as React from 'react';
import { Article } from "../gateways/cms/article";
// import { styled } from '@mui/material/styles';

export interface Props {
    article: Article;
    date: string; // 2022-02-01
}

export const PeriodBar = ({
    article,
    date,
}: Props) => {

    const getStartTime = ():String => {
        if(article.periodDayStart){
            const period = (article.periodDayStart).split(' ');
            if (period.length === 2) {
                if (period[0] === date) {
                    if (period[1] > article.periodTimeStart + ":00") {
                        return period[1];
                    }
                }
            }
        }
        return (article.periodTimeStart +":00");
    }

    const getEndTime = ():String => {
        if(article.periodDayEnd){
            const period = (article.periodDayEnd).split(' ');
            if(period.length === 2){
                if (period[0] === date) {
                    if (period[1] < article.periodTimeEnd + ":00") {
                        return period[1];
                    }
                }
            }
        }
        return (article.periodTimeEnd +":00");
    }

    const getPeriocBar = ():string => {
        if(article.status === "一時停止中") return "periodBarSuspend"
        else return "periodBar"
    }

    // 変換 
    let displayDate = new Date(date +" 00:00:00" ).getTime(); // 時刻無しで変換すると 09:00 になるので 00:00 に設定
    let checkDate = new Date(date +" 23:59:59" ).getTime();
    let dateStart = new Date(article.periodDayStart).getTime();
    let dateEnd   = new Date(article.periodDayEnd).getTime();
    let timeStart = new Date(date +" "+ getStartTime()).getTime();
    let timeEnd   = new Date(date +" "+ getEndTime()).getTime();
    let periodBarData;
    if(!article.periodDayEnd) dateEnd = Number.MAX_SAFE_INTEGER;

    //  表示指定日が、掲載期間内 か 掲載期限なし なら、時間スケジュール表示 
    if ( (dateStart <= checkDate && displayDate <= dateEnd)  || 
         (!article.periodDayStart && !article.periodDayEnd) ) {
        // TODO?: 表示可能曜日 表示可能日 チェック

        // ミリ秒表示の時間 / 1000ms / 60s / 60m / 24h * (全幅100%)
        let dayScale = 1 / 1000 / 60 / 60 / 24 * 100;
        // console.log("dayScale "+ dayScale);
        let periodStart = ( timeStart - displayDate ) * dayScale;
        // 終了時刻が開始時刻より遅ければ、翌日なので1日足す
        if(timeEnd - timeStart < 0 ){
            timeEnd = ( 1 / dayScale * 100 + timeEnd);
        }
        // console.log("timeEnd: "+ timeEnd);
        let periodSpan = ( timeEnd - timeStart ) * dayScale;
        // 終了点が、表示終了点を超えていたら、はみ出し部分を引く
        // let periodEndOverflow = ( dateEnd - timeEnd ) * dayScale;
        // console.log("periodEndOverflow: "+ periodEndOverflow);
        // if( periodEndOverflow > 0 ) {
        //     periodSpan = periodSpan - periodEndOverflow;
        // }
        // 表示単位を指定
        periodBarData = {
            left:  'calc(' + periodStart + ' * 1%)',
            width: 'calc(' + periodSpan + ' * 1%)',
        };
    }

    return (
        <>
        {periodBarData != null &&
        <div className={getPeriocBar()} style={periodBarData}>
            <h4 className="ayClmf"> {article.title} </h4>
            <div className="status" title={`${article.periodTimeStart} ～ ${article.periodTimeEnd}`}> {article.status} </div>
        </div>
        }
        </>
    );
}