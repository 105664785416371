import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value?: TaskCategoryOption;
    options: TaskCategoryOption[];
    onChange: (value?: TaskCategoryOption) => unknown;
}

export interface TaskCategoryOption {
    id: number;
    name: string;
    target: string;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;    
`;

export function SelectTaskCategory({ value, options, onChange }: Props) {
    return (
        <SelectWithIcon value={value?.id} onChange={(e) => onChange(options.find((o) => o.id === Number(e.target.value)))}>
            {options.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.name}
                </option>
            ))}
        </SelectWithIcon>
    );
}
