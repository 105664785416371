import { TaskPriorityOptions } from "../gateways/cms/task";

import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value: number;
    onChange: (value: number) => unknown;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

export function SelectTaskPriority({ value, onChange }: Props) {
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(Number(e.target.value))}>
            {TaskPriorityOptions.map((status) => (
                <option key={status.id} value={status.id}>
                    {status.name}
                </option>
            ))}
        </SelectWithIcon>
    );
}
