import { DeliveryRuleFormRequest } from "../gateways/cms/deliveryRule";

import { styled } from "@mui/system";
import iconCalendar from "../assets/icons/icon_calendar.svg";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    data: DeliveryRuleFormRequest;
    disabled?: boolean;
    onChange: (data: DeliveryRuleFormRequest) => unknown;
}

// style
const InputDate = styled("input")`
    padding: 4px;

    &::-webkit-calendar-picker-indicator {
        background: transparent url(${iconCalendar}) center center no-repeat scroll;
        cursor: pointer;
        border-radius: 4px;

        &:hover{
            background-color: #00000018
        }
    }
`;
const InputTime = styled("input")`
    padding: 4px;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background-image: none;
    }
`;
const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

export function OnceScheduleEditForm({ data, disabled, onChange }: Props) {
    return (
        <ul>
            <li>
                <InputDate
                    type="date"
                    value={data.day ?? ""}
                    required={!disabled}
                    disabled={disabled}
                    onChange={(e) => {
                        onChange({ ...data, day: e.target.value });
                    }}
                />
            </li>
            <li>
                <InputTime
                    type="time"
                    value={data.startTime ?? ""}
                    required={!disabled}
                    disabled={disabled}
                    onChange={(e) => onChange({ ...data, startTime: e.target.value })}
                />
                <span>〜</span>
                <InputTime
                    type="time"
                    value={data.endTime ?? ""}
                    required={!disabled}
                    disabled={disabled}
                    onChange={(e) => onChange({ ...data, endTime: e.target.value })}
                />
            </li>
            <li>
                <SelectWithIcon
                    value={data.poweron ? "on" : ""}
                    disabled={disabled}
                    onChange={(e) => onChange({ ...data, poweron: Boolean(e.target.value) })}
                >
                    <option value="on">電源ON</option>
                    <option value="">電源OFF</option>
                </SelectWithIcon>
            </li>
        </ul>
    );
}
