import { useGetUsersQuery } from "../gateways/cms/userApi";

// まだ使ってない
// タスク管理の編集フォームで使う予定

export interface Props {
    value: string;
    disabled: boolean;
    onChange?: (value: string) => void;
}

export function SelectUser({ value, disabled, onChange }: Props) {
    const users = useGetUsersQuery();

    return (
        <select value={value} disabled={disabled} onChange={(e) => (onChange ?? (() => {}))(e.target.value)}>
            <option value="">未選択</option>
            {users.data?.data?.map((user) => (
                <option key={user.id} value={user.id}>
                    {user.name}
                </option>
            ))}
        </select>
    );
}
