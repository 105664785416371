const colors = {
    text: "#4a4a4a",
    textLink: "#007aff",
    primary: "#3a3e5d",
    secondary: "#9290e1",
    caution: "#ff3442",
    // caution2:  '#FF4C4C',
    bg: "#ffffff",
    bgHead: "#f4f4f4",
    // bgMenu:   '#3A3E5D',
    bgCaution: "#ffe3e3",
    border: "#daddde",
    navHover: "#2a2935",
    // rborder:    '#C9CBD3',
    selected: "#d3f1f8",
    // iconAlt:  '#FF76A8',
};

export default colors;
