import { Dialog } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { Entity } from "../gateways/cms/id";
import { useGetOrganizationsQuery } from "../gateways/cms/organizationApi";
import { SearchForm } from "./SearchForm";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";
import { Organization } from "../gateways/cms/organization";

// style
const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        header {
            text-align: center;
            padding: 40px 32px 0;

            h1 {
                font-size: 24px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
            .message {
                margin: 40px 0 0;
            }
        }
        main {
            display: flex;
            flex-wrap: wrap;
            padding: 24px 56px 40px;
            flex: 1;
            overflow: auto;

            label {
                display: flex;
                align-items: center;
                position: relative;
                width: calc((100% - (16px * 2)) / 3);
                margin-bottom: 24px;

                input[type="checkbox"] {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                    width: 24px;
                    height: 24px;
                    margin: 0;
                    opacity: 0;
                    cursor: pointer;
                    transform: translate( -50%, -50%);

                    & + span{
                        display: flex;
                        align-items: center;

                        &::before {
                            content: "";
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            margin-right: 0.5em;
                            pointer-events: none;
                            background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                        }
                    }
                    &:checked + span::before {
                        background-image: url(${iconCheckboxOn});
                    }
                }
            }
        }
        footer {
            padding: 0 32px;
            background-color: #f4f4f4;

            .supplement {
                padding-top: 16px;
                text-align: center;
            }
            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
            }

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;
const SearchWrapper = styled("section")`
    display: grid;
    padding: 24px 24px 0;
    grid-template-columns: 30% 40% 30%;

    form {
        grid-column: 2/3;

        .MuiButton-root {
            color: #f4f4f4;
            background-color: #3a3e5d;
            border-color: #3a3e5d;
            border-radius: 2px;
        }
    }
    form + div {
        grid-column: 3/4;
        display: flex;
        padding-left: 24px;

        .MuiButton-root {
            flex: 1;
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;

export interface Props {
    open: boolean;
    value: Entity[];
    onChange: (value: Entity[]) => void;
    onClose: () => void;
    organizationId?: number;
}

export function SelectOrganizationModal({ open = false, value, onChange, onClose, organizationId = 0 }: Props) {
    const [searchKeyword, setSearchKeyword] = useState("");
    const organizations = useGetOrganizationsQuery({ search: searchKeyword, organizationId });
    const [selected, setSelected] = useState(value);

    const selectedOrganizationNames = selected
        .map((e) => organizations.data?.data?.find((o) => o.id === e.id))
        .map((o) => o?.name);

    const handleChange = (organization: Organization) => (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelected([...selected, { id: organization.id }]);
        } else {
            setSelected(selected.filter((v) => v.id !== organization.id));
        }
    };

    const selectAll = () => {
        setSelected(organizations.data?.data || []);
    };

    const deselectAll = () => {
        setSelected([]);
    };

    const handleSubmit = () => {
        onChange(selected);
        onClose();
    };

    const handleDismiss = () => {
        setSelected(value);
        onClose();
    };

    return (
        <DialogOuter open={open}>
            <header>
                <h1>所属テナント</h1>
            </header>

            <SearchWrapper>
                <SearchForm defaultValue={searchKeyword} onSubmit={setSearchKeyword} placeholder="キーワード検索" />
                <div>
                    <Button type="button" onClick={selectAll}>
                        すべて選択
                    </Button>
                    <Button type="button" onClick={deselectAll}>
                        選択解除
                    </Button>
                </div>
            </SearchWrapper>

            <main>
                {organizations.data?.data?.map((organization) => (
                    organization.id !== 1 &&
                    <label key={organization.id}>
                        <input
                            type="checkbox"
                            value={organization.id}
                            checked={selected.some((o) => o.id === organization.id)}
                            onChange={handleChange(organization)}
                        />
                        <span>
                            {organization.name}
                        </span>
                    </label>
                ))}
            </main>

            <footer>
                <section className="supplement">選択中: {selectedOrganizationNames.join("、")}</section>

                <section className="buttons">
                    <Button variant="outlined" type="button" onClick={handleDismiss}>
                        キャンセル
                    </Button>
                    <Button variant="contained" type="button" disableElevation onClick={handleSubmit}>
                        保存
                    </Button>
                </section>
            </footer>
        </DialogOuter>
    );
}
