import { Dialog } from "@material-ui/core";
import { ChangeEvent } from "react";
import { Entity } from "../gateways/cms/id";
import { useGetUserGroupsQuery } from "../gateways/cms/userGroupApi";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";

// style
const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        header {
            text-align: center;
            padding: 40px 32px 0;

            h1 {
                font-size: 24px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
            .message {
                margin: 40px 0 0;
            }
        }
        main {
            display: flex;
            flex-wrap: wrap;
            padding: 24px 56px; //40px;
            flex: 1;
            overflow: auto;
            justify-content: space-evenly;

            label {
                display: flex;
                align-items: center;
                position: relative;
                /* width: calc((100% - (16px * 2)) / 3);
                margin-bottom: 24px; */
                cursor: pointer;

                input[type="checkbox"] {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                    width: 24px;
                    height: 24px;
                    margin: 0;
                    opacity: 0;
                    cursor: pointer;
                    transform: translate( -50%, -50%);

                    & + span{
                        display: flex;
                        align-items: center;

                        &::before {
                            content: "";
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            margin-right: 0.5em;
                            pointer-events: none;
                            background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                        }
                    }
                    &:checked + span::before {
                        background-image: url(${iconCheckboxOn});
                    }
                }
            }
        }
        footer {
            padding: 0 32px;
            background-color: #f4f4f4;

            .supplement {
                padding-top: 16px;
                text-align: center;
            }
            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
            }

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;

export interface Props {
    open: boolean;
    value: Entity[];
    onChange: (value: Entity[]) => void;
    onClose: () => void;
}

export function SelectUserGroupModal({ open = false, value, onChange, onClose }: Props) {
    const userGroups = useGetUserGroupsQuery();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            onChange([...value, { id: Number(e.target.value) }]);
        } else {
            onChange(value.filter((v) => String(v.id) !== e.target.value));
        }
    };

    return (
        <DialogOuter open={open}>
            <main>
                {userGroups.data?.map((userGroup) => (
                    <label key={userGroup.id}>
                        <input
                            type="checkbox"
                            value={userGroup.id}
                            checked={value.some((g) => g.id === userGroup.id)}
                            onChange={handleChange}
                        />
                        <span>
                            {userGroup.name}
                        </span>
                    </label>
                ))}
            </main>

            <footer>
                <section className="buttons">
                    {/* <Button variant="outlined" type="button" onClick={handleDismiss}>
                        キャンセル
                    </Button> */}

                    <Button type="button" variant="contained" disableElevation onClick={onClose}>閉じる</Button>
                </section>
            </footer>

        </DialogOuter>
    );
}
