import { Dialog } from "@mui/material";
import { BillingRuleForm } from "./BillingRuleForm";
import { BillingRuleForm as BillingRuleFormData } from "../gateways/cms/billingRule";

import { styled } from "@mui/system";

export interface Props {
    open: boolean;
    data?: BillingRuleFormData;
    onChange: (data: BillingRuleFormData) => void;
    onClose: () => void;
}



// style
const DialogOuter = styled(Dialog)`
        .MuiDialog-paper {
            width: 800px;
            max-width: 100%;
            border-radius: 16px;

            main {
                display: flex;
                flex-wrap: wrap;
                padding: 40px 56px 16px;
            }
            footer {
                padding: 0 32px;
                background-color: #f4f4f4;

                .supplement{
                    padding-top: 16px;
                    text-align: center;
                }
                .buttons{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 64px;
                }

                button {
                    height: 32px;
                    width: 116px;
                    border-radius: 2px;
                    padding: 4px;

                    &.MuiButton-contained {
                        color: #f4f4f4;
                        background-color: #3a3e5d;
                        border-color: #3a3e5d;
                    }
                    &.MuiButton-outlined {
                        background-color: #fff;
                        border-width: 2px;
                        border-color: #c9cbd3;
                    }

                    & + button {
                        margin-left: 16px;
                    }
                }
            }
        }
    `;

export function BillingRuleEditModal({ open, data, onChange, onClose }: Props) {
    return (
        <DialogOuter open={open} onClose={onClose}>
            {data && <BillingRuleForm data={data} onSubmit={onChange} onDismiss={onClose} />}
        </DialogOuter>
    );
}
