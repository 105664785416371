// import { Link } from "react-router-dom";
import { OrganizationTerminalStatusLog } from "../../../components/OrganizationTerminalStatusLog";
import MainLayout from "../../../layouts/MainLayout";

import { styled } from "@mui/system";
//import ButtonMUI from "@mui/material/Button";
import { useSelector } from "react-redux";
import { selectOrganizationId } from "../../../features/cms/organizationBreadcrumbSlice";
import { useGetTerminalStatusLogQuery } from "../../../gateways/cms/logApi";

// style
const OrganizationTitle = styled("section")`
    display: flex;
    justify-content: flex-start;
    padding: 24px;
    // margin-bottom: 16px;

    h2 {
        font-size: 24px;
        font-weight: normal;
        margin: 0 40px 0 0;
    }
`;
/** タスク追加 無効のためコメントアウト
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;
** コメントアウトここまで **/

export function TerminalStatusLogPage() {
    const organizationId = useSelector(selectOrganizationId);
    const entries = useGetTerminalStatusLogQuery(organizationId);

    return (
        <MainLayout title="端末状態">
            {entries.data?.map((entry) => (
                <div key={entry.organization.id}>
                    <OrganizationTitle>
                        <h2>{entry.organization.name}</h2>
                        {/** タスク追加 無効のためコメントアウト
                        <Button
                            variant="contained"
                            disableElevation
                            href={`/tasks/create?organizationId=${entry.organization.id}`}
                        >
                            タスク追加
                        </Button>
                        ** コメントアウトここまで **/}
                    </OrganizationTitle>
                    <OrganizationTerminalStatusLog entry={entry} />
                </div>
            ))}
        </MainLayout>
    );
}
