export default class CmsHttpError extends Error {
    constructor(public statusCode: number, message: string) {
        super(message);
        this.name = "CmsHttpError";
    }

    static factory(args: [number, string]): CmsHttpError {
        return new CmsHttpError(args[0], args[1]);
    }
}
