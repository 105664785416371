import assert from "assert";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DeliveryScheduleListTemplate } from "../../components/DeliveryScheduleListTemplate";
import { DeliveryRuleFormRequest, DeliverySchedule } from "../../gateways/cms/deliveryRule";
import {
    useDeleteOrganizationDeliveryRuleMutation,
    useGetOrganizationDeliveryRuleQuery,
    useUpdateOrganizationDeliveryRuleMutation,
} from "../../gateways/cms/deliveryRuleApi";
import { useGetOrganizationQuery } from "../../gateways/cms/organizationApi";
import { ScheduleEditModal } from "./ScheduleEditModal";
import MainLayout from "../../layouts/MainLayout";

export function OrganizationScheduleEditPage() {
    const { organizationId } = useParams();
    assert(organizationId);

    const organization = useGetOrganizationQuery(organizationId);
    const schedules = useGetOrganizationDeliveryRuleQuery(organizationId);
    const [scheduleUpdate] = useUpdateOrganizationDeliveryRuleMutation();
    const [scheduleDelete] = useDeleteOrganizationDeliveryRuleMutation();
    const [selected, setSelected] = useState<DeliveryRuleFormRequest>();
    const [selectedIds, setSelectedIds] = useState([] as number[]);

    const handleCreate = () => {
        setSelected({
            type: 0,
            poweron: true,
        });
    };

    const handleEdit = (schedule: DeliverySchedule) => {
        setSelected(schedule);
    };

    const save = async (data: DeliveryRuleFormRequest) => {
        await scheduleUpdate({ organizationId: Number(organizationId), ...data });
        closeEditModal();
    };

    const handleUpdate = async (data: DeliveryRuleFormRequest) => {
        await scheduleUpdate({ organizationId: Number(organizationId), ...data });
    };

    const closeEditModal = () => {
        setSelected(undefined);
    };

    const handleDelete = async (scheduleId: number) => {
        await scheduleDelete({ id: Number(organizationId), scheduleIds: [scheduleId] });
        schedules.refetch();
    };

    const handleBulkDelete = async (scheduleIds: number[]) => {
        setSelectedIds([]);
        await scheduleDelete({ id: Number(organizationId), scheduleIds });
        schedules.refetch();
    };

    return (
        <>
            <MainLayout title="テナントスケジュール">
                {schedules.data && (
                    <DeliveryScheduleListTemplate
                        title={organization.data?.name}
                        schedules={schedules.data}
                        selectedIds={selectedIds}
                        onCreate={handleCreate}
                        onEdit={handleEdit}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        onBulkDelete={handleBulkDelete}
                        onSelect={setSelectedIds}
                        scheduleTitle="テナントスケジュール"
                    />
                )}
            </MainLayout>

            {selected && <ScheduleEditModal data={selected} onSubmit={save} onDismiss={closeEditModal} />}
        </>
    );
}
