// import * as React from 'react';
import { Article } from "../gateways/cms/article";
// import { styled } from '@mui/material/styles';

export interface Props {
    article: Article;
    fromDate: string;
    toDate: string;
    dispLastDate: Date;
    gridCells: number;
}

export const PeriodBarMonthly = ({
    article,
    fromDate,
    toDate,
    dispLastDate,
    gridCells,
}: Props) => {

    // 変換 
    let baseDate = new Date(fromDate +" 00:00:00");  // 時刻無しで変換すると 09:00 になるので 00:00 に設定 (表示範囲の先頭の時間)
    let lastDate = new Date(toDate +" 23:59:59" ); // 時刻無しで変換すると 09:00 になるので 23:59 を設定 (表示範囲の末の時間)
    let displayBaseDate = new Date( baseDate.setDate(1) ).getTime(); 
    let displayLastDate = new Date( lastDate.setDate(lastDate.getDate() + 0) ).getTime(); 
    let dateStart = new Date(article.periodDayStart).getTime();
    let dateEnd   = new Date(article.periodDayEnd).getTime();
    let periodBarData;
    if(!dateEnd) dateEnd = Number.MAX_SAFE_INTEGER;

    let displayDays = gridCells;
    if( gridCells < 28 ){
        displayDays = gridCells * 7;
        displayLastDate = new Date( dispLastDate ).getTime(); 
    }
    // ミリ秒表示の時間 / 1000ms / 60s / 60m / 24h * (全幅100%)
    let dayScale = 1 / 1000 / 60 / 60 / 24 / displayDays * 100;
    let periodStart = ( dateStart - displayBaseDate ) * dayScale;
    let periodSpan  = ( dateEnd - dateStart ) * dayScale;

    // 開始点が、表示開始点を超えていたら、Startを0にして、はみ出し部分を引く
    if( periodStart < 0) {
        periodSpan = periodSpan + periodStart;
        periodStart = 0;
    }
    // 終了点が、表示終了点を超えていたら、はみ出し部分を引く
    // - 単月表示の場合、翌1日まで表示して最終日の時間を表示
    // - 23か月表示の場合、最終日までの時間を表示してそれ以降は非表示
    let periodEndOverflow = ( dateEnd - displayLastDate ) * dayScale;
    if( periodEndOverflow > 0 ) {
        periodSpan = periodSpan - periodEndOverflow;
    }
    // 表示単位を指定
    periodBarData = {
        left:  'calc('+ periodStart +' * 1%)',
        width: 'calc('+ periodSpan +' * 1%)',
    };

    return (
        <>
        {periodBarData != null &&
        <div className="periodBar" style={periodBarData}>
            <h4 className="ayClmf"> {article.title} </h4>
            <div className="status" title={`${article.periodDayStart.replace(/-/g, '/').slice( 0, -9)} ～ ${article.periodDayEnd?.replace(/-/g, '/').slice( 0, -9)}`}> {article.status} </div>
        </div>
        }
        </>
    );
}