// 曜日表示

export interface Props {
    value?: number[];
}

const dayOptions = ["日", "月", "火", "水", "木", "金", "土"];

export function PeriodWeekDay({ value }: Props) {
    return (
        <>
            {dayOptions.map((dayName, day) => (
                <li key={day} className={value && value[day] ? "" : "off"}>
                    {dayName}
                </li>
            ))}
        </>
    );
}
