import { ReactNode } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withConfirmation, WrappedComponentProps, createConfirmation } from "react-confirmable";

import { styled } from "@mui/system";

type Props = {
    title: string;
    body: ReactNode;
    okButtonText?: string;
    destructive?: boolean;
    single?: boolean;
    // themeType: 'dark' | 'light'; // ダークモードがあるなら、値を受入れる
};

// style
const DialogMain = styled(Dialog)`

    .MuiDialog-paper {
        width: 500px;
        max-width: 100%;
        border-radius: 16px;

        & > header,
        .MuiDialogTitle-root {
            text-align: center;
            padding: 40px 32px 0;
        }
        & > main,
        .MuiDialogContent-root {
            min-height: calc( 40px + 64px + 40px);
            padding: 40px;
            text-align: center;

            p:last-child{
                margin-bottom: 0;
            }
        }
        & > footer,
        .MuiDialogActions-root {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 64px;
            padding: 0 32px;
            background-color: #f4f4f4;

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #C9CBD3;

                    &#confirm{
                        color: #FD6F6C;
                    }
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;

const BaseModal = ({
    show,
    confirm,
    abort,
    title,
    body,
    okButtonText,
    destructive,
    single,
    // themeType,
}: 
// WrappedComponentProps:
// - show: if true, modal will open
// - confirm: callback function which closes modal, returns true
// - abort: callback function which closes modal, returns false
Props & WrappedComponentProps) => (
    <DialogMain open={show} onClose={abort}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
            <DialogContentText>{body}</DialogContentText>
        </DialogContent>

        <DialogActions>
            <Button id="cancel" onClick={abort} variant="outlined">
                キャンセル
            </Button>
            <Button id="confirm" onClick={confirm} variant={destructive ? "outlined" : "contained" } disableElevation>
                {okButtonText !== null ? okButtonText : "OK"}
            </Button>
        </DialogActions>
    </DialogMain>
);

const ConfirmationModal = withConfirmation(BaseModal);

export const confirmWithStyle = (props: Props): Promise<boolean> => {
    return createConfirmation(ConfirmationModal, props);
};

// https://github.com/uraway/react-confirmable

// インポート
// import { confirmWithStyle } from '../../components/MyConfirm';

//
// const handleSomethingAction = async () => {
//     const isConfirmed = await confirmWithStyle({
//         title: 'タイトル',
//         body: '本文本文本文本文本文本文本文本文本文本文本文本文本文',
//     });
//     if(isConfirmed){
//         // OK時の処理
//     }
// };
