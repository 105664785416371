import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { Page } from "./page";
import { ResetPasswordForm, User, UserForm, UserQuery } from "./user";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getUsers: builder.query<Page<User>, UserQuery | void>({
            query: (params) => {
                return { url: "users/list", params: params || undefined };
            },
            providesTags: ['User'],
        }),
        getMyUser: builder.query<User, void>({
            query: () => ({ url: "users/me" }),
            providesTags: ['User'],
        }),
        getUser: builder.query<User, Id>({
            query: (id) => ({ url: `users/${id}` }),
            providesTags: ['User'],
        }),
        updateUser: builder.mutation<void, UserForm>({
            query: ({ id, ...body }) => ({
                url: `users/edit/${id}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['User'],
        }),
        deleteUser: builder.mutation<void, Id>({
            query: (id) => ({ url: `users/${id}`, method: "delete" }),
            invalidatesTags: ['User'],
        }),
        enableUser: builder.mutation<void, Id>({
            query: (id) => ({ url: `users/enable/${id}`, method: "post" }),
            invalidatesTags: ['User'],
        }),
        disableUser: builder.mutation<void, Id>({
            query: (id) => ({ url: `users/disable/${id}`, method: "post" }),
            invalidatesTags: ['User'],
        }),
        resetPassword: builder.mutation<void, ResetPasswordForm>({
            query: (body) => ({
                url: `users/reset-password`,
                method: "post",
                body,
            }),
            invalidatesTags: ['User'],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useGetMyUserQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useResetPasswordMutation,
    useEnableUserMutation,
    useDisableUserMutation,
} = userApi;
