import React from 'react';
import { getYear, getMonth } from "date-fns";
import { FormEvent, useState } from "react";
import { BillingDataDownloadRequest } from "../gateways/cms/billingData";
import { Entity } from "../gateways/cms/id";
import { SelectMonth } from "./SelectMonth";
import { SelectOrganization } from "./SelectOrganization";
import { SelectOrganizationModal } from "./SelectOrganizationModal";
import { SelectYear } from "./SelectYear";
import ButtonMUI from "@material-ui/core/Button";
import { ItemSummaryPop } from "../components/itemSummaryPop";

import { styled } from "@mui/system";

export interface Props {
    onSubmit: (data: BillingDataDownloadRequest) => unknown;
}

export function BillingDataDownloadForm({ onSubmit }: Props) {
    const [year, setYear] = useState(getYear(new Date()));
    const [month, setMonth] = useState(getMonth(new Date()) + 1);
    const [organizations, setOrganizations] = useState([] as Entity[]);
    const [selectOrganizationModalOpen, setSelectOrganizationModalOpen] = useState(false);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            year: String(year),
            month: String(month).padStart(2, "0"),
            organizationIds: organizations.map((o) => o.id),
        });
    };

    // style
    const DataDownloadFormSection = styled("section")`
        margin: 0 0 24px;

        &:nth-of-type(1){
            select{
                min-width: 90px;
            }
        }

        label {
            display: block;
            margin-bottom: 4px;
        }
        select {
            height: 32px;
            padding: 4px 8px;

            & + select{
                margin-left: 16px
            }
        }
        
        button.MuiButton-contained {
            color: #f4f4f4;
            background-color: #3a3e5d;
            border-color: #3a3e5d;
            border-radius: 2px;
        }
    `;
    const Button = styled(ButtonMUI)`
        height: 32px;
        border-radius: 2px;
    `;
    const DataDownloadFormFooter = styled("footer")`
        padding: 16px 24px;
        background-color: #F4F4F4;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        text-align: right;

        button.MuiButton-root{
            color: #f4f4f4;
            background-color: #3a3e5d;
            border-color: #3a3e5d;
            border-radius: 2px;
        }
    `;
    const SimpleList = styled("ul")`
        padding-top: 24px;
        display: flex;
        flex-wrap: wrap;
    `;
    const SimpleListItem = styled("li")`
        position: relative;
        margin-right: 24px;
        margin-bottom: 24px;
        background-color: #F4F4F4;
        border-radius: 2px;
        width: 180px;
                   
        select{
            max-width: 11.25em;
            padding: 0 4px 0 8px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            background-color: transparent;
            pointer-events: none;
        }
        .MuiButton-root{
            position: absolute !important;
            right: 0;
            top: 0;
            min-width: 0;
            padding: 0;
        }
    `;

    // DEBUG
    // console.log("organizations: ");
    // console.log(organizations);

    return (
        <>
            <form onSubmit={handleSubmit}>

                <DataDownloadFormSection>                    
                    <label htmlFor="year">請求月</label>
                    <SelectYear value={year} onChange={setYear} />
                    <SelectMonth value={month} onChange={setMonth} />
                </DataDownloadFormSection>

                <DataDownloadFormSection>                    
                    <label htmlFor="organizations">対象のテナント</label>
                    <Button type="button" variant="contained" disableElevation onClick={() => setSelectOrganizationModalOpen(true)}>
                        テナントを選択
                    </Button>

                    <SimpleList>
                        {organizations.map((o) => (
                            <SimpleListItem key={o.id}>
                                <SelectOrganization value={o.id} />
                                <ItemSummaryPop
                                    popTitle={ <SelectOrganization value={o.id} /> }
                                    popMain={ <div>グループ詳細 未取得</div> }
                                    linkDetail={ `/organizations/${o.id}/edit` }
                                />
                            </SimpleListItem>
                        ))}
                    </SimpleList>
                </DataDownloadFormSection>                    

                <DataDownloadFormFooter>
                    <Button type="submit" variant="contained" disableElevation>ダウンロード</Button>
                </DataDownloadFormFooter>
            </form>

            <SelectOrganizationModal
                open={selectOrganizationModalOpen}
                value={organizations}
                onChange={setOrganizations}
                onClose={() => setSelectOrganizationModalOpen(false)}
            />
        </>
    );
}
