import { useSelector } from "react-redux";
import { OrganizationDeliveryGroups } from "../../components/OrganizationDeliveryGroups";
import { selectOrganizationId } from "../../features/cms/organizationBreadcrumbSlice";
import MainLayout from "../../layouts/MainLayout";

import { styled } from '@mui/system';
// import VarConst from "../../styles/variables.js";

// [掲載順管理]

const PageTitle = styled('h1')`
    margin:0;
    padding: 24px;
    font-size: 24px;
    font-weight: normal;
`;

export const DeliveryGroups = () => {
    const organizationId = useSelector(selectOrganizationId);

    return (
        <MainLayout title="掲載順管理" >
            <PageTitle className={`organizationId-${organizationId}`}>掲載順管理</PageTitle>
            <OrganizationDeliveryGroups organizationId={organizationId}  />
        </MainLayout>
    );
};
