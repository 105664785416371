import { articleFilterStatues, articleFilterStatusNames } from "../gateways/cms/article";
import { SelectFilter } from "./SelectFilter";

export interface Props {
    value: string[];
    onChange: (value: string[]) => void;
}

const filters = articleFilterStatues.map((s, i) => ({ value: String(s), label: articleFilterStatusNames[i] }));

export function SelectArticleStatusFilter({ value, onChange }: Props) {
    return <SelectFilter options={filters} value={value} onChange={onChange} />;
}
