import { useNavigate, useParams } from "react-router-dom";
import {
    useBulkDeleteSchemaDefinitionMutation,
    useGetSchemaDefinitionQuery,
    useUpdateSchemaDefinitionMutation,
} from "../../gateways/cms/schemaApi";
import MainLayout from "../../layouts/MainLayout";
import { SchemaDefinitionForm } from "../../components/SchemaDefinitionForm";
import assert from "assert";
import { SchemaDefinitionFormRequest } from "../../gateways/cms/schema";

import { styled } from "@mui/system";

// [コンテンツスキーマ管理(編集)]

export function SchemaDefinitionEditPage() {
    const { id } = useParams();
    assert(id);

    const navigate = useNavigate();
    const schemaDefinition = useGetSchemaDefinitionQuery(id);
    const [schemaDefinitionUpdate] = useUpdateSchemaDefinitionMutation();
    const [schemaDefinitionBulkDelete] = useBulkDeleteSchemaDefinitionMutation();

    const handleSubmit = async (data: SchemaDefinitionFormRequest) => {
        await schemaDefinitionUpdate(data);
        navigate(-1);
    };

    const handleDelete = async () => {
        await schemaDefinitionBulkDelete([Number(id)]);
        navigate(-1);
    };

    // style
    const PageTitle = styled("section")`
        display: flex;
        justify-content: space-between;
        padding: 24px;
        // margin-bottom: 16px;

        h1 {
            font-size: 24px;
            font-weight: normal;
            margin: 0;
        }
    `;

    return (
        <MainLayout title="コンテンツスキーマ管理（編集）">
            <PageTitle>
                <h1>コンテンツスキーマ管理（編集）</h1>
            </PageTitle>

            {schemaDefinition.data && (
                <SchemaDefinitionForm data={schemaDefinition.data} onSubmit={handleSubmit} onDelete={handleDelete} />
            )}
        </MainLayout>
    );
}
