export const ScheduleTypeNames = {
    0: "繰り返しスケジュール",
    1: "日付指定スケジュール",
    2: "標準の起動スケジュール",
};

export type ScheduleType = keyof typeof ScheduleTypeNames;

export interface DeliveryRuleQuery {
    organizationId: number;
    fromDate?: string; // yyyy-MM-dd
    toDate?: string; // yyyy-MM-dd
}

export interface DeliveryRule {
    organizationId: number;
    name: string;
    schedule: DeliverySchedule[];
    deliveryGroup: DeliveryRuleDeliveryGroup[];
}

export interface DeliverySchedule {
    id: number;
    type: ScheduleType;
    day?: string; // yyyy-MM-dd
    week?: number[]; // 7つの0/1で有効・無効を表す
    startTime: string; // mm:ss
    endTime: string; // mm:ss
    startOff?: string; // mm:ss
    endOff?: string; // mm:ss
    poweron: true;
}

export interface DeliveryRuleDeliveryGroup {
    deliveryGroupId: number;
    name: string;
    schedule: DeliverySchedule[];
    terminal: DeliveryRuleTerminal[];
}

export interface DeliveryRuleTerminal {
    terminalId: number;
    name: string;
    schedule: DeliverySchedule[];
    terminalSchedule: DeliveryTerminalSchedule[];
}

export interface DeliveryTerminalSchedule {
    startTime: string; // mm:ss
    endTime: string; // mm:ss
    schedule: number;
    startOff?: string; // mm:ss
    endOff?: string; // mm:ss
}

export interface DeliveryRuleDeleteFormRequest {
    id: number;
    scheduleIds: number[];
}

export interface DeliveryRuleFormRequest {
    id?: number;
    organizationId?: number;
    deliveryGroupId?: number;
    terminalId?: number;
    type?: ScheduleType;
    day?: string;
    week?: number[]; // 7つの0/1で有効・無効を表す
    startTime?: string; // mm:ss
    endTime?: string; // mm:ss
    poweron?: boolean;
}

export interface DeliveryOrganization {
    id: number;
    name: string;
    parentId: number;
}

export interface DeliveryOrganizations {
    organizations: DeliveryOrganization[];
    total: number;
}
