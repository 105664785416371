import { SelectOrganization } from "./SelectOrganization";
import { SelectTerminal } from "./SelectTerminal";

export interface Props {
    target: string;
}

export function TaskTarget({ target }: Props) {
    const data = JSON.parse(target);
    const organizationId = String(data.organizationId ?? "");
    const terminalId = String(data.terminalId ?? "");
    // const articleId = String(data.articleId ?? "");

    return (
        <>
            <li>
                <label htmlFor="organization">対象テナント</label>
                <SelectOrganization value={organizationId} disabled />
            </li>
            <li>
                <label htmlFor="terminal">対象端末</label>
                <SelectTerminal value={terminalId} disabled />
            </li>
            {/* 
            <li>
                <label htmlFor="article">対象コンテンツ/label>
                <input type="text" value={articleId} disabled />
            </li>
            */}
        </>
        // <div>
        //     <label htmlFor="organization">対象テナント</label>
        //     <SelectOrganization value={organizationId} disabled />

        //     <label htmlFor="terminal">対象端末</label>
        //     <SelectTerminal value={terminalId} disabled />

        //     <label htmlFor="article">対象コンテンツ</label>
        //     <input type="text" value={articleId} disabled />
        // </div>
    );
}
