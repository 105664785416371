import { DeliveryRuleFormRequest } from "../gateways/cms/deliveryRule";
import { SelectDay } from "./SelectDay";

import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    data: DeliveryRuleFormRequest;
    disabled?: boolean;
    onChange: (data: DeliveryRuleFormRequest) => unknown;
}
const InputTime = styled("input")`
    padding: 4px;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background-image: none;
    }
`;
const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

export function RepeatScheduleEditForm({ data, disabled, onChange }: Props) {

    return (
        <ul>
            <li >
                <SelectDay
                    value={data.week ?? [0, 0, 0, 0, 0, 0, 0]}
                    disabled={disabled}
                    onChange={(week) => onChange({ ...data, week })}
                    />
            </li>
            <li>
                <InputTime
                    type="time"
                    value={data.startTime ?? ""}
                    required={!disabled}
                    disabled={disabled}
                    onChange={(e) => onChange({ ...data, startTime: e.target.value })}
                />
                <span>〜</span>
                <InputTime
                    type="time"
                    value={data.endTime ?? ""}
                    required={!disabled}
                    disabled={disabled}
                    onChange={(e) => onChange({ ...data, endTime: e.target.value })}
                />
            </li>
            <li>
                <SelectWithIcon
                    value={data.poweron ? "on" : ""}
                    disabled={disabled}
                    onChange={(e) => onChange({ ...data, poweron: Boolean(e.target.value) })}
                >
                    <option value="on">電源ON</option>
                    <option value="">電源OFF</option>
                </SelectWithIcon>
            </li>
        </ul>
    );
}
