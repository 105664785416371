import { useGetDeliveryGroupsQuery } from "../gateways/cms/deliveryGroupApi";

import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    organizationId: string;
    value?: string;
    disabled?: boolean;
    required?: boolean;
    onChange?: (value: string) => void;
}

// style
const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

/**
 * 配信グループの選択
 */
export function SelectDeliveryGroup({
    organizationId,
    value = "",
    disabled = false,
    required,
    onChange = () => {},
}: Props) {
    const deliveryGroups = useGetDeliveryGroupsQuery(organizationId);

    return (
        <SelectWithIcon value={value} disabled={disabled} required={required} onChange={(e) => onChange(e.target.value)}>
            <option value="">未選択</option>
            {deliveryGroups.data?.map((deliveryGroup) => (
                <option key={deliveryGroup.id} value={deliveryGroup.id}>
                    {deliveryGroup.name}
                </option>
            ))}
        </SelectWithIcon>
    );
}
