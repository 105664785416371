import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { Page } from "./page";
import { Task, TaskBulkDeleteFormRequest, TaskCreateFormRequest, TaskQuery, TaskUpdateFormRequest } from "./task";

export const taskApi = createApi({
    reducerPath: "taskApi",
    baseQuery,
    tagTypes: ['Task'],
    endpoints: (builder) => ({
        getTasks: builder.query<Page<Task>, TaskQuery>({
            query: (params) => ({
                url: "tasks/list",
                params,
            }),
            providesTags: ['Task'],
        }),
        getTask: builder.query<Task, Id>({
            query: (id) => ({
                url: `tasks/${id}`,
            }),
            providesTags: ['Task'],
        }),
        getTaskCount: builder.query<String, void>({
            query: () => ({
                url: "task_count",
            }),
            providesTags: ['Task'],
        }),
        createTask: builder.mutation<void, TaskCreateFormRequest>({
            query: (body) => ({
                url: "tasks",
                method: "post",
                body,
            }),
            invalidatesTags: ['Task'],
        }),
        updateTask: builder.mutation<void, TaskUpdateFormRequest>({
            query: (body) => ({
                url: "tasks",
                method: "post",
                body,
            }),
            invalidatesTags: ['Task'],
        }),
        deleteTasks: builder.mutation<void, TaskBulkDeleteFormRequest>({
            query: (body) => ({
                url: "tasks",
                method: "delete",
                body,
            }),
            invalidatesTags: ['Task'],
        }),
    }),
});

export const {
    useGetTasksQuery,
    useGetTaskQuery,
    useGetTaskCountQuery,
    useCreateTaskMutation,
    useUpdateTaskMutation,
    useDeleteTasksMutation,
} = taskApi;
