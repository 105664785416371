import { Entity } from "./id";
import { SimpleOrganization } from "./organization";
import { UserGroup } from "./user";

export interface SchemaDefinitionQuery {
    per?: number;
    page?: number;
    sort_key?: string;
    sort_direction?: string;
}

/**
 * コンテンツスキーマ
 */
export interface Schema {
    id: number;
    title: string;
    type: string;
    draft: boolean;
    organization: SimpleOrganization[];
    usergroup: UserGroup[];
    schemaItem: SchemaDefinitionItem[];
    updatedAt: string; // yyyy-MM-dd HH:mm
    checkDefault: boolean;
}

export interface SchemaDefinitionItem {
    id: number;
    title: string;
    fieldName: string;
    content: SchemaDefinitionItemContent;
}

export interface SchemaDefinitionItemContent {
    type: string;
    required: boolean;
    info: string;
    item?: Record<string, string>;
    radioItem?: SchemaDefinitionRadioItemContent;
}

export interface SchemaDefinitionRadioItemContent {
    signageVideo: boolean;
    signageImage: boolean;
    signageUrl: boolean;
    signageText: boolean;
}

export interface SchemaDefinitionFormRequest {
    id?: number;
    title?: string;
    organization?: Entity[];
    usergroup?: Entity[];
    schemaItem?: SchemaDefinitionItemFormRequest[];
    draft?: boolean;
}

export interface SchemaDefinitionItemFormRequest {
    id?: number;
    fieldName?: string;
    title?: string;
    content?: SchemaDefinitionItemContentFormRequest;
}

export interface SchemaDefinitionItemContentFormRequest {
    type?: string;
    required?: boolean;
    info?: string;
    item?: Record<string, string>;
    radioItem?: SchemaDefinitionRadioItemContent;
}

export interface SchemaDefinitionMinimumFormRequest {
    id?: number;
    title?: string;
    organization?: Entity[];
    usergroup?: Entity[];
    type?: string;
    maxTime?: string;
    postingInterval?: string;
    draft?: boolean;
}

export interface OptionType {
    value: number;
    label: string;
}

/** サイネージ用ラジオボタン */
export interface Radio {
    label: string
    value: string
}
export const radioMinimum: Radio[] = [
    {
        label: "画像",
        value: "image"
    },
    {
        label: "動画",
        value: "video"
    },
    {
        label: "未選択(削除)",
        value: "clear"
    }
]

export const radioSignage: Radio[] = [
    {
        label: "画像",
        value: "image"
    },
    {
        label: "動画",
        value: "video"
    },
    {
        label: "URL",
        value: "url"
    },
    {
        label: "短文",
        value: "text"
    },
    {
        label: "未選択(削除)",
        value: "clear"
    }
]
