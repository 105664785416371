// TODO: 廃止したい

export default class PermanentDataStore {
    private token: string | null;
    private static instance: PermanentDataStore | null = null;
    private constructor() {
        this.token = null;
    }

    hasToken(): boolean {
        return this.token !== null;
    }

    static load(): PermanentDataStore {
        if (PermanentDataStore.instance === null) {
            const data = window.localStorage.getItem("local-data");
            
            const store = new PermanentDataStore();
            if (data) {
                const json = JSON.parse(data) || {};
                store.token = json.token || null;
                // 下記コードでもいいような気がする
                // store = (json as PermanentDataStore)
            }
            PermanentDataStore.instance = store;
        }
        //console.log(PermanentDataStore.instance);
        return PermanentDataStore.instance;
    }

    sync(): void {
        window.localStorage.setItem("local-data", JSON.stringify(this));
    }

    getToken(): string | null {
        return this.token;
    }

    removeToken(): PermanentDataStore {
        this.token = null;
        return this;
    }

    updateToken(token: string): PermanentDataStore {
        this.token = token;
        return this;
    }
}
