import { useGetUsersQuery } from "../gateways/cms/userApi";
import { SelectFilter } from "./SelectFilter";

export interface Props {
    value: string[];
    onChange: (value: string[]) => unknown;
}

export function SelectUserFilter({ value, onChange }: Props) {
    const users = useGetUsersQuery();
    const options = users.data?.data?.map((u) => ({ value: String(u.id), label: u.name })) ?? [];

    return <SelectFilter options={options} value={value} onChange={onChange} />;
}
