import { ChangeEvent, FormEvent, useState } from "react";
import { TaskUpdateFormRequest } from "../gateways/cms/task";
import { SelectTaskStatus } from "./SelectTaskStatus";

import { styled } from "@mui/system";
import Color from "../styles/colors.js";
import VarConst from "../styles/variables.js";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import iconPlus from "../assets/icons/icon_plus.svg";

// style
const BarFooter = styled("footer")`
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 24px 40px;
    background-color: ${Color.bgHead};

    & > div {
        align-items: end;

        div {
            display: flex;
            flex-wrap: wrap;
            width: 30em;
        }
    }
    ul {
        flex: 1;

        li {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    button {
        width: 116px;
        height: 32px;
    }
    label {
        display: block;
        width: 100%;
    }
    select {
        height: 32px;
        width: 180px;
    }
    select,
    textarea {
        border: ${VarConst.border1};
        padding: 4px 8px;
    }
    textarea {
        width: 50%;
    }

    .file {
        display: flex;
        align-items: center;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 88px;
            width: 88px;
            margin-right: 24px;
            padding: 4px;
            border: ${VarConst.border1};
            text-align: center;
            background-color: #fff;

            img {
                height: auto;
                width: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }
        input[type="file"] {
            height: 74px;
            width: 74px;
            color: #f4f4f4;
            border-radius: 8px;
            cursor: pointer;
            user-select: none;
            overflow: hidden;

            &::before {
                content: "画像を添付";
                display: block;
                width: 100%;
                height: 100%;
                background: #3a3e5d url(${iconPlus}) scroll no-repeat center 70%;
                background-size: 33%;
                text-align: center;
                padding-top: 0.5em;
                font-size: 12px;
            }
            &::file-selector-button {
                display: none;
            }
        }
    }
`;
const ButtonFooter = styled(Button)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
`;

export interface Props {
    data: TaskUpdateFormRequest;
    onSubmit: (data: TaskUpdateFormRequest) => unknown;
}

export function TaskEditForm({ data, onSubmit }: Props) {
    const [status, setStatus] = useState(data?.status || 1);
    const [comment, setComment] = useState("");
    const [images, setImages] = useState([] as string[]);
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            ...data,
            status,
            comment,
            file: images,
        });
    };

    const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        if (!files || !files.length) {
            return;
        }
        for (let i = 0; i < files.length; i++) {
            const s = new FileReader();
            s.onload = () => {
                setImages([...images, String(s.result)]);
            };
            s.readAsDataURL(files[i]);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <BarFooter>
                <ul>
                    <li>
                        <label htmlFor="status">状態</label>
                        <SelectTaskStatus value={status} onChange={setStatus} />
                    </li>
                    <li>
                        <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder={"コメントを入力してください。"}
                        ></textarea>
                    </li>
                    <li className="file">
                        {images.map((image, i) => (
                            <div>
                                <img key={i} src={image} alt="example" width="64px" />
                            </div>
                        ))}
                        <input type="file" multiple onChange={handleSelectFile} />
                    </li>
                </ul>

                <Stack spacing={2} direction="row">
                    <ButtonFooter type="button" variant="outlined" href="/tasks">
                        キャンセル
                    </ButtonFooter>
                    <ButtonFooter type="submit" variant="contained" disableElevation>
                        更新
                    </ButtonFooter>
                </Stack>
            </BarFooter>
        </form>
    );
}
