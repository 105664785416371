import { Article, ArticleStatus } from "../../gateways/cms/article";
import { useBulkUpdateArticleStatusMutation } from "../../gateways/cms/articleApi";

export function useArticleSuspend() {
    const [articleUpdate] = useBulkUpdateArticleStatusMutation();

    // 掲載停止の設定・解除
    const articleSuspend = (article: Article, suspended: boolean = true, deliveryGroupId: number, startDate: string) => {
        articleUpdate({
            articleIds: [article.id],
            status: suspended ? ArticleStatus.SUSPENDED : ArticleStatus.PUBLISHED,
            deliveryGroupId: deliveryGroupId,
            startDate: startDate,
        });
    };

    return articleSuspend;
}
