import * as React from 'react';
import { useNavigate } from "react-router-dom"
import { Article } from "../gateways/cms/article";
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
// import Fade from '@mui/material/Fade';

export interface Props {
    article: Article;
    deliveryGroupId: number;
    startDate: string;
    onArticleDuplicate: (id: number, modaloption: string, deliveryGroupId: number) => void;
    onArticleSuspend: (article: Article, suspended: boolean, deliveryGroupId: number, startDate: string) => void;
}

const MinimumMenu = styled((props: MenuProps) => (
    <Menu
      {...props}
    />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
        borderRadius: 2,

            '& .MuiList-root': {
                padding: 0,
                },
            '& .MuiDivider-root': {
                marginTop: 0,
                marginBottom: 0,
            },
            '& .suspend': {
                color: 'FC6F6C',
            },
        },
    }));

export const ScheduleArticleMenu = ({
    article,
    deliveryGroupId,
    startDate,
    onArticleDuplicate,
    onArticleSuspend,
}: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // 各ボタンの処理
    const handleDetail = () => {
        setAnchorEl(null);
        navigate('/articles/' + article.id + '/edit');
    };
    const handleCopy = () => {
        setAnchorEl(null);
        onArticleDuplicate(article.id, "schedule", deliveryGroupId);
    };
    const handleSuspendTrue = () => {
        setAnchorEl(null);
        onArticleSuspend(article, true, deliveryGroupId, startDate);
    };
    const handleSuspendFalse = () => {
        setAnchorEl(null);
        onArticleSuspend(article, false, deliveryGroupId, startDate);
    };

        
    return (
        <div>
        <Button
            id="schedul-article-menu-button"
            aria-controls={open ? 'schedul-article--menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
        </Button>
        <MinimumMenu
            id="schedul-article--menu"
            MenuListProps={{
            'aria-labelledby': 'schedul-article-menu-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            // TransitionComponent={Fade}
        >
            <MenuItem onClick={handleDetail} href={`/articles/${article.id}/edit`}>詳細情報</MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleCopy}>コピー</MenuItem>            
            <Divider sx={{ my: 0.5 }} />
            { article.status.toString() === "掲載中" 
                ? <MenuItem onClick={handleSuspendTrue} className="suspend">掲載を一時停止</MenuItem>
                : <MenuItem onClick={handleSuspendFalse}>掲載</MenuItem>
            }
        </MinimumMenu>
        </div>
    );
}