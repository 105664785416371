import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactLoading from 'react-loading';

import { ArticleForm } from "../../components/ArticleForm";
import { Article, ArticleForm as ArticleFormData } from "../../gateways/cms/article";
import {
    useBulkDeleteArticlesMutation,
    useGetArticleQuery,
    useUpdateArticleMutation,
} from "../../gateways/cms/articleApi";
import { useGetMyUserQuery } from "../../gateways/cms/userApi";
import MainLayout from "../../layouts/MainLayout";
import { confirmWithStyle } from "../../components/MyConfirm";
import { selectOrganizationId } from "../../features/cms/organizationBreadcrumbSlice";

import { styled } from "@mui/system";
import ButtonMUI from "@mui/material/Button";
import VarConst from "../../styles/variables.js";

// style
const PageTitle = styled("h1")`
    margin: 0;
    padding: 24px;
    font-size: 24px;
    font-weight: normal;

    & + form,
    & + article{
        flex: 1;
        overflow: auto;
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    width: 11em;
    border-radius: 2px;
    border: ${VarConst.border2};
    background-color: #fff;
`;

const Loading = styled("section")`
    position: relative;
    left: 45%;
    top: 35%;
    b {
        font-size: 12px;
        color: #A9A9A9;
        font-weight: normal;
    }
`; 

// [コンテンツ編集]

export const ArticleEdit = () => {
    const { id } = useParams() as { id: string };
    const article = useGetArticleQuery(id);
    const [articleBulkDelete] = useBulkDeleteArticlesMutation();
    const navigate = useNavigate();
    const [articleUpdate] = useUpdateArticleMutation();
    const organizationId = useSelector(selectOrganizationId);
    const user = useGetMyUserQuery();
    const [checkSaveAs, setSaveAs] = useState(false);

    if (!article.data) {
        return (
            <MainLayout title="コンテンツ詳細・編集">
                <Loading>
                    <ReactLoading type="bars" color="#ADD8E6" height={'-1%'} width={'8%'} />
                    <b>コンテンツデータ取得中</b>
                </Loading>
            </MainLayout>
        );
    };

    const handleSubmit = (articleForm: ArticleFormData) => {
        setSaveAs(true);
        articleUpdate(articleForm).then(() => navigate(-1));
    };

    const deleteArticle = async () => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "このコンテンツを削除します。よろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            articleBulkDelete([Number(id)]).then(() => navigate(-1));
        }
    };

    const pageTitle = (article: Article):string => {
        const editTitle = "コンテンツ編集";
        const detailTitle = "コンテンツ詳細";
        if(user.data?.checkAdmin) return editTitle;
        if(article.createdBy.id === user.data?.id) return editTitle; 
        return detailTitle;
    };

    return (
        <MainLayout title="コンテンツ詳細・編集">
            <PageTitle>{pageTitle(article.data)}</PageTitle>
            {article.data && (
                <ArticleForm
                    article={article.data}
                    organizationId={organizationId}
                    checkSaveAs={checkSaveAs} 
                    onSubmit={handleSubmit}
                    deleteButton={
                        <Button variant="outlined" color="error" disabled={checkSaveAs} type="button" onClick={deleteArticle}>
                            コンテンツ削除
                        </Button>
                    }
                    isEdit={true}
                />
            )}
        </MainLayout>
    );
};
