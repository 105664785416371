import produce from "immer";
import { FormEvent, ReactNode, useState } from "react";
import { InvitationForm as InvitationFormData } from "../gateways/cms/invitation";
import { SelectOrganization } from "./SelectOrganization";
import { SelectUserGroup } from "./SelectUserGroup";

import { styled } from "@mui/system";
import iconPlus from "../assets/icons/icon_plus.svg";
import iconDelete from "../assets/icons/icon_delete.svg";

// style
const InputInvite = styled("ul")`
    /* display: grid;
        grid-template-columns: 12.5% 12.5% 25% 50% ; */
    /* padding: 0 24px 24px; */
    display: flex;
    flex-wrap: wrap;

    & > li {
        margin-bottom: 16px;

        &:nth-of-type(1) {
            width: 35%;
        }
        &:nth-of-type(2) {
            flex: 1;
        }
        &:nth-of-type(3) {
            width: 100%;
        }
        &:nth-of-type(4) {
            width: 100%;
        }

        label {
            margin-bottom: 4px;
        }
        select,
        textarea {
            width: 12em;
            padding: 0.25em 0.5em;
        }
        select {
            width: 12em;
            height: 32px;
        }
        textarea {
            width: 100%;
            height: calc(1em * 1.5 * 3);
        }
        input[type="email"],
        button {
            height: 32px;
        }
        ul {
            padding-left: 0.5em;
            li {
                display: inline;
                word-break: break-all;

                & + li::before {
                    content: "、";
                }
            }
        }

        &:nth-of-type(2) {
            select {
                margin-right: 16px;
            }
        }
        &.email {
            margin-bottom: 0;

            & > div {
                display: flex;
                margin-bottom: 16px;

                input {
                    flex: 1;
                    margin-right: 8px;
                }
                button {
                    height: 32px;
                    width: 32px;
                    min-width: 32px;
                    border-width: 2px;
                    border-radius: 2px;
                    background-image: url(${iconDelete});
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-color: #fff;
                    background-size: 80%;
                    overflow: hidden;
                    color: transparent;
                    user-select: none;
                    cursor: pointer;
                    border-color: transparent;

                    &[disabled]{
                        pointer-events: none;
                        background-image: none;
                        background-color: transparent;
                    }
                }
            }
        }
    }
`;
const ButtonPlus = styled("button")`
    display: block;
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin: 16px auto 0;
    border: unset;
    border-radius: 2px;

    color: transparent;
    background-color: #3a3e5d;
    background-image: url(${iconPlus});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 62.5%;

    overflow: hidden;
    user-select: none;
`;

export interface Props {
    onSubmit: (data: InvitationFormData) => unknown;
    children: ReactNode;
}

export function InvitationForm({ children, onSubmit }: Props) {
    const [organizationId, setOrganizationId] = useState("");
    const [userGroupId, setUserGroupId] = useState("");
    const [users, setUsers] = useState([{ email: "" }]);
    const [comment, setComment] = useState("");

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            organizationId: Number(organizationId),
            usergroup: Number(userGroupId),
            email: users.map((u) => u.email),
            comment,
        });
    };

    const setUserEmail = (index: number, email: string) => {
        setUsers(
            produce(users, (newUsers) => {
                newUsers[index].email = email;
            })
        );
    };

    const addUser = () => {
        setUsers([...users, { email: "" }]);
    };

    const deleteUser = (index: number) => {
        setUsers(
            produce(users, (newUsers) => {
                newUsers.splice(index, 1);
            })
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            <InputInvite>
                <li>
                    <label htmlFor="organizationId">テナント</label>
                    <SelectOrganization value={organizationId} required onChange={setOrganizationId} />
                </li>
                <li>
                    <label htmlFor="userGroupId">権限</label>
                    <SelectUserGroup value={String(userGroupId)} required onChange={setUserGroupId} />
                    として招待する。
                </li>
                <li>
                    <textarea
                        placeholder="招待メールにコメントを追加する場合は入力してください。"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </li>
                <li className="email">
                    <label htmlFor="email">メールアドレス</label>
                    {users.map((user, i) => (
                        <div key={i}>
                            <input
                                type="email"
                                value={user.email}
                                required
                                onChange={(e) => setUserEmail(i, e.target.value)}
                            />
                            {/* 課題 NEXT_SIGNAGE_CMS-133: 0件目ではメールアドレスフォームの削除ボタンを表示しない */}
                            {i > 0
                                ? <button type="button" onClick={() => deleteUser(i)}>削除</button>
                                : <button type="button" disabled></button>
                            }
                        </div>
                    ))}

                    <ButtonPlus type="button" onClick={addUser}>
                        メールアドレスを追加
                    </ButtonPlus>
                </li>
            </InputInvite>

            {children}
        </form>
    );
}
