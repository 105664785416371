import React from "react";
import { IconButton } from "@mui/material";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import MuiAlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import uniqueString from "unique-string";

export type NotificationAlertData = {
    severity: AlertColor;
    title: string;
    message?: string;
    onClose?: () => void;
};

export type NotificationAlertDataMap = { [key: string]: NotificationAlertData };

export type AlertContextType = {
    notificationAlerts: NotificationAlertDataMap;
    pushNotificationAlert: (alert: NotificationAlertData) => void;
    removeNotificationAlert: (key: string) => void;
    clearNotificationAlert: () => void;
};

// コンテキストの作成
const NotificationAlertContext = React.createContext<AlertContextType>({} as AlertContextType);

// プロバイダの作成
export const NotificationAlertProvider = ({ children }: { children: React.ReactNode }) => {
    const [notificationAlerts, setAlerts] = React.useState<NotificationAlertDataMap>({});

    /// アラートを画面に追加する処理
    const pushNotificationAlert = React.useCallback((alert: NotificationAlertData) => {
        setAlerts((prevAlerts) => {
            let uniqueKey = uniqueString();
            while (prevAlerts[uniqueKey]) {
                uniqueKey = uniqueString();
            }
            return { ...prevAlerts, [uniqueKey]: alert };
        });
    }, []);

    /// アラートを画面から取り除く処理
    const removeNotificationAlert = React.useCallback((key: string) => {
        setAlerts((prevAlerts) => {
            const newAlerts = { ...prevAlerts };
            if (newAlerts[key]) {
                delete newAlerts[key];
            }
            return newAlerts;
        });
    }, []);

    // 全アラートを削除する
    const clearNotificationAlert = React.useCallback(() => {
        setAlerts({});
    }, []);

    return (
        <NotificationAlertContext.Provider
            value={{
                notificationAlerts,
                pushNotificationAlert,
                removeNotificationAlert,
                clearNotificationAlert,
            }}
        >
            {children}
        </NotificationAlertContext.Provider>
    );
};

// アラート操作APIの提供
export const useNotificationAlert = () => {
    return React.useContext(NotificationAlertContext);
};

// 閉じるボタンの作成
const CloseIconButton = ({ onClick }: { onClick: () => void }) => (
    <IconButton aria-label="close" color="inherit" size="small" onClick={onClick}>
        <CloseIcon fontSize="inherit" />
    </IconButton>
);

// アラートUIの提供
export default function NotificationAlert() {
    const { removeNotificationAlert, notificationAlerts } = useNotificationAlert();

    return Object.entries(notificationAlerts).length > 0 ? (
        <Stack sx={{ width: "100%", mt: 2, mb: 2 }} spacing={2}>
            {Object.entries(notificationAlerts).map((values, index) => {
                const alertId = values[0];
                const { severity, title, message, onClose } = values[1];

                return (
                    <MuiAlert
                        key={index}
                        severity={severity}
                        action={
                            <CloseIconButton
                                onClick={() => {
                                    removeNotificationAlert(alertId);
                                    if (onClose !== undefined) {
                                        onClose();
                                    }
                                }}
                            />
                        }
                    >
                        {
                            // `message`変数がセットされていて空文字列でなければ、`true`
                            message !== undefined && message.length > 0 ? (
                                <>
                                    <MuiAlertTitle>{title}</MuiAlertTitle>
                                    {message}
                                </>
                            ) : (
                                <>{title}</>
                            )
                        }
                    </MuiAlert>
                );
            })}
        </Stack>
    ) : null;
}
