import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
import Header from "../components/layout/Header";
import GlobalMenu from "../components/layout/GlobalMenu";
// import Copyright from "../components/Copyright";
import NotificationAlert, { NotificationAlertProvider } from "../components/NotificationAlert";

// import { styled } from '@mui/system';


interface MainLayoutProps {
    children: React.ReactNode;
    title: string;
}

export default function MainLayout({ children, title }: MainLayoutProps) {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ 
            display: "flex",
            height: "100vh",
            }}>
            <CssBaseline />

            <Header isDrawerOpen={open} toggleDrawer={toggleDrawer} title={title} />

            <GlobalMenu isDrawerOpen={open} toggleDrawer={toggleDrawer} />

            {/* Page Main */}
            <Box
                component="main"
                className="page-main"
                sx={{
                    // backgroundColor: (theme) =>
                    //   theme.palette.mode === 'light'
                    //     ? theme.palette.grey[100]
                    //     : theme.palette.grey[900],
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    overflow: "auto",
                    position: "relative",
                }}
            >
                {/*<Toolbar />*/}
                <NotificationAlertProvider>
                    <NotificationAlert />

                    {/* Contents */}
                    {children}

                    {/* Footer */}
                    {/*<Copyright sx={{ pt: 4 }} />*/}
                </NotificationAlertProvider>
            </Box>
        </Box>
    );
}
