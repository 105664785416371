import { PropsWithChildren } from "react";

interface Props {
    selected: boolean;
    onSelect: (selecting: boolean) => unknown;
}

export function DeliveryRuleList({ children, selected, onSelect }: PropsWithChildren<Props>) {
    return (
        <table>
            <thead>
                <tr>
                    <th className="checkbox">
                        <input type="checkbox" checked={selected} onChange={(e) => onSelect(e.target.checked)} />
                        <span></span>
                    </th>
                    <th>スケジュール</th>
                    <th className="on">電源ON</th>
                    <th className="off">電源OFF</th>
                    <th className="function">操作</th>
                </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    );
}
