import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import config from "../Config";

export default function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link
                color="inherit"
                href={config.copyright.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {config.copyright.text + "."}
            </Link>
        </Typography>
    );
}
