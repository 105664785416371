import { useSearchParams } from "react-router-dom";

export const useSetSearchParam =
    (...[searchParams, setSearchParams]: ReturnType<typeof useSearchParams>) =>
    (key: string) =>
    (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(key, value);
        setSearchParams(newSearchParams);
    };

export const useSetSearchParamArray =
    (...[searchParams, setSearchParams]: ReturnType<typeof useSearchParams>) =>
    (key: string) =>
    (values: string[]) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(key);
        values.forEach((value) => {
            newSearchParams.append(key, value);
        });
        setSearchParams(newSearchParams);
    };
