import assert from "assert";
import { DeliveryGroupCreateFormRequest } from "../gateways/cms/deliveryGroup";
import { SelectDeliveryGroup } from "./SelectDeliveryGroup";

import { styled } from "@mui/system";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";

export interface Props {
    data: DeliveryGroupCreateFormRequest;
    onChange: (data: DeliveryGroupCreateFormRequest) => unknown;
}

// style
const InputList = styled("ul")`
    width: 100%;

    & > li{
        width: 100%;
        margin-bottom: 16px;

        label{
            display: block;
            width: 100%;
            margin-bottom: 4px;
            position: relative;

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate( -50%, -50%);

                & + span{
                    display: flex;
                    align-items: center;

                    &::before {
                        content: "";
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        margin-right: 0.5em;
                        pointer-events: none;
                        background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                    }
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
            }
        }
        input[type="text"],
        select{
            width: 100%;
            height: 32px;
            padding: 0 0.5em;
        }
    }
`;

export function DeliveryGroupCreateForm({ data, onChange }: Props) {
    assert(data.organizationId);

    return (
        <main>
            <InputList>
                <li>
                    <label htmlFor="name">配信グループ名</label>
                    <input
                        type="text"
                        value={data.name ?? ""}
                        required
                        onChange={(e) => onChange({ ...data, name: e.target.value })}
                    />
                </li>
                <li>
                    <label>
                        <input
                            type="checkbox"
                            checked={data.copyEnabled}
                            onChange={(e) => onChange({ ...data, copyEnabled: e.target.checked })}
                        />
                        <span>
                            配信グループのコンテンツをコピーする
                        </span>
                    </label>
                    <label>
                        <SelectDeliveryGroup
                            organizationId={String(data.organizationId)}
                            value={String(data.originalDeliveryGroupId ?? "")}
                            disabled={!data.copyEnabled}
                            required={data.copyEnabled}
                            onChange={(id) => onChange({ ...data, originalDeliveryGroupId: id ? Number(id) : undefined })}
                        />
                    </label>
                </li>
            </InputList>
        </main>
    );
}
