const variables = {
    border0: "0   none  transparent",
    border1: "1px solid #daddde",
    border2: "2px solid #daddde",
    headerH: "40px",
    chkboxSize: "24px",
    // $PC-min: 768px;
    // $PC-max: 1360px;
    // $Mobile-max: $PC-min - 1px;

    // $breakpoints: (
    //     "MB-s": (
    //         min-width: 0,
    //     ),
    //     // XS
    //     "MB":
    //         (
    //             min-width: 576px,
    //         ),
    //     // S / smartphone
    //     "MB-l":
    //         (
    //             min-width: 728px,
    //         ),
    //     // M / iPad
    //     "PC-s":
    //         (
    //             min-width: 992px,
    //         ),
    //     // l
    //     "PC":
    //         (
    //             min-width: 1200px,
    //         ),
    //     // XL
    //     "PC-l":
    //         (
    //             min-width: 1400px,
    //         ),
    //     // XXL
    //     "MB-s-max":
    //         (
    //             max-width: 0,
    //         ),
    //     "MB-max": (
    //         max-width: 575px,
    //     ),
    //     // smartphone
    //     "MB-l-max":
    //         (
    //             max-width: 727px,
    //         ),
    //     // iPad
    //     "PC-s-max":
    //         (
    //             max-width: 991px,
    //         ),
    //     "PC-max": (
    //         max-width: 1199px,
    //     ),
    //     "PC-l-max": (
    //         max-width: 1399px,
    //     ),
    // ) !default;

    // $font-size: (
    //     "XXS": (
    //         font-size: 10px,
    //     ),
    //     "XS": (
    //         font-size: 12px,
    //     ),
    //     "S": (
    //         font-size: 14px,
    //     ),
    //     "M": (
    //         font-size: 16px,
    //     ),
    //     "L": (
    //         font-size: 18px,
    //     ),
    //     "XL": (
    //         font-size: 20px,
    //     ),
    //     "XXL": (
    //         font-size: 24px,
    //     ),
    // ) !default;
};

export default variables;
