import { DeliverySchedule, ScheduleTypeNames } from "../gateways/cms/deliveryRule";
import { EditMenuMinimum } from "../components/materials/EditMenuMinimum";

import { styled } from "@mui/system";
import VarConst from "../styles/variables.js";
import { PeriodWeekDay } from "../components/PeriodWeekDay";

export interface Props {
    item: DeliverySchedule;
    selected: boolean;
    onSelect: (item: DeliverySchedule, selected: boolean) => unknown;
    onEdit: (item: DeliverySchedule) => unknown;
    onDelete: (item: DeliverySchedule) => unknown;
}

// style
const WeeklyPattern = styled("ul")`
    display: inline;
    /* align-items: center; */
    /* height: 3em; */
    padding: 0;
    /* border-bottom: ${VarConst.border1}; */
    list-style: outside none;
    margin-left: 0.25em;

    li {
        /* flex: 1; */
        /* text-align: center; */
        display: inline;
        margin-right: 0.5em;

        &:nth-of-type(1) {
            color: #f00;
        }
        &:nth-of-type(7) {
            color: #00f;
        }
        &.off {
            opacity: 0.1;
        }
    }

    & + span {
        display: flex;
        align-items: center;
        height: 3em;
        padding: 0 8px;
    }
`;

export function DeliveryRuleListItem({ item, selected, onSelect, onEdit, onDelete }: Props) {

    return (
        <tr>
            <td className="checkbox">
                <input
                    type="checkbox"
                    value={item.id}
                    checked={selected}
                    onChange={(e) => onSelect(item, e.target.checked)}
                />
                <span></span>
            </td>
            <td>
                {ScheduleTypeNames[item.type]} (
                {item.type === 1 ? (
                    <> ({item.day})</>
                ) : (
                    <>
                        <WeeklyPattern>
                            <PeriodWeekDay value={item.week} />
                        </WeeklyPattern>
                    </>
                )}
                )
            </td>
            {item.poweron ? (
                <>
                    <td>
                        {item.startTime}〜{item.endTime}
                    </td>
                    <td></td>
                </>
            ) : (
                <>
                    <td></td>
                    <td>
                        {item.startTime}〜{item.endTime}
                    </td>
                </>
            )}
            <td>
                <EditMenuMinimum
                    menuEdit={
                        <button type="button" onClick={() => onEdit(item)}>
                            編集
                        </button>
                    }
                    menuDelete={
                        <button type="button" onClick={() => onDelete(item)}>
                            削除
                        </button>
                    }
                />
            </td>
        </tr>
    );
}
