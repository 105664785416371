import { useSelector } from "react-redux";
import { BillingDataDownloadForm } from "../../../components/BillingDataDownloadForm";
import { BillingDataDownloadRequest } from "../../../gateways/cms/billingData";
import { billingDataDownload } from "../../../gateways/cms/billingDataDownload";
import MainLayout from "../../../layouts/MainLayout";
import { RootState } from "../../../store";

import { styled } from "@mui/system";

export function BillingDataPage() {
    const token = useSelector((state: RootState) => state.auth.token);
    const download = async (request: BillingDataDownloadRequest) => {
        const response = await billingDataDownload(token, request);
        if (response.ok) {
            window.open(URL.createObjectURL(await response.blob()));
        } else {
            alert("error");
        }
    };

    // style
    const PageTitle = styled("h1")`
        /* display: flex;
        justify-content: flex-start; */
        padding: 24px;
        // margin-bottom: 16px;
        font-size: 24px;
        font-weight: normal;
        margin: 0 40px 0 0;
    `;
    const PageContent = styled("section")`
        padding: 0 40px 24px;
    `;
    return (
        <MainLayout title="請求データダウンロード">
            <PageTitle>請求データダウンロード</PageTitle>
            <PageContent>
                <BillingDataDownloadForm onSubmit={download} />
            </PageContent>
        </MainLayout>
    );
}
