import { createTheme } from "@material-ui/core/styles";

// フォントを設定
// const fontFamily = [
//     "Noto Sans JP",
//     "メイリオ",
//     "ＭＳ Ｐゴシック",
//     "sans-serif",
//     // 使用したいフォントを以降に羅列してください
// ].join(",");

/*
 * デフォルトからテーマを生成して上書き
 *
 * 各設定値: https://mui.com/customization/default-theme/
 */
const theme = createTheme({
    // typography: {
    //   fontFamily: fontFamily,  // フォント
    // },

    // カラー設定
    palette: {
        primary: {
            main: "#3A3E5D",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#00e676",
            contrastText: "#ffffff",
        },
        // type: 'dark', // ダークモードをON
    },
    // mixins: {
    //   // ツールバーの高さ
    //   toolbar: {r
    //     minHeight: 64,
    //   },
    // },
    // 各パーツのスタイルをカスタマイズ
    // props: {
    //   MuiButton: {
    //     height: '32px',
    //     color: 'contrastText',
    //   },
    //   MuiCheckbox: {
    //     color: 'primary',
    //   },
    //   MuiList: {
    //     dense: true,
    //   },
    //   MuiTable: {
    //     size: 'small',
    //   },
    //   MuiTextField: {
    //     variant: 'outlined',
    //   },
    // },
});

export default theme;
