import produce from "immer";
import { ChangeEvent } from "react";

import { styled } from "@mui/system";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";

// [コンテンツ編集] 繰返し 曜日設定

export interface Props {
    value: number[];
    disabled?: boolean;
    onChange: (days: number[]) => void;
}

const dayOptions = ["日", "月", "火", "水", "木", "金", "土"];

export function SelectDay({ value, disabled, onChange }: Props) {
    const handleChange = (day: number) => (e: ChangeEvent<HTMLInputElement>) => {
        onChange(
            produce(value, (draft) => {
                draft[day] = Number(e.target.checked);
            })
        );
    };

    // style
    const SelectDayWrapper = styled("div")`
        display: flex;
    `;
    const LabelDay = styled("label")`
        position: relative;
        padding-right: 8px;

        &:nth-of-type(1) {
            color: #f00;
        }
        &:nth-of-type(7) {
            color: #00f;
        }

        input[type="checkbox"] {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            width: 24px;
            height: 24px;
            margin: 0;
            opacity: 0;
            cursor: pointer;
            transform: translate( -50%, -50%);

            & + span{
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-right: 0.5em;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
            }
            &:checked + span::before {
                background-image: url(${iconCheckboxOn});
            }
        }
    `;


    return (
        <SelectDayWrapper>
            {dayOptions.map((dayName, day) => (
                <LabelDay key={day}>
                    <input
                        type="checkbox"
                        disabled={disabled}
                        checked={Boolean(value[day])}
                        onChange={handleChange(day)}
                    />
                    <span>
                        {dayName}
                    </span>
                </LabelDay>
            ))}
        </SelectDayWrapper>
    );
}
