import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectedUser {
    id: number;
    name: string;
    email: string;
}

export const invitationSlice = createSlice({
    name: "invitation",
    initialState: {
        users: [] as SelectedUser[],
    },
    reducers: {
        selected: (state, action: PayloadAction<SelectedUser[]>) => {
            state.users = action.payload;
        },
        revoked: (state) => {
            state.users = [];
        },
    },
});

export const { selected, revoked } = invitationSlice.actions;

export default invitationSlice.reducer;
