import { Dialog } from "@material-ui/core";
import { SchemaDefinitionItemFormRequest } from "../gateways/cms/schema";
import { SchemaDefinitionItemEditForm } from "./SchemaDefinitionItemForm";

import { styled } from "@mui/system";
import VarConst from "../styles/variables.js";

export interface Props {
    data?: SchemaDefinitionItemFormRequest;
    onSubmit: (data: SchemaDefinitionItemFormRequest) => unknown;
    onDismiss: () => unknown;
}

// style
const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        header {
            text-align: center;
            padding: 40px 32px 0;

            h1 {
                font-size: 24px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
            .message {
                margin: 40px 0 0;
            }
        }
        main {
            /* display: flex;
            flex-wrap: wrap; */
            padding: 24px 56px 40px;

            label {
                display: flex;
                align-items: center;
            }
            input[type="text"],
            select{
                height: 32px;
            }
            input[type="text"],
            select,
            textarea{
                width: 100%;
                border: ${VarConst.border1};
            }
            textarea{
                height: 75px;
            }

            ul{
                display: grid;
                grid-template-columns: 180px 1fr 180px;

                li{
                    margin-bottom: 16px;

                    &:nth-of-type(1){ grid-row: 1/2; grid-column: 1/2;}
                    &:nth-of-type(2){ grid-row: 1/2; grid-column: 2/4; padding-left: 24px;}
                    &:nth-of-type(3){ grid-row: 2/3; grid-column: 1/4;}
                    &:nth-of-type(4){ grid-row: 3/4; grid-column: 1/3; padding-right: 24px;}
                    &:nth-of-type(5){ grid-row: 3/4; grid-column: 3/4;}
                    &:nth-of-type(6){ grid-row: 4/5; grid-column: 1/4;
                        div{
                            display: flex;
                            margin-bottom: 16px;

                            & > input:first-of-type{
                                width: 32px;
                                text-align: center;
                                margin-right: -1px;
                            }
                            input{
                                padding: 0 8px;
                            }
                            button{
                                margin-left: 8px;
                            }
                        }
                    }

                }
            }


        }

        footer {
            padding: 0 32px;
            background-color: #f4f4f4;

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
            }

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;

export function SchemaDefinitionItemEditModal({ data, onSubmit, onDismiss }: Props) {
    return (
        <DialogOuter aria-labelledby="label" open={!!data} onClose={onDismiss}>
            {data && <SchemaDefinitionItemEditForm data={data} onSubmit={onSubmit} onDismiss={onDismiss} />}
        </DialogOuter>
    );
}
