import { FormEvent, useState } from "react";
import { UserForm } from "../gateways/cms/user";

export interface Props {
    data: UserForm;
    btnGroup?: JSX.Element;
    onSubmit: (data: UserForm) => void;
}

export function MyUserForm({ data, btnGroup, onSubmit }: Props) {
    const [name, setName] = useState(data.name);
    const [email, setEmail] = useState(data.email);
    const [loginId, setLoginId] = useState(data.loginId);
    const [password, setPassword] = useState("");

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            id: data.id,
            name,
            email,
            loginId,
            password,
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <ul>
                <li>
                    <label htmlFor="name">名前</label>
                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />

                </li>
                <li>
                    <label htmlFor="email">メール</label>
                    <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                </li>
                <li>
                    <label htmlFor="loginId">ID</label>
                    <input
                        type="text"
                        id="loginId"
                        value={loginId}
                        placeholder="********"
                        onChange={(e) => setLoginId(e.target.value)}
                    />

                </li>
                <li>
                    <label htmlFor="password">PW</label>
                    <input
                        type="text"
                        id="password"
                        value={password}
                        placeholder="********"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </li>
            </ul>

            {btnGroup}
        </form>
    );
}
