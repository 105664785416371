import { FormEvent, useState } from "react";

import { styled } from "@mui/system";
import VarConst from "../styles/variables.js";
import Button from "@material-ui/core/Button";

// style
const DialogApprove = styled("section")`
    background-color: #f4f4f4;
    padding: 16px 0;

    form {
        width: 630px;
        max-width: 100%;
        margin: 0 auto;
        background-color: #fff;
        border: 1px solid #daddde;

        header {
            text-align: center;
            padding: 24px 0;

            h2 {
                font-size: 20px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
            .message {
                margin: 16px 0 0;
            }
        }
        main {
            width: 70%;
            margin: 0 auto;
            padding: 0;

            input[type="text"] {
                height: 32px;
                width: 100%;
                border: ${VarConst.border1};
                padding: 4px;
            }
        }

        footer {
            padding: 16px 0 24px;

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }
                &.destructive {
                    color: #fd6f6c;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;

export interface Props {
    onAccept: () => unknown;
    onReject: (reason: string) => unknown;
}

export function ArticleApproveForm({ onAccept, onReject }: Props) {
    const [reason, setReason] = useState("");

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onReject(reason);
    };

    return (
        <DialogApprove>
            <form onSubmit={handleSubmit}>
                <header>
                    <h2>このコンテンツの掲載を承認しますか？</h2>
                    <p className="message">差戻の場合はその理由を記載してください。</p>
                </header>

                <main>
                    <input
                        type="text"
                        value={reason}
                        required
                        onChange={(e) => setReason(e.target.value)}
                        placeholder={"差戻理由"}
                    />
                </main>

                <footer>
                    <section className="buttons">
                        <Button type="submit" variant="outlined" className="destructive">
                            差戻
                        </Button>
                        <Button type="button" variant="contained" disableElevation onClick={onAccept}>
                            承認する
                        </Button>
                    </section>
                </footer>
            </form>
        </DialogApprove>
    );
}
