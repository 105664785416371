import { useNavigate } from "react-router-dom";
import { OrganizationForm } from "../../components/OrganizationForm";
import { OrganizationForm as OrganizationFormData } from "../../gateways/cms/organization";
import { useCreateOrganizationMutation } from "../../gateways/cms/organizationApi";
import MainLayout from "../../layouts/MainLayout";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";

// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;
const OrganizationFormFooter = styled("footer")`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 260px);
    padding: 16px 24px;
    background-color: #F4F4F4;
`;
const Button = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #C9CBD3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3A3E5D;
        border-color: #3A3E5D;
    }
`;

export function OrganizationCreatePage() {
    const navigate = useNavigate();
    const [organizationCreate] = useCreateOrganizationMutation();

    const handleSubmit = async (data: OrganizationFormData) => {
        await organizationCreate(data);
        navigate(-1);
    };

    return (
        <MainLayout title="テナント管理（新規作成）">
            <PageTitle>
                <h1>テナント管理（新規作成）</h1>
            </PageTitle>

            <OrganizationForm onSubmit={handleSubmit}>
                <OrganizationFormFooter>
                    <div className="footerLeft">
                        {/* TODO:<確認> [テナント削除]ボタンが無い */}
                    </div>
                    <div className="footerRight">
                        <Button type="button" variant="outlined"  href="/organizations">キャンセル</Button>
                        <Button type="submit" variant="contained" disableElevation >保存</Button>
                    </div>
                </OrganizationFormFooter>
            </OrganizationForm>

        </MainLayout>
    );
}
