import IconButton from "@mui/material/IconButton";
// import { Logout as LogoutIcon } from "@mui/icons-material";
import logout from "../api-clients/actions/logout";
import { useDispatch } from "react-redux";
import { revoked as tokenRevoked } from "../features/auth/authSlice";
import { revoked as organizationRevoked } from "../features/cms/organizationBreadcrumbSlice";
import { revoked as invitationRevoked } from "../features/cms/invitationSlice";
import { useNavigate } from "react-router-dom";
import { userApi } from "../gateways/cms/userApi";
import { articleApi } from "../gateways/cms/articleApi";
import { billingRuleApi } from "../gateways/cms/billingRuleApi";
import { deliveryGroupApi } from "../gateways/cms/deliveryGroupApi";
import { deliveryRuleApi } from "../gateways/cms/deliveryRuleApi";
import { displayModelApi } from "../gateways/cms/displayModelApi";
import { invitationApi } from "../gateways/cms/invitationApi";
import { logApi } from "../gateways/cms/logApi";
import { organizationApi } from "../gateways/cms/organizationApi";
import { scheduleApi } from "../gateways/cms/scheduleApi";
import { schemaApi } from "../gateways/cms/schemaApi";
import { taskApi } from "../gateways/cms/taskApi";
import { terminalApi } from "../gateways/cms/terminalApi";
import { userGroupApi } from "../gateways/cms/userGroupApi";

export default function LogoutButton() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        // ログアウトAPIをコール
        logout().catch((e: any) => {
            console.error(e);
        });

        // ログアウト状態にする
        dispatch(tokenRevoked());
        dispatch(userApi.util.resetApiState());

        // reduxのリセット
        dispatch(organizationRevoked());
        dispatch(invitationRevoked());

        // localStorage のクリア
        localStorage.clear();

        // APIのリセット
        dispatch(articleApi.util.resetApiState());
        dispatch(billingRuleApi.util.resetApiState());
        dispatch(deliveryGroupApi.util.resetApiState());
        dispatch(deliveryRuleApi.util.resetApiState());
        dispatch(displayModelApi.util.resetApiState());
        dispatch(invitationApi.util.resetApiState());
        dispatch(logApi.util.resetApiState());
        dispatch(organizationApi.util.resetApiState());
        dispatch(scheduleApi.util.resetApiState());
        dispatch(schemaApi.util.resetApiState());
        dispatch(taskApi.util.resetApiState());
        dispatch(terminalApi.util.resetApiState());
        dispatch(userGroupApi.util.resetApiState());


        // ログイン画面に遷移
        navigate("/login");
    };

    return (
        <IconButton color="inherit" onClick={handleLogout}>
            ログアウト
        </IconButton>
    );
}
