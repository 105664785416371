import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config/api";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { Page } from "./page";
import { Schema, OptionType, SchemaDefinitionFormRequest, SchemaDefinitionQuery } from "./schema";

export const schemaApi = createApi({
    reducerPath: "schemaApi",
    baseQuery,
    tagTypes: ['Schema'],
    endpoints: (builder) => ({
        getSchemaDefinitions: builder.query<Page<Schema>, SchemaDefinitionQuery>({
            query: (params) => ({
                url: "schemas/group/list",
                params,
            }),
            providesTags: ['Schema'],
        }),
        getSchemaDefinition: builder.query<Schema, Id>({
            query: (id) => ({
                url: `schemas/group/${id}`,
            }),
            providesTags: ['Schema'],
        }),
        getSchemaTypeDefinitions: builder.query<Schema[], Id>({
            query: (id) => ({
                url: `schema_type/${id}`,
            }),
            providesTags: ['Schema'],
        }),
        getDefaultUrl: builder.query<String, void>({
            query: () => ({
                url: "default/url",
            }),
            providesTags: ['Schema'],
        }),
        getDefaultSchema: builder.query<OptionType[], void>({
            query: () => ({
                url: "default/schema",
            }),
            providesTags: ['Schema'],
        }),
        createSchemaDefinition: builder.mutation<void, SchemaDefinitionFormRequest>({
            query: (body) => ({
                url: "schemas/group",
                method: "post",
                body,
            }),
            invalidatesTags: ['Schema'],
        }),
        updateSchemaDefinition: builder.mutation<void, SchemaDefinitionFormRequest>({
            query: ({ id, ...body }) => ({
                url: `schemas/group/${id}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Schema'],
        }),
        bulkDeleteSchemaDefinition: builder.mutation<void, number[]>({
            query: (ids) => ({
                url: "schemas/group",
                method: "delete",
                body: {
                    schemaGroupId: ids,
                },
            }),
            invalidatesTags: ['Schema'],
        }),
    }),
});

export const getSchemaDefinition = async (token: string, id: number) => {
    const response = await fetch(new URL(`schemas/group/${id}`, baseUrl).toString(), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if (response.ok) {
        return (await response.json()) as Schema;
    }
};

export const {
    useGetSchemaDefinitionsQuery,
    useGetSchemaDefinitionQuery,
    useGetSchemaTypeDefinitionsQuery,
    useGetDefaultUrlQuery,
    useGetDefaultSchemaQuery,
    useCreateSchemaDefinitionMutation,
    useUpdateSchemaDefinitionMutation,
    useBulkDeleteSchemaDefinitionMutation,
} = schemaApi;
