import * as React from "react";
import Typography from "@mui/material/Typography";
import { createTheme, Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import { red } from "@material-ui/core/colors";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
const theme = createTheme();

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            color: "#FFF",
            backgroundColor: red[600],
            padding: theme.spacing(2),
            fontWeight: "bold !important" as any,
        },
    })
);

interface Props {
    sx?: SxProps<Theme>;
    message: string | null;
}

export function FormErrorMessage({ message, sx }: Props): JSX.Element {
    const classes = useStyles();
    sx = sx || { m: 2 };
    return (
        <React.Fragment>
            {message !== null && message.length > 0 && (
                <Box sx={sx}>
                    <Typography
                        variant="body1"
                        gutterBottom
                        component="pre"
                        className={classes.root}
                    >
                        {message}
                    </Typography>
                </Box>
            )}
        </React.Fragment>
    );
}
