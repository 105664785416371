import * as React from "react";
// import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import { styled } from "@mui/material/styles";
import iconSort from "../assets/icons/icon_sort.svg";
import VarConst from "../styles/variables.js";

export interface Props {
    // onSubmit: (direction: string) => unknown;
    menuAsc: React.ReactNode;
    menuDesc: React.ReactNode;
    optionMenu?: JSX.Element;
}

// style
const MinimumMenu = styled((props: MenuProps) => <Menu {...props} />)`
    #schedul-article-menu-button {
        min-width: 24px;
    }
    & .MuiPaper-root {
        border-radius: 2px;
        width: 200px;
        font-size: 14px;
        max-height: calc(100vh - 258px) !important;

        display: flex;
        flex-direction: column;
        overflow: hidden;

        & .MuiList-root {
            padding: 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex: 1;

            .MuiMenuItem-root {
                width: 100%;
                padding: 0;

                & > * {
                    display: flex;
                    width: 100%;
                    padding: 6px 16px;
                    font-size: 1em; //14px;
                    background-color: transparent;
                    border: 0 none transparent;
                    cursor: pointer;
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
        & .MuiDivider-root {
            margin-top: 0;
            margin-bottom: 0;
        }
        & .optionmenu {
            margin-top: "16px";
        }
        & h5 {
            margin: 0;
            padding: 16px;
            line-height: 1;
            font-weight: 400;
            font-size: 14px;
        }
    }
`;
const SortButton = styled(Button)`
    display: block;
    padding: 0;
    margin: 0;
    height: 24px;
    width: 24px;
    border: ${VarConst.border0};
    overflow: hidden;
    color: transparent;
    background-image: url(${iconSort});
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;

    &.MuiButton-root {
        min-width: 24px;
    }
`;

// TODO: リストが長いと最下部のボタンが見切れる > ダイアログの表示開始位置を取得して開始位置分高さを縮める

export const SortMenu = ({ menuAsc, menuDesc, optionMenu }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    // POP表示基準位置を設定
    const targetElRef = React.useRef(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };

    return (
        <div ref={targetElRef}>
            <SortButton
                id="schedul-article-menu-button"
                aria-controls={open ? "schedul-article--menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            ></SortButton>
            <MinimumMenu
                id="schedul-article--menu"
                MenuListProps={{
                    "aria-labelledby": "schedul-article-menu-button",
                }}
                // anchorEl={anchorEl}
                anchorEl={targetElRef.current}
                open={open}
                onClose={close}
            >
                <h5>並び替え</h5>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={close}>{menuAsc}</MenuItem>
                <MenuItem onClick={close}>{menuDesc}</MenuItem>
                {optionMenu && <> 
                    <Divider sx={{ my: 0.5 }} className="optionmenu" /> 
                    <h5>フィルター</h5>
                    <Divider sx={{ my: 0.5 }} />
                    {optionMenu}                         
                    </>
                }
            </MinimumMenu>
        </div>
    );
};
