import { TaskPriorityOptions } from "../gateways/cms/task";
import { SelectFilter } from "./SelectFilter";

const options = TaskPriorityOptions.map(({ id, name }) => ({ value: String(id), label: name }));

export interface Props {
    value: string[];
    onChange: (value: string[]) => unknown;
}

export function SelectTaskPriorityFilter({ value, onChange }: Props) {
    return <SelectFilter options={options} value={value} onChange={onChange} />;
}
