import assert from "assert";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DeliveryScheduleListTemplate } from "../../components/DeliveryScheduleListTemplate";
import { DeliveryRuleFormRequest, DeliverySchedule } from "../../gateways/cms/deliveryRule";
import {
    useDeleteTerminalDeliveryRuleMutation,
    useGetTerminalDeliveryRuleQuery,
    useUpdateTerminalDeliveryRuleMutation,
} from "../../gateways/cms/deliveryRuleApi";
import { useGetTerminalQuery } from "../../gateways/cms/terminalApi";
import { ScheduleEditModal } from "./ScheduleEditModal";
import MainLayout from "../../layouts/MainLayout";

export function TerminalScheduleEditPage() {
    const { terminalId } = useParams();
    assert(terminalId);

    const terminal = useGetTerminalQuery(terminalId);

    const schedules = useGetTerminalDeliveryRuleQuery(terminalId);
    const [scheduleUpdate] = useUpdateTerminalDeliveryRuleMutation();
    const [scheduleDelete] = useDeleteTerminalDeliveryRuleMutation();
    const [selected, setSelected] = useState<DeliveryRuleFormRequest>();
    const [selectedIds, setSelectedIds] = useState([] as number[]);

    const handleCreate = () => {
        setSelected({
            type: 0,
            poweron: true,
        });
    };

    const handleEdit = (schedule: DeliverySchedule) => {
        setSelected(schedule);
    };

    const save = async (data: DeliveryRuleFormRequest) => {
        await scheduleUpdate({ terminalId: Number(terminalId), ...data });
        closeEditModal();
    };

    const handleUpdate = async (data: DeliveryRuleFormRequest) => {
        await scheduleUpdate({ terminalId: Number(terminalId), ...data });
    };

    const closeEditModal = () => {
        setSelected(undefined);
    };

    const handleDelete = async (scheduleId: number) => {
        await scheduleDelete({ id: Number(terminalId), scheduleIds: [scheduleId] });
        schedules.refetch();
    };

    const handleBulkDelete = async (scheduleIds: number[]) => {
        setSelectedIds([]);
        await scheduleDelete({ id: Number(terminalId), scheduleIds });
        schedules.refetch();
    };

    return (
        <>
            <MainLayout title="ターミナルスケジュール">
                {schedules.data && (
                    <DeliveryScheduleListTemplate
                        title={terminal.data?.name}
                        schedules={schedules.data}
                        selectedIds={selectedIds}
                        onCreate={handleCreate}
                        onEdit={handleEdit}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        onBulkDelete={handleBulkDelete}
                        onSelect={setSelectedIds}
                        scheduleTitle="ターミナルスケジュール"
                    />
                )}
            </MainLayout>

            {selected && <ScheduleEditModal data={selected} onSubmit={save} onDismiss={closeEditModal} />}
        </>
    );
}
