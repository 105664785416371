import produce from "immer";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SelectTaskCategoryFilter } from "../../components/SelectTaskCategoryFilter";
import { SelectTaskPriorityFilter } from "../../components/SelectTaskPriorityFilter";
import { SelectTaskStatusFilter } from "../../components/SelectTaskStatusFilter";
import { SelectUserFilter } from "../../components/SelectUserFilter";
// import { TaskList } from "../../components/TaskList";
import { TaskListItem } from "../../components/TaskListItem";
import { Task } from "../../gateways/cms/task";
import { useDeleteTasksMutation, useGetTasksQuery } from "../../gateways/cms/taskApi";
import { confirmWithStyle } from "../../components/MyConfirm";
import MainLayout from "../../layouts/MainLayout";

import { styled } from "@mui/system";
import Color from "../../styles/colors.js";
import VarConst from "../../styles/variables.js";
import ButtonMUI from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import iconEllipsis from "../../assets/icons/icon_ellipsis.svg";
import iconReduce from "../../assets/icons/icon_reduce.svg";
import iconCheckboxOff from "../../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../../assets/icons/icon_checkbox_on.svg";
import { SortMenu } from "../../components/SortMenu";
import { PaginationLinks } from "../../components/PaginationLinks";

// style
const varCss = {
    articleListStaticItemsW: "260px + 24px + 3em + 6em + 12em + 8em + 8em + 3em + 24px",
};
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;
const PageMain = styled("section")`
    display: flex;
    flex-direction: column;
    padding: 0 24px 0;
    overflow: hidden;

    & > main {
        overflow: auto;
    }
`;
const BarList = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;

    [class$="MuiStack-root"]{
        align-items: center;

        & > :not(style) + :not(style) {
            margin-left: 16px;
        }
    }
`;
const WrapperSticky = styled("section")`
    position: relative;
    overflow: auto;
    margin-bottom: 24px;
`;
const TableCommon = styled("table")`
    table-layout: fixed;
    border-spacing: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    border-bottom: ${VarConst.border1};

    font-size: 13px;

    thead {
        position: sticky;
        top: 0;
        z-index: 10;
    }

    tr > th,
    tr > td {
        border-top: ${VarConst.border1};
        border-right: ${VarConst.border1};
        line-height: 1.1;

        &.checkbox {
            width: 3em;
            min-width: 3em;
            max-width: 3em;
            position: relative;
            border-left: ${VarConst.border1};

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate( -50%, -50%);
                
                & + span::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
            }
        }
        &.priority {
            width: 6em;
            min-width: 6em;
            max-width: 6em;
        }
        &.category {
            width: 12em;
            min-width: 12em;
            max-width: 12em;
        }
        &.title {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.44);
        }
        &.organization {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.28);
        }
        &.status {
            width: 8em;
            min-width: 8em;
            max-width: 8em;
        }
        &.usergroup {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.28);
        }
        &.limitdate {
            width: 8em;
            min-width: 8em;
            max-width: 8em;
        }
        &.function {
            width: 3em;
            min-width: 3em;
            max-width: 3em;
        }
    }
    tr > th {
        padding: 4px 0 4px 8px;
        background-color: ${Color.bgHead};
        text-align: left;
        font-weight: normal;
        border-bottom: ${VarConst.border1};

        &.function {
            text-align: center;
            padding: 0;
        }
    }
    tr > td {
        height: 56px;
        padding: 4px 8px;

        ul {
            /* display: flex; */
            li {
                display: inline;
            }
            li + li::before {
                content: ", ";
            }
        }

        &.function {
            padding-top: 12px;
            padding-bottom: 12px;

            button {
                // IconButtonに hrefがないので Buttonを使用
                height: 24px;
                width: 24px;
                min-width: 24px;
                border: ${VarConst.border1};
                border-width: 2px;
                border-radius: 2px;
                background-image: url(${iconEllipsis});
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #fff;
            }
        }
    }

    // 状態表示
    tr {
        .status div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 2em;
            margin: 0 auto;
            color: #fff;
            line-height: 1;

            b {
                writing-mode: vertical-lr;
                font-weight: normal;
            }
        }
    }
`;

const Button = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
    &.MuiButton-contained {
        width: 116px;
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
`;
const FunctionHeader = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
        flex: 1;
        font-weight: normal;
    }

    &.reduce button {
        background-image: url(${iconReduce});
    }
`;

export function TaskListPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const tasks = useGetTasksQuery({
        per: Number(searchParams.get("per") || 15),
        page: Number(searchParams.get("page") || 1),
        sort_key: searchParams.get("sort_key") || undefined,
        sort_direction: searchParams.get("sort_direction") || undefined,
        priority: searchParams.getAll("priority").join(",") || undefined,
        category: searchParams.getAll("category").join(",") || undefined,
        status: searchParams.getAll("status").join(",") || undefined,
        userid: searchParams.getAll("user").join(",") || undefined,
    });
    const [taskDelete] = useDeleteTasksMutation();
    const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([]);

    const handleSelect = (task: Task, selected: boolean) => {
        setSelectedTaskIds(
            produce(selectedTaskIds, (draft) => {
                if (selected) {
                    draft.push(task.id);
                } else {
                    draft.splice(
                        draft.findIndex((id) => task.id === id),
                        1
                    );
                }
            })
        );
    };

    const handleDelete = async (task: Task) => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "タスクを削除します。よろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            taskDelete({ taskId: [task.id] });
        }
    };

    const handleBulkDelete = () => {
        taskDelete({ taskId: selectedTaskIds });
    };

    const handleSortBy = (key: string, direction: string) => () => {
        searchParams.set("sort_key", key);
        searchParams.set("sort_direction", direction);
        setSearchParams(searchParams);
    };

    const handleFilterBy = (key: string) => (values: string[]) => {
        searchParams.delete(key);
        values.forEach((value) => {
            searchParams.append(key, value);
        });
        setSearchParams(searchParams);
    };

    const handleSelectAll = (selecting: boolean) => {
        if (selecting) {
            setSelectedTaskIds(tasks.data?.data.map((t) => t.id) || []);
        } else {
            setSelectedTaskIds([]);
        }
    };

    return (
        <MainLayout title="タスク管理">
            <PageTitle>
                <h1>タスク管理</h1>
            </PageTitle>

            <PageMain>
                <BarList>
                    <Stack spacing={1} direction="row">
                        <Button type="button" onClick={handleBulkDelete}>
                            {/* まとめて */}削除
                        </Button>
                    </Stack>

                    <Stack spacing={1} direction="row">
                        <span> 選択数: {selectedTaskIds.length} </span>
                        <span> 登録件数: {tasks.data?.total} </span>
                        <span>
                            {tasks.data && <PaginationLinks meta={tasks.data} />}
                        </span>
                    </Stack>
                </BarList>

                {/* TaskList にソート開閉を渡す手順がすぐにわからないため一旦コメントアウト
                <TaskList>
                    {tasks.data?.data?.map((task) => (
                        <TaskListItem
                            key={task.id}
                            task={task}
                            selected={selectedTaskIds.includes(task.id)}
                            onSelect={handleSelect}
                            onDelete={handleDelete}
                        />
                    ))}
                </TaskList>
                */}

                <WrapperSticky>
                    <TableCommon>
                        <thead>
                            <tr>
                                <th className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedTaskIds.length > 0}
                                        onChange={(e) => handleSelectAll(e.target.checked)}
                                    /><span></span>
                                </th>

                                <th className="priority">
                                    <FunctionHeader className={`${ searchParams.getAll("priority").length > 0 && "reduce"}`}>
                                        <b>優先度</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("priority", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("priority", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                            optionMenu={
                                                <SelectTaskPriorityFilter
                                                    value={searchParams.getAll("priority")}
                                                    onChange={handleFilterBy("priority")}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="category">
                                    <FunctionHeader className={`${ searchParams.getAll("category").length > 0 && "reduce"}`}>
                                        <b>カテゴリ</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("category", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("category", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                            optionMenu={
                                                <SelectTaskCategoryFilter
                                                    value={searchParams.getAll("category")}
                                                    onChange={handleFilterBy("category")}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="title">
                                    <FunctionHeader>
                                        <b>タイトル</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("title", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("title", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="organizationId">
                                    <FunctionHeader>
                                        <b>テナント</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("organizationId", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("organizationId", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="status">
                                    <FunctionHeader className={`${ searchParams.getAll("status").length > 0 && "reduce"}`}>
                                        <b>状態</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("status", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("status", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                            optionMenu={
                                                <SelectTaskStatusFilter
                                                    value={searchParams.getAll("status")}
                                                    onChange={handleFilterBy("status")}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="assignedUser">
                                    <FunctionHeader className={`${ searchParams.getAll("user").length > 0 && "reduce"}`}>
                                        <b>担当者</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("assignedUser", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("assignedUser", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                            optionMenu={
                                                <SelectUserFilter
                                                    value={searchParams.getAll("user")}
                                                    onChange={handleFilterBy("user")}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="createdBy">
                                    <FunctionHeader className={`${ searchParams.getAll("user").length > 0 && "reduce"}`}>
                                        <b>作成者</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("createdBy", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("createdBy", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                            optionMenu={
                                                <SelectUserFilter
                                                    value={searchParams.getAll("user")}
                                                    onChange={handleFilterBy("user")}
                                                />
                                            }
                                        />
                                    </FunctionHeader>
                                </th>
                                <th className="limitdate">
                                    <FunctionHeader>
                                        <b>タスク期限</b>
                                        <SortMenu
                                            menuAsc={
                                                <button type="button" onClick={handleSortBy("limitdate", "asc")}>
                                                    昇順 ( A→Z )
                                                </button>
                                            }
                                            menuDesc={
                                                <button type="button" onClick={handleSortBy("limitdate", "desc")}>
                                                    降順 ( Z→A )
                                                </button>
                                            }
                                        />
                                    </FunctionHeader>
                                </th>

                                <th className="function">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.data?.data?.map((task) => (
                                <TaskListItem
                                    key={task.id}
                                    task={task}
                                    selected={selectedTaskIds.includes(task.id)}
                                    onSelect={handleSelect}
                                    onDelete={handleDelete}
                                />
                            ))}
                        </tbody>
                    </TableCommon>
                </WrapperSticky>
            </PageMain>
        </MainLayout>
    );
}
