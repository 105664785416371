import { SchemaDefinitionItemFormRequest, radioSignage } from "../gateways/cms/schema";

import { styled } from "@mui/system";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";
import iconRadioOff from "../assets/icons/icon_radiobutton_off.svg";
import iconRadioOn from "../assets/icons/icon_radiobutton_on.svg";

// style
const SchemaItemInput = styled("div")`
    h3 {
        font-weight: normal;
        margin: 0 0 8px;
        font-size: 14px;
    }

    .type_signage {
        display: flex;
        margin-bottom: 4px;
        label {
            align-items: center;
            margin: 4px 40px 0 0;
            position: relative;
            &:last-of-type {
                margin-left: auto;
                padding-right: 24px;
                color: #770000;
            }
        }
    }
    .type_checkbox,
    .type_radio {
        display: flex;

        label {
            align-items: center;
            margin: 4px 16px 0 0;
            position: relative;
            cursor: pointer;
        }
    }
    .type_file {
        display: grid;
        grid-template-columns: 140px 1fr;
        grid-template-rows: auto auto 1fr;

        h3,
        label {
            padding-left: 24px;
        }
        h3 {
            grid-column: 2/3;
            grid-row: 1/2;
        }
        label {
            grid-column: 2/3;
            grid-row: 2/3;
            display: flex;
            width: 384px;

            input {
                flex: 1;
                line-height: 28px;
                padding: 0 8px;

                &::file-selector-button {
                    display: none;
                }
            }
            b {
                display: inline-block;
                width: 116px;
                height: 32px;
                border: 2px solid;
                border-radius: 2px;
                background-color: #fff;
                border: 2px solid #c9cbd3;
                font-weight: normal;
                letter-spacing: 0;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                margin-left: -1px;
                cursor: pointer;
            }
        }
        div {
            grid-column: 1/2;
            grid-row: 1/4;
            position: relative;
            width: 140px;
            height: 140px;

            &::before {
                position: absolute;
                z-index: 0;
                display: block;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                font-weight: bold;
                text-align: center;
                line-height: 140px;
                background-color: #daddde;
                color: #fff;
            }

            img {
                position: relative;
                z-index: 1;
                width: 140px;
                height: 140px;
            }
        }
    }
    .notes_info {
        font-size: 14px;
        margin: 16px 0 0;
    }

    input[type="file"] {
        height: 100%;
        width: 100%;
        border-radius: 2px;
    }
    input[type="text"],
    select {
        border-radius: 2px;
        min-width: 30em;
    }
    textarea {
        width: 100%;
        height: 30px;
    }
    input[type="checkbox"],
    input[type="radio"] {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        transform: translate( 0, -50%);

        & + span{
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                pointer-events: none;
                background-color: transparent;
                background-attachment: scroll;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    input[type="checkbox"] {
        & + span::before {
            background-image: url(${iconCheckboxOff});
        }
        &:checked + span::before {
            background-image: url(${iconCheckboxOn});
        }
    }
    input[type="radio"] {
        & + span::before {
            background-image: url(${iconRadioOff});
        }
        &:checked + span::before {
            background-image: url(${iconRadioOn});
        }
    }
`;

export interface Props {
    schemaData: SchemaDefinitionItemFormRequest;
}

/**
 * スキーマ定義の各項目を編集画面でダミー表示するコンポーネント
 */
export function SchemaItemMain({ schemaData }: Props) {
    return (
        <SchemaItemInput>
            <h3>{schemaData.title}</h3>
            {schemaData.content?.type === "signage" && (
                <div className="type_signage">
                    {radioSignage.map((radio) => (
                        <label>
                            <input type="radio" disabled value={radio.value} />
                            <span>
                                {radio.label}
                            </span>
                        </label>
                    ))}
                </div>
            )}
            {schemaData.content?.type === "file" && (
                <div className="type_file">
                    <label>
                        <input type="file" disabled />
                        <b>ファイルを選択</b>
                    </label>
                    <div>
                        <img src="" alt="" />
                    </div>
                </div>
            )}
            {schemaData.content?.type === "text" && (
                <div className="type_text">
                    <input type="text" disabled />
                </div>
            )}
            {schemaData.content?.type === "textarea" && (
                <div className="type_textarea">
                    <textarea disabled></textarea>
                </div>
            )}
            {schemaData.content?.type === "checkbox" && (
                <div className="type_checkbox">
                    {Object.entries(schemaData.content?.item || {}).map(([label, value]) => (
                        <label>
                            <input type="checkbox" disabled value={value} />
                            <span>
                                {label}
                            </span>
                        </label>
                    ))}
                </div>
            )}
            {schemaData.content?.type === "select" && (
                <div className="type_select">
                    <select disabled>
                        {Object.entries(schemaData.content?.item || {}).map(([label, value]) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {schemaData.content?.type === "radio" && (
                <div className="type_radio">
                    {Object.entries(schemaData.content?.item || {}).map(([label, value]) => (
                        <label>
                            <input type="radio" disabled value={value} />
                            <span>
                                {label}
                            </span>
                        </label>
                    ))}
                </div>
            )}
            {schemaData.content?.info && <p className="notes_info">※ {schemaData?.content?.info}</p>}
        </SchemaItemInput>
    );
}
