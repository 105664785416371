import { useCreateSchemaDefinitionMutation } from "../../gateways/cms/schemaApi";
import MainLayout from "../../layouts/MainLayout";
import { SchemaDefinitionMinimumForm } from "../../components/SchemaDefinitionMinimumForm";
import { SchemaDefinitionFormRequest as SchemaDefinitionFormData } from "../../gateways/cms/schema";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/system";

// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;

export function SchemaDefinitionMinimumCreatePage() {
    const navigate = useNavigate();
    const [schemaDefinitionMinimumCreate] = useCreateSchemaDefinitionMutation();

    const handleSubmit = async (data: SchemaDefinitionFormData) => {
        await schemaDefinitionMinimumCreate(data);
        navigate(-1);
    };

    return (
        <MainLayout title="コンテンツスキーマ管理（回数保証スキーマ作成）">
            <PageTitle>
                <h1>コンテンツスキーマ管理（回数保証スキーマ作成）</h1>
            </PageTitle>

            <SchemaDefinitionMinimumForm onSubmit={handleSubmit} />
        </MainLayout>
    );
}
