import { useLocation, useNavigate } from "react-router-dom";

export function useRouterModal() {
    const location = useLocation();
    const navigate = useNavigate();

    const openModal = (path: string) => {
        navigate(path, { state: { backgroundLocation: location } });
    };

    return openModal;
}
