import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value: string;
    onChange: (value: string) => void;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

const days = Array.from(Array(31), (_, i) => i + 1);

export function SelectBillingDate({ value, onChange }: Props) {
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(e.target.value)}>
            {days.map((day) => (
                <option key={day} value={day}>
                    {day}日
                </option>
            ))}
        </SelectWithIcon>
    );
}
