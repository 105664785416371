import { Dialog, Button } from "@material-ui/core";
import assert from "assert";
import { FormEventHandler } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeliveryGroupEditForm } from "../../components/DeliveryGroupEditForm";
import { DeliveryGroupForm } from "../../gateways/cms/deliveryGroup";
import { useGetDeliveryGroupQuery, useUpdateDeliveryGroupMutation } from "../../gateways/cms/deliveryGroupApi";

import { styled } from "@mui/system";

export default function DeliveryGroupEditModal() {
    const { id } = useParams();
    assert(id);

    const navigate = useNavigate();
    const deliveryGroup = useGetDeliveryGroupQuery(id);
    const [deliveryGroupUpdate] = useUpdateDeliveryGroupMutation();

    const handleDismiss: FormEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleSubmit = async (data: DeliveryGroupForm) => {
        await deliveryGroupUpdate(data);
        navigate(-1);
    };

    // style
    const DialogOuter = styled(Dialog)`
        .MuiDialog-paper {
            width: 800px;
            max-width: 100%;
            border-radius: 16px;

            main {
                width: 45%;
                margin: 0 auto;
                padding: 40px 0 16px;
            }
            footer {
                padding: 0 32px;
                background-color: #f4f4f4;

                .supplement{
                    padding-top: 16px;
                    text-align: center;
                }
                .buttons{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 64px;
                }

                button {
                    height: 32px;
                    width: 116px;
                    border-radius: 2px;
                    padding: 4px;

                    &.MuiButton-contained {
                        color: #f4f4f4;
                        background-color: #3a3e5d;
                        border-color: #3a3e5d;
                    }
                    &.MuiButton-outlined {
                        background-color: #fff;
                        border-width: 2px;
                        border-color: #c9cbd3;
                    }

                    & + button {
                        margin-left: 16px;
                    }
                }
            }
        }
    `;

    return (
        <DialogOuter aria-labelledby="label" open onClose={handleDismiss}>
            {deliveryGroup.data && (
                <DeliveryGroupEditForm data={deliveryGroup.data} onSubmit={handleSubmit}>
                    <footer>
                        <section className="buttons">
                            <Button type="button" variant="outlined"  onClick={handleDismiss}>
                                キャンセル
                            </Button>
                            <Button type="submit" variant="contained" disableElevation >保存</Button>
                        </section>
                    </footer>
                </DeliveryGroupEditForm>
            )}
        </DialogOuter>
    );
}
