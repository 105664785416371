import { OrganizationBillingRuleForm } from "../gateways/cms/article";
import { SelectBillingData} from "./SelectBillingRule";
import { BillingData } from "../gateways/cms/billingRule";
//import { useGetBillingDatasQuery } from "../gateways/cms/billingRuleApi";
import { styled } from '@mui/system';
import Stack         from '@mui/material/Stack';
import VarConst from "../styles/variables.js";

// [コンテンツ編集] 請求情報 配信先指定

export interface Props {
    value: OrganizationBillingRuleForm[];
    onChange: (value: OrganizationBillingRuleForm[]) => void;
}

export function OrganizationBillingDatasInput({ value, onChange }: Props) {
    const changeItemBillingDatas = (organizationId: number, name: string, billingName: string, billingRuleId: number, index: number, billingDatas: BillingData[]) => {
        onChange([...value.filter((ob) => ob.organizationId !== organizationId), { organizationId, name, billingName, billingRuleId, index, billingDatas }]);
    };
    //const billingDatas = useGetBillingDatasQuery(organizationId)


    // style
    const ItemBilling = styled( Stack )`
        select{
            min-width: 200px;
            max-width: 500px;

            padding-left: 4px;
            border: ${VarConst.border1};
            border-radius: 2px;
            margin-bottom: 32px;

            & + select{
                min-width: 12em;    
            }
        }

        label {
            min-width:  100px;
        }
    `;

    return (
        <div>
            {value.sort((a, b) => a['organizationId'] - b['organizationId']).map((ob) => (
                <ItemBilling direction="row" key={ob.organizationId}>
                    <label htmlFor="billingRule">{ob.name}</label>
                    <SelectBillingData
                        organizationId={ob.organizationId}
                        billingName={ob.billingName}
                        billingRuleId={ob.billingRuleId}
                        billingDataIndex={ob.index}
                        billingDatas={ob.billingDatas}
                        value={ob.index}
                        defaultOption={<option value="-1">未選択</option>}
                        onChange={(billingDataIndex) => changeItemBillingDatas(ob.organizationId, ob.name, ob.billingName, ob.billingRuleId, Number(billingDataIndex), ob.billingDatas)}
                    />
                </ItemBilling>
            ))}


        </div>
    );
}
