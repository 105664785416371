import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "../../components/Copyright";
import { Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";

const mdTheme = createTheme();
export default function PageNotFound() {
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        height="100vh"
                        wrap="nowrap"
                    >
                        <Grid container item xs={12} justifyContent="center">
                            <Paper
                                sx={{
                                    p: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexGrow: 1,
                                }}
                            >
                                <Typography variant="h1">404</Typography>
                                <Typography variant="h5">
                                    Page Not Found
                                </Typography>
                                <MuiLink component={RouterLink} to="/">
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            p: 1,
                                        }}
                                    >
                                        <HomeIcon fontSize="large" />
                                        ホームへ
                                    </Typography>
                                </MuiLink>
                            </Paper>
                        </Grid>
                        <Copyright sx={{ p: 2 }} />
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
