import { Link } from "react-router-dom";
import { useGetDeliveryGroupsQuery } from "../gateways/cms/deliveryGroupApi";
import { Id } from "../models/Id";

import { styled } from '@mui/system';
import VarConst from "../styles/variables.js";

// [掲載順管理] 端末グループ表示

export interface Props {
    organizationId: Id;
}

const DeliveryGroup = styled('ul')`

    list-style: none outside;
    margin: 0 0 24px;
    padding: 0;
    border: ${VarConst.border1};
    border-bottom-width: 0;

    li{
        height: 48px;
        border-bottom: ${VarConst.border1};

        a{
            display: flex;
            flex: 1;
            align-items: center;
            height: 100%;
            padding-left: 40px;
            color: inherit;
            text-decoration: none;
        }

        h4{
            display: inline;
            font-weight: normal;
            margin-right: 0.8em;
        }
    }
`;

export function DeliveryGroupList({ organizationId }: Props) {
    const deliveryGroups = useGetDeliveryGroupsQuery(organizationId);
    return (
        <DeliveryGroup>
            {deliveryGroups.data?.map((deliveryGroup) => (
                <li className="DeliveryGroupItem" key={deliveryGroup.id}>
                    <Link to={`/delivery-groups/${deliveryGroup.id}`}>
                        <h4>{deliveryGroup.name}</h4>
                        <span>({deliveryGroup.terminals.map((t) => t.name).join(", ")})</span>
                    </Link>
                </li>
            ))}
        </DeliveryGroup>
    );
}
