import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { revoked as tokenRevoked } from "../features/auth/authSlice";

export const unauthenticatedMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action) && action.payload.status === 401) {
            dispatch(tokenRevoked());
        }
        return next(action);
    };
