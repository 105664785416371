import { useGetOrganizationQuery } from "../gateways/cms/organizationApi";

export interface Props {
    organizationId: number;
}

export function OrganizationName({ organizationId }: Props) {
    const organization = useGetOrganizationQuery(organizationId);
    return <h1>{organization.data?.name}</h1>;
}
