import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { TerminalStatusLogEntry } from "./log";

export const logApi = createApi({
    reducerPath: "logApi",
    baseQuery,
    endpoints: (builder) => ({
        getTerminalStatusLog: builder.query<TerminalStatusLogEntry[], Id>({
            query: (organizationId) => `logs/terminal/status/list/${organizationId}`,
        }),
    }),
});

export const { useGetTerminalStatusLogQuery } = logApi;
