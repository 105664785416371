import { MonitorInfoForm } from "../gateways/cms/terminal";

import { styled } from "@mui/system";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg"
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg"

export interface Props {
    data: MonitorInfoForm;
    onChange: (data: MonitorInfoForm) => unknown;
}
    
// style
const LabelTitle= styled("label")`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    input[type="checkbox"] {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        transform: translate( -50%, -50%);

        & + span{
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                pointer-events: none;
                background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
            }
        }
        &:checked + span::before {
            background-image: url(${iconCheckboxOn});
        }
    }
    
`;
const LabelIp = styled("label")`
    display: flex; 
    margin-bottom: 0;
    align-items: center;

    input{
        flex: 1;
        margin-left: 0.5em;
    }
`;

export function MonitorEditForm({ data, onChange }: Props) {
    const setIp = (ip: string) => {
        onChange({ ...data, ip });
    };

    const setEnabled = (enabled: boolean) => {
        onChange({ ...data, enabled });
    };

    return (
        <li>
            <LabelTitle>
                <input type="checkbox" checked={data.enabled} onChange={(e) => setEnabled(e.target.checked)} />
                <span>
                    {data.label}
                </span>
            </LabelTitle>
            <LabelIp>
                IP
                <input type="text" pattern="([\d\.]*|RS232C)" value={data.ip} required={data.enabled} onChange={(e) => setIp(e.target.value)} />
            </LabelIp>
        </li>
    );
}
