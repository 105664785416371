import { Dialog } from "@material-ui/core";
import { FormEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InvitationResendForm } from "../../components/InvitationResendForm";
import { InvitationForm as InvitationFormData } from "../../gateways/cms/invitation";
import { useInviteUserMutation } from "../../gateways/cms/invitationApi";
import { RootState } from "../../store";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";

export default function InvitationResendModal() {
    const navigate = useNavigate();
    const [invitationCreate] = useInviteUserMutation();
    const users = useSelector((state: RootState) => state.invitation.users);

    const handleDismiss = (e: FormEvent) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleSubmit = async (data: InvitationFormData) => {
        await invitationCreate(data);
        navigate(-1);
    };

    // style
    const DialogOuter = styled(Dialog)`
        .MuiDialog-paper {
            width: 800px;
            max-width: 100%;
            border-radius: 16px;

            header {
                text-align: center;
                padding: 40px 32px 0;

                h1 {
                    font-size: 24px;
                    font-weight: normal;
                    margin: 0;
                    line-height: 1;
                }
                .message {
                    margin: 40px 0 0;
                }
            }
            main {
                display: flex;
                flex-wrap: wrap;
                width: 65%;
                margin: 0 auto;
                padding: 24px 0 calc(40px + 64px);

                label {
                    display: block;
                }
            }
            footer {
                position: absolute;
                padding: 0 32px;
                background-color: #f4f4f4;
                bottom: 0;
                left: 0;
                width: 100%;

                .supplement{
                    padding-top: 16px;
                    text-align: center;
                }
                .buttons{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 64px;
                }

                button {
                    height: 32px;
                    width: 116px;
                    border-radius: 2px;
                    padding: 4px;

                    &.MuiButton-contained {
                        color: #f4f4f4;
                        background-color: #3a3e5d;
                        border-color: #3a3e5d;
                    }
                    &.MuiButton-outlined {
                        background-color: #fff;
                        border-width: 2px;
                        border-color: #c9cbd3;
                    }

                    & + button {
                        margin-left: 16px;
                    }
                }
            }
        }
    `;
    const Button = styled(ButtonMUI)`
        height: 32px;
        border-radius: 2px;
    `;

    // DEBUG 
    // console.log(users);

    return (
        <DialogOuter aria-labelledby="label" open onClose={handleDismiss}>
            <header>
                <h1>ユーザー再招待</h1>
                <p>以下のユーザーに再招待メールを送信しますか？ <br />（承認済みのユーザーには送信できません）</p>
            </header>

            <main>
                <InvitationResendForm users={users} onSubmit={handleSubmit}>
                    <footer>
                        <section className="buttons">
                            <Button variant="outlined" type="button" onClick={handleDismiss}>キャンセル</Button>
                            <Button variant="contained" type="submit" disableElevation >送信</Button>
                        </section>
                    </footer>
                </InvitationResendForm>
            </main>

        </DialogOuter>
    );
}
