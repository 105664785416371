import { useRef, useState, useEffect } from "react";
import { Control, useFieldArray, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Article, ArticleSchemaItem } from "../gateways/cms/article";
import { ArticleSchemaItemForm } from "./ArticleSchemaItemForm";
import { styled } from "@mui/system";

interface Props {
    control: Control<Article>;
    errors?: any[];
    register: UseFormRegister<Article>;
    setValue: UseFormSetValue<Article>;
}

// style
const SectionTotal = styled("h2")`
    margin: 0 0 24px;
    padding: 10px 10px 10px 40px;
    height: 40px;
    font-weight: normal;
    font-size: 20px;
    background-color: #f4f4f4;
    line-height: 1;
    border-bottom: 2px solid #000;
    .info {
            font-size: 15px;
            padding: 10px 10px 10px 80px;
    }
`; 
const MinimumInfo = styled("div")`
    padding-left: 24px;
    .info {
        font-size: 14px;
        margin: 0 0 24px; 
    }
`;

export function ArticleSchemaForm({ control, errors, register, setValue }: Props) {
    const durations = useRef <number[]>([0,0,0,0,0,0,0,0,0,0]);
    const total = useRef <number>(0);
    const [totalTime, setTotalTime] = useState(0);

    //const minimumInfo : string = '貼付可能なファイルの種類\n■ 動画ファイル 種類：(*.mp4 *.mv4) サイズ:100MBまで\n■ 画像ファイル 種類：(*.jpeg *.jpg *.png) サイズ：4096px*4096px まで';
    const minimumInfo = (limit: number|undefined) => {
        let maxSize = 30;
        if(limit) maxSize = limit;
        let info = '貼付可能なファイルの種類\n■ 動画ファイル 種類：(*.mp4 *.mv4) サイズ：';
        info += maxSize;
        info += 'MBまで\n■ 画像ファイル 種類：(*.jpeg *.jpg *.png) サイズ：4096px*4096px まで';
        return info;
    }

    const addDuration = (duration: number, index: number ):number => {
        durations.current = durations.current.map((x, i) => i === index ? duration : x);
        total.current = durations.current.reduce((sum, num) => sum + num, 0);
        setTotalTime(total.current);
        return total.current;
    }

    const { fields } = useFieldArray({ control, name: "articleSchema" });
    useEffect(() => {
        let count = 0;
        fields.forEach((field, index) => {
            if(field.content?.value?.duration){
                addDuration(Number(field.content.value.duration), index);
                count++;
            }
        });
        if(count === 0) setTotalTime(0);
    }, [fields])

    const checkSectionTotal = (fields: ArticleSchemaItem[]):boolean => {
        let check = false;
        for(let i = 0; i < fields.length; i++){
            if(fields[i].content?.type === "minimum-schema" || fields[i].content?.type === "signage"){
                check = true;
                break;
            }
        }
        return check;
    }

    return (
        <>
            {fields.map((field, i) => (
                <>
                    {i === 0 && ((checkSectionTotal(fields) && field.content.type === "minimum-schema") && (
                        <SectionTotal >コンテンツ<div className="info" style={{display: 'inline-block'}}> 総掲載時間： {Math.ceil(totalTime)} 秒／最大掲載時間：{field.content.maxTime || 0} 秒</div></SectionTotal>
                    ))}
                    {i === 0 && ((checkSectionTotal(fields) && field.content.type !== "minimum-schema") && (
                        <SectionTotal >コンテンツ<div className="info" style={{display: 'inline-block'}}> 総掲載時間： {Math.ceil(totalTime)} 秒</div></SectionTotal>
                    ))}
                    {i === 0 && (field.content.type === "minimum-schema" && (
                        <MinimumInfo><div className="info" style={{whiteSpace: 'pre-line'}}>{minimumInfo(field.content.limit)}</div></MinimumInfo>
                    ))}
                    <ArticleSchemaItemForm
                        key={field.id}
                        name={`articleSchema.${i}`}
                        value={field}
                        register={register}
                        setValue={setValue}
                        errors={(errors ?? [])[i]}
                        addDuration={addDuration}
                        index={i}
                    />
                </>
            ))}
        </>
    );
}
