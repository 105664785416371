import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const organizationBreadcrumbSlice = createSlice({
    name: "organizationBreadcrumb",
    initialState: {
        id: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
    },
    reducers: {
        setFirstOrganization: (state, action: PayloadAction<string>) => {
            //console.log("payload1="+action.payload);
            state.id = action.payload || getStorage("bc_first") || "";
            state.first = action.payload || getStorage("bc_first") || "";
            state.second = "";
            state.third = "";
            state.fourth = "";
            //console.log(state.id);
        },
        setSecondOrganization: (state, action: PayloadAction<string>) => {
            //console.log("payload2="+action.payload);
            state.id = action.payload || getStorage("bc_second") || state.first;
            state.second = action.payload || getStorage("bc_second");
            state.third = "";
            state.fourth = "";
            //console.log(state.id);
        },
        setThirdOrganization: (state, action: PayloadAction<string>) => {
            //console.log("payload3="+action.payload);
            state.id = action.payload || getStorage("bc_third") || state.second;
            state.third = action.payload || getStorage("bc_third");
            state.fourth = "";
            //console.log(state.id);
        },
        setFourthOrganization: (state, action: PayloadAction<string>) => {
            //console.log("payload4="+action.payload);
            state.id = action.payload || getStorage("bc_fourth") || state.third;
            state.fourth = action.payload || getStorage("bc_fourth");
            //console.log(state.id);
        },
        revoked: (state) => {
            state.id = "";
            state.first = "";
            state.second = "";
            state.third = "";
            state.fourth = "";
        },
    },
});

const getStorage = (key: string) => {
    if(localStorage.getItem(key)){
        return JSON.parse(localStorage.getItem(key) || "");
    }else{
        return null;
    }
}

export const { revoked } = organizationBreadcrumbSlice.actions;

export const { setFirstOrganization, setSecondOrganization, setThirdOrganization, setFourthOrganization } =
    organizationBreadcrumbSlice.actions;

export const selectOrganizationBreadcrumb = (state: RootState) => state[organizationBreadcrumbSlice.name];

//export const selectOrganizationId = (state: RootState) => state[organizationBreadcrumbSlice.name].id;
export const selectOrganizationId = (state: RootState) => state[organizationBreadcrumbSlice.name].id || getStorage("organization_id");