import { useGetBillingRulesQuery } from "../gateways/cms/billingRuleApi";
import { Id } from "../models/Id";
import { BillingData } from "../gateways/cms/billingRule";

import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

// [コンテンツ編集] 請求情報 配信先指定

export interface Props {
    organizationId: Id;
    billingName?: string;
    billingRuleId?: Id;
    billingDataIndex?: Id;
    billingDatas?: BillingData[];
    value?: Id;
    defaultOption?: JSX.Element;
    onChange: (billingDataIndex: Id) => void;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

/**
 * 請求ルールを選択する
 */
export function SelectBillingRule({ organizationId, value, defaultOption, onChange }: Props) {
    const billingRules = useGetBillingRulesQuery(organizationId);

    if (!billingRules.data) {
        return <select></select>;
    }

    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(e.target.value)}>
            {defaultOption}
            {billingRules.data.map((billingRule) => (
                <option key={billingRule.id} value={billingRule.id}>
                    {billingRule.name}
                </option>
            ))}
        </SelectWithIcon>
    );
}

/**
 * 請求書を選択する
 */
export function SelectBillingData({ organizationId, billingDatas, value, defaultOption, onChange }: Props) {
    if (!billingDatas) {
        return <select></select>;
    }
    const organizationBilling = new Array(0).fill(billingDatas);

    billingDatas.forEach((billing) => {
        if(organizationId === billing.organizationId){
            organizationBilling.push(billing);
        }
    });
    if (!organizationBilling) {
        return <select></select>;
    }
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(e.target.value)}>
            {defaultOption}
            {organizationBilling.map((billingData) => (
                <option key={billingData.index} value={billingData.index}>
                    {billingData.billingName}
                </option>
            ))}
        </SelectWithIcon>
    );
}