import assert from "assert";
import { useNavigate } from "react-router-dom";
import { TaskCreateForm } from "../../components/TaskCreateForm";
import { TaskCreateFormRequest, TaskPriorityOptions, TaskStatusOptions } from "../../gateways/cms/task";
import { useCreateTaskMutation } from "../../gateways/cms/taskApi";
import { useGetMyUserQuery } from "../../gateways/cms/userApi";
import MainLayout from "../../layouts/MainLayout";

import { styled } from "@mui/system";

interface Props {
    organizationId: string;
}
export function OrganizationTaskCreatePage({ organizationId }: Props) {
    assert(organizationId);

    const navigate = useNavigate();
    const user = useGetMyUserQuery();
    const [taskCreate] = useCreateTaskMutation();
    if (!user.data) {
        return <div></div>;
    }

    const categoryOptions = [
        { id: 30, name: "保守メンテ", target: JSON.stringify({ organizationId }) },
        { id: 90, name: "その他", target: JSON.stringify({ organizationId }) },
    ];

    const data: TaskCreateFormRequest = {
        title: "",
        category: categoryOptions[0].id,
        status: TaskStatusOptions[0].id,
        priority: TaskPriorityOptions[0].id,
        limitdate: "",
        organizationId: Number(organizationId),
        target: categoryOptions[0].target,
        createdBy: user.data.id,
    };

    const handleSubmit = (data: TaskCreateFormRequest) => {
        taskCreate(data);
    };

    const handleDismiss = () => {
        navigate(-1);
    };

    // style
    const PageTitle = styled("section")`
        display: flex;
        justify-content: space-between;
        padding: 24px;
        // margin-bottom: 16px;

        h1 {
            font-size: 24px;
            font-weight: normal;
            margin: 0;

            span {
                margin-right: 1em;
            }
        }

        & + form {
            flex: 1;
            display: flex;
            flex-direction: column;

            & > section {
                flex: 1;
                overflow: auto;
            }
        }
    `;

    return (
        <MainLayout title="新規タスク">
            <PageTitle>
                <h1>新規タスク</h1>
            </PageTitle>

            <TaskCreateForm
                data={data}
                categoryOptions={categoryOptions}
                onSubmit={handleSubmit}
                onDismiss={handleDismiss}
            />
        </MainLayout>
    );
}
