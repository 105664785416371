import { Button } from "@mui/material";
import { useState } from "react";
import { SelectOrganization } from "./SelectOrganization";

import { styled } from "@mui/system";

interface Props {
    exclude?: number[];
    onSubmit: (organizationId: number) => unknown;
}

// style
const ButtonAdd = styled(Button)`
    margin-left: 16px;
`;

export function OrganizationAddForm({ exclude, onSubmit }: Props) {
    const [organizationId, setOrganizationId] = useState("");

    const handleSubmit = () => {
        if (!organizationId) {
            return;
        }
        onSubmit(Number(organizationId));
        setOrganizationId("");
    };

    return (
        <div>
            <SelectOrganization value={organizationId} exclude={exclude} onChange={setOrganizationId} />
            <ButtonAdd type="button" variant="contained" disableElevation onClick={handleSubmit}>
                テナントを追加
            </ButtonAdd>
        </div>
    );
}
