import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { Id } from "./id";
import { Terminal, TerminalForm, TerminalQuery } from "./terminal";

export const terminalApi = createApi({
    reducerPath: "terminalApi",
    baseQuery,
    tagTypes: ['Terminal'],
    endpoints: (builder) => ({

        getTerminal: builder.query<Terminal, Id>({
            query: (id) => `terminal/${id}`,
            // TODO: FIXME ダミーデータが配列になってる。Terminalを直接返してもらう
            // API対応済みなのでコメントアウト
            //transformResponse: (data: Terminal[]) => data[0],
            providesTags: ['Terminal'],
        }),
        getTerminals: builder.query<Terminal[], TerminalQuery>({
            query: (params) => ({
                url: `terminal/list`,
                params,
            }),
            providesTags: ['Terminal'],
        }),
        createTerminal: builder.mutation<void, TerminalForm>({
            query: (body) => ({
                url: "terminal",
                method: "post",
                body,
            }),
            invalidatesTags: ['Terminal'],
        }),
        updateTerminal: builder.mutation<void, TerminalForm>({
            query: ({ id, ...body }) => ({
                url: `terminal/${id}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Terminal'],
        }),
        deleteTerminal: builder.mutation<void, Id>({
            query: (id) => ({ url: `terminal/${id}`, method: "delete" }),
            invalidatesTags: ['Terminal'],
        }),
    }),
});

export const {
    useGetTerminalQuery,
    useGetTerminalsQuery,
    useCreateTerminalMutation,
    useUpdateTerminalMutation,
    useDeleteTerminalMutation,
} = terminalApi;
