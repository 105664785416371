import { baseUrl } from "./config/api";

interface Config {
    copyright: { url: string; text: string };
    readonly baseEndpoint: string;
}

const config: Config = {
    copyright: {
        url: "https://ybs-inc.biz/",
        text: "YBS",
    },
    // baseEndpoint: "http://api.signage-cms.local/api/",
    // 2022-03-07 ご連絡
    baseEndpoint: baseUrl,
};

export default config;
