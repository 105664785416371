// import { Button, Dialog, DialogActions } from "@mui/material";
import { Dialog } from "@mui/material";
import { FormEvent, useState } from "react";
import { OnceScheduleEditForm } from "../../components/OnceScheduleEditForm";
import { RepeatScheduleEditForm } from "../../components/RepeatScheduleEditForm";
import { DeliveryRuleFormRequest, ScheduleType } from "../../gateways/cms/deliveryRule";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";
import iconRadioOff from "../../assets/icons/icon_radiobutton_off.svg"
import iconRadioOn from "../../assets/icons/icon_radiobutton_on.svg"

// style
const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        header {
            text-align: center;
            padding: 40px 32px 0;

            h1 {
                font-size: 24px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
            .message {
                margin: 40px 0 0;
            }
        }
        main {
            display: flex;
            flex-wrap: wrap;
            width: 75%;
            margin: 0 auto;
            padding: 24px 0 calc(40px + 64px);

            form{
                width: 100%;
                
                & > section {

                    & + section{
                        margin-top: 48px;
                    }

                    & > label {
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;

                        input[type="radio"]{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            z-index: 1;
                            width: 24px;
                            height: 24px;
                            margin: 0;
                            opacity: 0;
                            cursor: pointer;
                            transform: translate( -50%, -50%);

                            & + span{
                                display: flex;
                                align-items: center;

                                &::before {
                                    content: "";
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 0.5em;
                                    pointer-events: none;
                                    background: transparent url(${iconRadioOff}) scroll no-repeat center center;
                                }
                            }
                            &:checked + span::before {
                                background-image: url(${iconRadioOn});
                            }

                            & + b {
                                margin-left: 0.65em;
                                font-weight: normal;
                            }
                        }
                    }
                    ul{
                        li{
                            margin-bottom: 16px;

                            input[type="text"],
                            input[type="date"],
                            input[type="time"],
                            select{
                                height: 32px;
                                min-width: 8em;
                            }

                            &:nth-of-type(2){
                                float: left;
                                margin-right: 32px;

                                span{
                                    padding: 0 0.25em;    
                                }
                            }
                        }
                    }
                }
            }

            form > section > ul{
                padding-left: 32px;
            }
        }
        footer {
            position: absolute;
            padding: 0 32px;
            background-color: #f4f4f4;
            bottom: 0;
            left: 0;
            width: 100%;

            .supplement{
                padding-top: 16px;
                text-align: center;
            }
            .buttons{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
            }

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;

export interface Props {
    data: DeliveryRuleFormRequest;
    onSubmit: (data: DeliveryRuleFormRequest) => unknown;
    onDismiss: () => unknown;
}

export function ScheduleEditModal({ data, onSubmit, onDismiss }: Props) {
    const [newData, setNewData] = useState(data);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(newData);
    };

    const changeType = (type: ScheduleType) => {
        setNewData({ ...data, type });
    };

    return (
        <DialogOuter open>
            <header>
                <h1>スケジュール設定</h1>
            </header>

            <main>
                <form onSubmit={handleSubmit}>
                    <section>
                        <label>
                            <input type="radio" checked={newData.type === 0} onChange={(e) => changeType(0)} />
                            <span>
                                繰り返しスケジュール
                            </span>
                        </label>
                        <RepeatScheduleEditForm data={newData} disabled={newData.type !== 0} onChange={setNewData} />
                    </section>

                    <section>
                        <label>
                            <input type="radio" checked={newData.type === 1} onChange={(e) => changeType(1)} />
                            <span>
                                日付指定スケジュール
                            </span>
                        </label>
                        <OnceScheduleEditForm data={newData} disabled={newData.type !== 1} onChange={setNewData} />
                    </section>

                    <footer>
                        <section className="buttons">
                            <Button type="button" variant="outlined" onClick={onDismiss}>
                                キャンセル
                            </Button>
                            <Button type="submit" variant="contained" disableElevation >保存</Button>
                        </section>
                    </footer>
                </form>
            </main>
        </DialogOuter>
    );
}
