import { Dialog, Button } from "@material-ui/core";
import assert from "assert";
import { FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TerminalEditForm } from "../../components/TerminalEditForm";
import { TerminalForm } from "../../gateways/cms/terminal";
import { useGetTerminalQuery } from "../../gateways/cms/terminalApi";

import { styled } from "@mui/system";

// style
const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        form{
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        main {
            display: flex;
            flex-wrap: wrap;
            padding: 40px 106px 16px;
            flex: 1;
            overflow: auto;
        }
        footer {
            padding: 0 32px;
            background-color: #f4f4f4;

            .supplement{
                padding-top: 16px;
                text-align: center;
            }
            .buttons{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
            }

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;

export default function TerminalDetailModal() {
    const { organizationId, id } = useParams();
    assert(organizationId);
    assert(id);

    const navigate = useNavigate();
    const terminal = useGetTerminalQuery(id);

    const handleDismiss = (e: FormEvent) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleSubmit = (data: TerminalForm) => {
        navigate(-1);
    };

    return (
        <DialogOuter aria-labelledby="label" open onClose={handleDismiss}>
            {terminal.data && (
                <TerminalEditForm organizationId={organizationId} data={terminal.data} onSubmit={handleSubmit}>
                    <footer>
                        <section className="buttons">
                            <Button type="button" variant="outlined"  onClick={handleDismiss}>
                                閉じる
                            </Button>
                        </section>
                    </footer>
                </TerminalEditForm>
            )}
        </DialogOuter>
    );
}
