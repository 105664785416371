import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { DisplayModel } from "./displayModel";

export const displayModelApi = createApi({
    reducerPath: "displayModelApi",
    baseQuery,
    endpoints: (builder) => ({
        getDisplayModels: builder.query<DisplayModel[], void>({
            query: () => ({ url: "display-model/list" }),
        }),
    }),
});

export const { useGetDisplayModelsQuery } = displayModelApi;
