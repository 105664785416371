import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuAccount from "../materials/MenuAccount";
import { OrganizationBreadcrumb } from "../OrganizationBreadcrumb";

import Color from "../../styles/colors.js";
import VarConst from "../../styles/variables.js";

// [ヘッダ]

const drawerWidth: number = 240;

// // input select
// function SelectItem() {
//     // プルダウン値の定義
//     type Option = {
//         optionId: number;
//         optionText: string;
//         optionValue: string;
//     };
//     type OptionData = {
//         optionsId: string;
//         optionsTitle: string;
//         options: Option[];
//     };

//     // プルダウン値の設定 (外から受取)
//     // JSON.parse() の結果を型アサーションして受け取る
//     // const optionList = JSON.parse(jsonText) as OptionData
//     const optionList: OptionData = {
//         optionsId: "range1",
//         optionsTitle: "指定1",
//         options: [
//             {
//                 optionId: 0,
//                 optionText: "すべて",
//                 optionValue: "all",
//             },
//             {
//                 optionId: 1,
//                 optionText: "YBS",
//                 optionValue: "ybs",
//             },
//             {
//                 optionId: 2,
//                 optionText: "2",
//                 optionValue: "2",
//             },
//             {
//                 optionId: 3,
//                 optionText: "3",
//                 optionValue: "3",
//             },
//         ],
//     };

//     const handleChange = (event: SelectChangeEvent) => {
//         setLange1(event.target.value as string);
//     };
//     const [range1, setLange1] = React.useState("all");

//     return (
//         <Select
//             className="select"
//             id={optionList.optionsId}
//             value={range1}
//             label={optionList.optionsId}
//             onChange={handleChange}
//         >
//             {optionList.options.map((op) => (
//                 <MenuItem value={op.optionValue}> {op.optionText} </MenuItem>
//             ))}
//         </Select>
//     );
// }

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}),`

    &.MuiAppBar-root {
      background-color: ${Color.bgHead};
      color: ${Color.text};
      font-size: 12px;
    }
    // MuiToolbar-gutters MuiToolbar-regular css-14v3fac-MuiToolbar-root
    .MuiToolbar-root {
        min-height: ${VarConst.headerH};
    }
    // h1
    .MuiTypography-root {
        flex: unset;
        margin-right: 36px;
        font-size: 14px;
        font-weight: normal;
    }

    .MuiBox-root:last-child {
        flex: 1;
        text-align: right;

        .MuiButton-root {
            color: inherit;
            background-color: inherit;
        }
    }
`);

export interface NavBarProps {
    title: string;
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
}

export default function NavBar(props: NavBarProps) {
    return (
        <AppBar className="page-header" position="fixed" elevation={0} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                {/* タイトル */}
                <Typography
                    component="h1"
                    noWrap
                >
                    YBS Signage Manager
                    {/*{ props.title }*/}
                </Typography>

                {/* プルダウングループ */}
                <OrganizationBreadcrumb />

                <Box>
                    <MenuAccount />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
