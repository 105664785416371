import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { styled } from "@mui/system";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };
export interface Props {
    children?: React.ReactNode;
    imageUrl: string;
    imageAlt: string;
}

// export default function ImageModal() {
export const ImageModal = ({
    children,
    imageUrl,
    imageAlt,
}: Props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // style
    const ButtonArea = styled( Button )`
        padding: 0;
    `;
    const ImageModal = styled( Modal )`
        /* display: flex;
        justify-content: space-between;
        padding: 24px; */

        .MuiBox-root{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 80vh;
            height: 80vh;
            max-width: 70%;
            max-height: 90%;
            padding: 24px;
            background-color: #fff;
            border-radius: 8px;

            display: table-cell;
            vertical-align: middle;
            text-align: center; 
                    
            img{
                width:auto;
                height:auto;
                max-width:100%;
                max-height:100%;
            }
        }
    `;
    const CloseButton = styled( Button )`
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate( -49%, 150%);

        /* 
        border-width: 2px; */
        width: 10em;
        background-color: #fff !important;
`;

  return (
    <>
      <ButtonArea onClick={handleOpen}>
          {children}
      </ButtonArea>
      <ImageModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
            <img src={imageUrl} alt={imageAlt} />
            {/* 
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
             */}
            <CloseButton
                 variant="outlined"
                 onClick={handleClose}
            >
                 閉じる
            </CloseButton>

        </Box>
      </ImageModal>
    </>
  );
}