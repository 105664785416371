import { ChangeEventHandler } from "react";

import { styled } from '@mui/system';
import Stack         from '@mui/material/Stack';
import ButtonMUI     from '@mui/material/Button';

// [コンテンツ編集] 繰返し 日付設定

export interface Props {
    value: number[];
    onChange: (days: number[]) => void;
}

const dateOptions = Array.from(new Array(31), (_, i) => i + 1); // 1 .. 31

export function SelectDate({ value, onChange }: Props) {
    const handleSelect: ChangeEventHandler<HTMLInputElement> = (e) => {
        const date = Number(e.target.value);
        if (e.target.checked) {
            onChange([...value, date]);
        } else {
            onChange(value.filter((v) => v !== date));
        }
    };
    const selectAll = () => {
        onChange(dateOptions);
    };
    const unselectAll = () => {
        onChange([]);
    };

    // style
    const Button = styled( ButtonMUI )`
        height: 32px;
        border-radius: 2px;
    `;

    return (
        <>
            <div className="selectCalender">
            {dateOptions.map((date) => (
                <label key={date}>
                    <input type="checkbox" value={date} checked={value.includes(date)} onChange={handleSelect} />
                    <b>{date}</b>
                </label>
            ))}
            </div>

            <Stack spacing={1} direction="row">
                <Button type="button" onClick={selectAll}>
                    すべて選択
                </Button>
                <Button type="button" onClick={unselectAll}>
                    選択解除
                </Button>
            </Stack>
        </>
    );
}
