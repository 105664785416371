import assert from "assert";
import { useParams } from "react-router-dom";
import { TaskEditForm } from "../../components/TaskEditForm";
import { TaskUpdateFormRequest } from "../../gateways/cms/task";
import { useGetTaskQuery, useUpdateTaskMutation } from "../../gateways/cms/taskApi";
import { useGetMyUserQuery } from "../../gateways/cms/userApi";
import MainLayout from "../../layouts/MainLayout";

import { styled } from "@mui/system";
import VarConst from "../../styles/variables.js";
import Button from "@material-ui/core/Button";

// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;

        span {
            margin-right: 1em;
        }
    }
`;
const SectionComon = styled("section")`
    h2 {
        margin: 0 0 24px;
        padding: 10px 10px 10px 40px;
        height: 40px;
        font-weight: normal;
        font-size: 20px;
        background-color: #f4f4f4;
        line-height: 1;
    }
    h2 + main {
        margin: 24px 40px 24px;

        h3 {
            font-weight: normal;
            margin: 0 0 8px;

            small {
                margin-left: 1em;
            }
        }
        ul {
            li {
                b {
                    font-weight: normal;
                    margin-right: 0.5em;
                }
            }
        }
    }
    &.task_summary {
        ul {
            display: grid;
            grid-template-columns: 20em 1fr;

            li {
                margin-bottom: 16px;
                padding-right: 16px;

                &:nth-of-type(1) {
                    grid-row: 1/2;
                    grid-column: 1/2;
                }
                &:nth-of-type(2) {
                    grid-row: 2/3;
                    grid-column: 1/2;
                }
                &:nth-of-type(3) {
                    grid-row: 3/4;
                    grid-column: 1/2;
                }
                &:nth-of-type(4) {
                    grid-row: 1/2;
                    grid-column: 2/3;
                }
                &:nth-of-type(5) {
                    grid-row: 2/3;
                    grid-column: 2/3;
                }
                &:nth-of-type(6) {
                    grid-row: 3/4;
                    grid-column: 2/3;
                }
            }
        }
    }
    &.task_log {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        main {
            flex: 1;
            overflow: auto;
            margin-top: 0;
        }

        section {
            margin-bottom: 24px;
        }
        ul {
            padding: 16px 24px;
            background-color: #f4f4f4;

            li {
                margin-bottom: 8px;
                padding-right: 8px;
            }
        }
        .file {
            display: flex;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 88px;
                width: 88px;
                margin-right: 24px;
                padding: 4px;
                border: ${VarConst.border1};
                text-align: center;
                background-color: #fff;

                img {
                    height: auto;
                    width: auto;
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
    }
`;
const ButtonApprove = styled(Button)`
    min-width: 116px;
    height: 32px;
    border-radius: 2px;

    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
`;

export function TaskPage() {
    const { id } = useParams();
    assert(id);

    const user = useGetMyUserQuery();
    const task = useGetTaskQuery(id);
    const [taskUpdate] = useUpdateTaskMutation();

    const handleUpdate = (data: TaskUpdateFormRequest) => {
        taskUpdate(data);
    };

    const taskCreatedAt = task.data?.taskHistory?.at(0)?.createdat;

    return (
        <MainLayout title="タスク管理 詳細">
            <PageTitle>
                <h1>
                    <span>
                        【{task.data?.status}】{task.data?.id}
                    </span>{" "}
                    {task.data?.title}
                </h1>
            </PageTitle>

            <SectionComon className="task_summary">
                <h2>タスク内容</h2>
                <main>
                    <ul>
                        <li>
                            <b>カテゴリ:</b>
                            {task.data?.category}
                        </li>
                        <li>
                            <b>登録日時:</b>
                            {taskCreatedAt}
                        </li>
                        <li>
                            <b>期限:</b>
                            {task.data?.limitdate}
                        </li>
                        <li>
                            <b>優先度:</b>
                            {task.data?.priority}
                        </li>
                        <li>
                            <b>作成者:</b>
                            {task.data?.createdBy?.name}
                        </li>
                        <li>
                            <b>担当者:</b>
                            {task.data?.assignedUser.map((user, i) => user.name).join("、")}
                        </li>
                    </ul>
                    {task.data?.category === "監修依頼" && (
                        <ButtonApprove type="button" variant="outlined" href={`/articles/${task.data?.id}/approve`}>
                            該当のコンテンツを表示
                        </ButtonApprove>
                    )}
                </main>
            </SectionComon>

            <SectionComon className="task_log">
                <h2>履歴</h2>
                <main>
                    {task.data?.taskHistory?.map((item) => (
                        <section key={item.id}>
                            <h3>
                                {item.createdby.name}
                                <small>{item.createdat}</small>
                            </h3>
                            <ul>
                                <li>
                                    <b>状態:</b>
                                    {item.status}
                                </li>
                                <li>
                                    <b>コメント:</b>
                                    {item.comment}
                                </li>
                                <li className="file">
                                    {item.file.map((fileUrl, i) => (
                                        <div key={i}>
                                            <img src={fileUrl} alt="file" width="64px" />
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </section>
                    ))}
                </main>
            </SectionComon>

            {/* Footer */}
            {task.data && user.data && (
                <TaskEditForm
                    data={{ id: task.data.id, status: task.data?.stsnum || 1, comment: "", file: [], createdBy: user.data.id }}
                    onSubmit={handleUpdate}
                />
            )}
        </MainLayout>
    );
}
