import { useSelector } from "react-redux";
import { TerminalSettingsDownloadFormRequest } from "../../gateways/cms/terminal";
import { terminalSettingsDownload } from "../../gateways/cms/terminalSettingsDownload";
import { RootState } from "../../store";

export function useDownloadTerminalSettings() {
    const token = useSelector((state: RootState) => state.auth.token);
    return async (request: TerminalSettingsDownloadFormRequest) => {
        const response = await terminalSettingsDownload(token, request);
        if (response.ok) {
            window.open(URL.createObjectURL(await response.blob()));
        } else {
            alert("error");
        }
    };
}
