import * as React from "react";
// import { useNavigate } from "react-router-dom";
// import { Organization } from "../gateways/cms/organization";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import { styled } from "@mui/material/styles";
// import ButtonMUI from "@mui/material/Button";
import iconEllipsis from "../../assets/icons/icon_ellipsis.svg";

export interface Props {
    menuEdit: React.ReactNode;
    menuDelete: React.ReactNode;
    menuSub1?: React.ReactNode;
    menuSub2?: React.ReactNode;
}

const MinimumMenu = styled((props: MenuProps) => (
    <Menu 
        {...props} 
    />
    ))`
        & .MuiPaper-root {
            border-radius: 0;
            width: 200px;
            font-size: 14px;
            // TODO: refから開始位置を取得して動的に設定したい (うまく行かなかったため固定値を指定)
            max-height: calc(100vh - 258px) !important;

            & .MuiList-root {
                padding: 0;

                .MuiMenuItem-root{
                    width: 100%;
                    padding: 0;

                    & > *{
                        display: flex;
                        width: 100%;
                        padding: 6px 16px;
                        font-size: 1em; //14px;
                        background-color: transparent;
                        border: 0 none transparent;
                        cursor: pointer;
                        text-decoration: none;
                        color: inherit;
                    }

                    &.delete {
                        *{
                            color: #FC6F6C;
                        }
                    }
                }
            }
            & .MuiDivider-root {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    `;

// style
const MenuButton = styled(Button)`
    &.edit{
        height: 24px;
        width: 24px;
        min-width: 24px;
        border: 2px solid #C9CBD3;
        border-radius: 2px;
        background-image: url(${iconEllipsis});
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #fff;
        background-size: 75%;
    }
`;

export const EditMenuMinimum = ({
    menuEdit,
    menuDelete,
    menuSub1,
    menuSub2
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    // const navigate = useNavigate();

    const targetElRef = React.useRef(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const close = () => {
        setAnchorEl(null);
    };

    // TODO: refから開始位置を取得して動的に設定したい (うまく行かなかったため固定値を指定)
    // const clientRect = targetElRef.current?.getBoundingClientRect();

    return (
        <div ref={targetElRef}>
            <MenuButton
                aria-controls={open ? "menu-minimum" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="edit"
            ></MenuButton>
            <MinimumMenu
                id="menu-minimum"
                MenuListProps={{
                    "aria-labelledby": "schedul-article-menu-button",
                }}
                anchorEl={targetElRef.current}
                open={open}
                onClose={close}
                className="menu-body"
            >
                <MenuItem onClick={close}>
                    {menuEdit}
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                { menuSub1 &&
                    <>
                    <MenuItem onClick={close}>
                        {menuSub1}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    </>
                }
                { menuSub2 &&
                    <>
                    <MenuItem onClick={close}>
                        {menuSub2}
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    </>
                }
                <MenuItem onClick={close} className="delete">
                    {menuDelete}
                </MenuItem>
            </MinimumMenu>
        </div>
    );
};