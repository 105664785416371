import { Article } from "../../gateways/cms/article";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// 保存ボタンを無理やりScheduleコンポーネントの外に持ってくるためのスライス
// TODO: 廃止してもっと単純な方法にしたい

export const orderedArticlesSlice = createSlice({
    name: "orderedArticles",
    initialState: {
        orderedArticles: [] as Article[],
    },
    reducers: {
        setOrderedArticles: (state, action: PayloadAction<Article[]>) => {
            state.orderedArticles = action.payload;
        },
    },
});

export const { setOrderedArticles } = orderedArticlesSlice.actions;

export const selectOrderedArticles = (state: RootState) => state[orderedArticlesSlice.name].orderedArticles;
