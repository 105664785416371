import { SimpleOrganization, SimpleUser } from "./organization";

export const TaskStatusOptions = [
    { id: 1, name: "未対応" },
    { id: 2, name: "対応中" },
    { id: 3, name: "対応済" },
];

export const TaskCategoryOptions = [
    { id: 10, name: "監修依頼" },
    { id: 20, name: "障害" },
    { id: 30, name: "保守メンテ" },
    { id: 40, name: "掲載ルール" },
    { id: 90, name: "その他" },
];

export const TaskPriorityOptions = [
    { id: 1, name: "高" },
    { id: 2, name: "中" },
    { id: 3, name: "低" },
];

export interface TaskQuery {
    per?: number;
    page?: number;
    sort_key?: string;
    sort_direction?: string;
    priority?: string;
    category?: string;
    status?: string;
    userid?: string;
}

export interface Task {
    id: number;
    priority: string;
    category: string;
    title: string;
    organization: SimpleOrganization;
    status: string;
    stsnum: number;
    assignedUser: SimpleUser[];
    limitdate: string; // yyyy-MM-dd hh:mm
    target: string; // json
    createdBy: SimpleUser;
    createdat: string; // yyyy-MM-dd hh:mm
    taskHistory?: TaskHistoryItem[]; // 詳細でだけ取得する
}

export interface TaskHistoryItem {
    id: number;
    status: string;
    comment: string;
    file: string[]; // url
    createdby: SimpleUser;
    createdat: string; // yyyy-MM-dd HH:mm
}

export interface TaskCreateFormRequest {
    category: number; // 0:監修依頼/20:障害/30:保守メンテ/40:掲載ルール/90:その他
    title: string;
    priority: number; // 1:高/2:中/3:低
    limitdate: string;
    organizationId: number; // organization id
    target: string;
    status: number; // 1:未対応/2:対応中/3:対応済
    createdBy: number; // user id
}

export interface TaskUpdateFormRequest {
    id: number;
    status: number; // 1:未対応/2:対応中/3:対応済
    file: string[]; // base64
    comment: string;
    createdBy: number; // user id
}

export interface TaskBulkDeleteFormRequest {
    taskId: number[];
}
