import { FormEventHandler, useState } from "react";
import { BillingRuleForm as BillingRuleFormData } from "../gateways/cms/billingRule";
import { SelectBillingDate } from "./SelectBillingDate";
import { SelectBillingMonth, SelectFromBillingMonth } from "./SelectBillingMonth";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";

export interface Props {
    data: BillingRuleFormData;
    onSubmit: (data: BillingRuleFormData) => void;
    onDismiss: () => void;
}

// style
const InputList = styled("ul")`
    display: grid;
    width: 60%;
    margin: 0 auto;
    grid-template-columns: 50% 50% ;
    
    & > li{
        margin-bottom: 24px;
        &:nth-of-type(1){ grid-row: 1/2; grid-column: 1/3; }
        &:nth-of-type(2){ grid-row: 2/3; grid-column: 1/3; }
        &:nth-of-type(3){ grid-row: 3/4; grid-column: 1/3; }
        &:nth-of-type(4){ grid-row: 4/5; grid-column: 1/2; padding-right: 8px;}
        &:nth-of-type(5){ grid-row: 4/5; grid-column: 2/3; padding-left: 8px;}
        &:nth-of-type(6){ grid-row: 5/6; grid-column: 1/3; }

        label{
            display: block;
            width: 100%;
            margin-bottom: 4px;
        }
        input,
        select{
            height: 32px;
            padding: 0 0.5em;
        }
        input{
            width: 100%;
        }
        &:nth-of-type(3){ 
            select{
                width: 6em;
                margin-right: 16px;
            }
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;

export function BillingRuleForm({ data, onSubmit, onDismiss }: Props) {
    const [name, setName] = useState(data.name);
    const [remarks, setRemarks] = useState(data.remarks);
    const [fromMonth, setFromMonth] = useState(String(data.periodFrom?.monthFlg));
    //const [fromDate, setFromDate] = useState(String(data.periodFrom?.day));
    const [toMonth, setToMonth] = useState(String(data.periodTo?.monthFlg));
    const [toDate, setToDate] = useState(String(data.periodTo?.day));
    const [packPrice, setPackPrice] = useState(data.packPrice);
    const [contentsMax, setContentsMax] = useState(data.contentsMax);
    const [overageCharges, setOverageCharges] = useState(data.overageCharges);

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        onSubmit({
            id: data.id,
            name,
            remarks,
            periodFrom: {
                monthFlg: Number(fromMonth),
                day: Number(toDate),
            },
            periodTo: {
                monthFlg: Number(toMonth),
                day: Number(toDate),
            },
            packPrice,
            contentsMax,
            overageCharges,
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <main>
                <InputList>
                    <li>
                        <label htmlFor="name">請求ルール名</label>
                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="remarks">請求書名</label>
                        <input type="text" id="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                    </li>
                    <li>
                        <label>請求日</label>
                        <span>開始月</span>
                        <SelectFromBillingMonth value={String(fromMonth)} onChange={setFromMonth} />
                        <span>期間</span>
                        <SelectBillingMonth value={String(toMonth)} onChange={setToMonth} />
                        <span>締め日</span>
                        <SelectBillingDate value={String(toDate)} onChange={setToDate} />
                    </li>
                    <li>
                        <label htmlFor="packPrice">パック料金</label>
                        <input
                            type="number"
                            id="packPrice"
                            value={packPrice}
                            onChange={(e) => setPackPrice(Number(e.target.value))}
                            min="0"
                        />
                    </li>
                    <li>
                        <label htmlFor="contentsMax">コンテンツ上限数</label>
                        <input
                            type="number"
                            id="contentsMax"
                            value={contentsMax}
                            onChange={(e) => setContentsMax(Number(e.target.value))}
                            min="0"
                        />
                    </li>
                    <li>
                        <label htmlFor="overageCharges">超過料金(1コンテンツあたり)</label>
                        <input
                            type="number"
                            id="overageCharges"
                            value={overageCharges}
                            onChange={(e) => setOverageCharges(Number(e.target.value))}
                            min="0"
                        />
                    </li>
                </InputList>
            </main>

            <footer>
                <section className="buttons">
                    <Button type="button" variant="outlined" onClick={onDismiss}>キャンセル</Button>
                    <Button type="submit" variant="contained" disableElevation >保存</Button>
                </section>
            </footer>
        </form>
    );
}
