import { Dialog, Button } from "@material-ui/core";
// import { DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MyUserForm } from "../../components/MyUserForm";
import { User, UserForm, UserForm as UserFormData } from "../../gateways/cms/user";
import { useGetMyUserQuery, useUpdateUserMutation } from "../../gateways/cms/userApi";

// TODO: ヘッダのドロップダウンから起動すると何故かドロップダウンの表示が崩れる

import { styled } from "@mui/system";
import VarConst from "../../styles/variables.js";

// style
const DialogOuter = styled(Dialog)`
    .MuiDialog-paper {
        width: 800px;
        max-width: 100%;
        border-radius: 16px;

        header {
            text-align: center;
            padding: 40px 32px 0;

            h1 {
                font-size: 24px;
                font-weight: normal;
                margin: 0;
                line-height: 1;
            }
        }
        main {
            padding: 24px 56px calc(24px + 64px);

            label {
                display: flex;
                align-items: center;
                margin-bottom: 4px 8px;
            }
            input[type="text"],
            select{
                height: 32px;
                padding: 4px;
            }
            input[type="text"],
            select,
            textarea{
                width: 100%;
                border: ${VarConst.border1};
            }
            textarea{
                height: 75px;
            }

            form{
                display: flex;
                justify-content: space-between;

                ul{
                    width: 47%;

                    li{
                        margin-bottom: 16px;
                    }

                    &:nth-of-type(2){
                        li input{
                                margin-bottom: 16px;
                        }
                    }
                }

            }


        }

        footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 32px;
            background-color: #f4f4f4;

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 64px;
            }

            button {
                height: 32px;
                width: 116px;
                border-radius: 2px;
                padding: 4px;

                &.MuiButton-contained {
                    color: #f4f4f4;
                    background-color: #3a3e5d;
                    border-color: #3a3e5d;
                }
                &.MuiButton-outlined {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: #c9cbd3;
                }

                & + button {
                    margin-left: 16px;
                }
            }
        }
    }
`;

export default function MyUserModal() {
    const navigate = useNavigate();
    const user = useGetMyUserQuery();
    const [userUpdate] = useUpdateUserMutation();

    const handleDismiss = () => {
        navigate(-1);
    };

    const handleSave = (data: UserFormData) => {
        userUpdate(data);
        navigate(-1);
    };
    
    return (
        <DialogOuter aria-labelledby="label" open onClose={handleDismiss}>
            <header>
                <h1>アカウント情報</h1>
            </header>

            <main>
                {user.data && (
                    <MyUserForm
                        data={transformUserToUserForm(user.data)}
                        onSubmit={handleSave}
                        btnGroup={
                            <>
                                <ul>
                                    <li>
                                        <label htmlFor="authority">テナント・権限</label>
                                        {user.data.organization?.map((organization) => (
                                            <input
                                                type="text"
                                                value={organization.name + "・" + organization.usergroup.name}
                                                disabled
                                            />
                                        ))}
                                    </li>
                                </ul>

                                <footer>
                                    <section className="buttons">
                                        <Button variant="outlined" type="button" onClick={handleDismiss}>
                                            キャンセル
                                        </Button>
                                        <Button variant="contained" type="submit" disableElevation >
                                            保存
                                        </Button>
                                    </section>
                                </footer>
                            </>
                        }
                    />
                )}
            </main>
        </DialogOuter>
    );
}

// UserのレスポンスとUserFormのリクエストが一致しないので調整
// TODO: user.login_idをloginIdにしてもらう
function transformUserToUserForm(user: User): UserForm {
    return {
        ...user,
        loginId: user.login_id,
    };
}
