import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import {
    DeliveryRule,
    DeliveryRuleDeleteFormRequest,
    DeliveryRuleFormRequest,
    DeliveryRuleQuery,
    DeliverySchedule,
    DeliveryOrganizations,
} from "./deliveryRule";
import { Id } from "./id";

export const deliveryRuleApi = createApi({
    reducerPath: "deliveryRuleApi",
    baseQuery,
    tagTypes: ['OrganizationRule', 'DeliveryGroupRule', 'TerminalRule'],
    endpoints: (builder) => ({
        getDeliveryRules: builder.query<DeliveryRule[], DeliveryRuleQuery>({
            query: ({ organizationId, ...params }) => ({
                url: `terminalschedules/${organizationId}`,
                params,
            }),
            providesTags: ['OrganizationRule'],
        }),
        getOrganizationDeliveryRule: builder.query<DeliverySchedule[], Id>({
            query: (id) => ({
                url: `terminalschedules/list/organization/${id}`,
            }),
            transformResponse: (response: { schedule: DeliverySchedule[] }) => response.schedule,
            providesTags: ['OrganizationRule'],
        }),
        getDeliveryRuleOrganizations: builder.query<DeliveryOrganizations, Id>({
            query: (id) => ({
                url: `terminalschedules/organizations/${id}`,
            }),
            providesTags: ['OrganizationRule'],
        }),
        updateOrganizationDeliveryRule: builder.mutation<void, DeliveryRuleFormRequest>({
            query: ({ organizationId, ...body }) => ({
                url: `terminalschedules/organization/${organizationId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['OrganizationRule'],
        }),
        deleteOrganizationDeliveryRule: builder.mutation<void, DeliveryRuleDeleteFormRequest>({
            query: ({ id, scheduleIds }) => ({
                url: `terminalschedules/organization/${id}`,
                method: "delete",
                body: {
                    id: scheduleIds,
                },
            }),
            invalidatesTags: ['OrganizationRule'],
        }),
        getDeliveryGroupDeliveryRule: builder.query<DeliverySchedule[], Id>({
            query: (id) => ({
                url: `terminalschedules/list/deliverygroup/${id}`,
            }),
            transformResponse: (response: { schedule: DeliverySchedule[] }) => response.schedule,
            providesTags: ['DeliveryGroupRule'],
        }),
        updateDeliveryGroupDeliveryRule: builder.mutation<void, DeliveryRuleFormRequest>({
            query: ({ deliveryGroupId, ...body }) => ({
                url: `terminalschedules/deliverygroup/${deliveryGroupId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['DeliveryGroupRule'],
        }),
        deleteDeliveryGroupDeliveryRule: builder.mutation<void, DeliveryRuleDeleteFormRequest>({
            query: ({ id, scheduleIds }) => ({
                url: `terminalschedules/deliverygroup/${id}`,
                method: "delete",
                body: {
                    id: scheduleIds,
                },
            }),
            invalidatesTags: ['DeliveryGroupRule'],
        }),
        getTerminalDeliveryRule: builder.query<DeliverySchedule[], Id>({
            query: (id) => ({
                url: `terminalschedules/list/terminal/${id}`,
            }),
            transformResponse: (response: { schedule: DeliverySchedule[] }) => response.schedule,
            providesTags: ['TerminalRule'],
        }),
        updateTerminalDeliveryRule: builder.mutation<void, DeliveryRuleFormRequest>({
            query: ({ terminalId, ...body }) => ({
                url: `terminalschedules/terminal/${terminalId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['TerminalRule'],
        }),
        deleteTerminalDeliveryRule: builder.mutation<void, DeliveryRuleDeleteFormRequest>({
            query: ({ id, scheduleIds }) => ({
                url: `terminalschedules/terminal/${id}`,
                method: "delete",
                body: {
                    id: scheduleIds,
                },
            }),
            invalidatesTags: ['TerminalRule'],
        }),
    }),
});

export const {
    useGetDeliveryRulesQuery,
    useGetOrganizationDeliveryRuleQuery,
    useGetDeliveryRuleOrganizationsQuery,
    useUpdateOrganizationDeliveryRuleMutation,
    useDeleteOrganizationDeliveryRuleMutation,
    useGetDeliveryGroupDeliveryRuleQuery,
    useUpdateDeliveryGroupDeliveryRuleMutation,
    useDeleteDeliveryGroupDeliveryRuleMutation,
    useGetTerminalDeliveryRuleQuery,
    useUpdateTerminalDeliveryRuleMutation,
    useDeleteTerminalDeliveryRuleMutation,
} = deliveryRuleApi;
