//import { useEffect  } from "react";
import { useGetOrganizationChildrenQuery } from "../gateways/cms/organizationApi";

import { styled } from "@mui/system";
import VarConst from "../styles/variables.js";

//  ヘッダ内 組織のセレクトボックス（指定した組織の下の階層のみ）

export interface Props {
    parent?: string;
    value?: string;
    disabled?: boolean;
    key: string;
    onChange: (organizationId: string) => void;
}

// style
const Select = styled("select")`
    height: 24px;
    min-width: 110px;
    background-color: #fff;
    border-radius: 2px;
    border: ${VarConst.border1};

    & + span {
        display: inline-block;
        width: 24px;
        text-align: center;
        color: #788189;
    }
    .MuiSelect-select {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 12px;
    }
`;

/**
 * [ヘッダ] 組織のセレクトボックス（指定した組織の下の階層のみ）
 */
export function SelectOrganizationChildren({ parent, value, disabled, key, onChange }: Props) {
    const organizations = useGetOrganizationChildrenQuery(parent);
    /*
    useEffect(() => {
        console.log(key);
        localStorage.setItem(key, JSON.stringify(value));
        localStorage.setItem("organization_id", JSON.stringify(value));
    });
    */ 

    return (
        <Select value={value} disabled={disabled} onChange={(e) => onChange(e.target.value)}>
            <option value="">すべて</option>
            {organizations.data?.organizations?.map((organization) => (
                <option key={organization.id} value={organization.id}>
                    {organization.name}
                </option>
            ))}
        </Select>
    );
}
