import { createApi } from "@reduxjs/toolkit/query/react";
import { Article } from "./article";
import { baseQuery } from "./baseQuery";
import { ScheduleForm, ScheduleQuery } from "./schedule";

export const scheduleApi = createApi({
    reducerPath: "scheduleApi",
    baseQuery,
    tagTypes: ['Schedule'],
    endpoints: (builder) => ({
        getDailySchedule: builder.query<Article[], ScheduleQuery>({
            query: (params) => ({
                url: `schedule/daily`,
                params,
            }),
            providesTags: ['Schedule'],
        }),
        getMonthlySchedule: builder.query<Article[], ScheduleQuery>({
            query: (params) => ({
                url: `schedule/monthly`,
                params,
            }),
            providesTags: ['Schedule'],
        }),
        updateDailySchedule: builder.mutation<void, ScheduleForm>({
            query: ({ deliveryGroupId, body }) => ({
                url: `schedule/daily/${deliveryGroupId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Schedule'],
        }),
        updateMonthlySchedule: builder.mutation<void, ScheduleForm>({
            query: ({ deliveryGroupId, body }) => ({
                url: `schedule/monthly/${deliveryGroupId}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['Schedule'],
        }),
    }),
});

export const {
    useGetDailyScheduleQuery,
    useGetMonthlyScheduleQuery,
    useUpdateDailyScheduleMutation,
    useUpdateMonthlyScheduleMutation,
} = scheduleApi;
