import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value: string;
    onChange: (value: string) => void;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

export function SelectBillingMonth({ value, onChange }: Props) {
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(e.target.value)}>
            <option value="1">1ヶ月</option>
            <option value="2">2ヶ月</option>
            <option value="3">3ヶ月</option>
            <option value="4">4ヶ月</option>
            <option value="5">5ヶ月</option>
            <option value="6">6ヶ月</option>
        </SelectWithIcon>
    );
}

export function SelectFromBillingMonth({ value, onChange }: Props) {
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(e.target.value)}>
            <option value="0">当月</option>
            <option value="1">翌月</option>
        </SelectWithIcon>
    );
}