import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../components/Copyright";
import { FormErrorMessage } from "../components/FormErrorMessage";
import approval from "../api-clients/actions/approval";
import { useDispatch } from "react-redux";
import { updated as tokenUpdated } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

const SectionTitle = styled("h2")`
    margin: 24px 0 5px;
    padding: 10px 10px 10px 40px;
    font-weight: normal;
    font-size: 18px;
    background-color: #f4f4f4;
    line-height: 1;
`;

const theme = createTheme();

export default function Approval() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDisabledSubmit, setDisabledSubmit] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        setErrorText("");
        setDisabledSubmit(true);
        approval(new FormData(e.currentTarget))
            .then((token) => {
                dispatch(tokenUpdated(token));
                navigate("/login");
            })
            .catch((e: Error) => {
                setErrorText(e.message);
                setDisabledSubmit(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" gutterBottom>
                        招待受諾
                    </Typography>

                    {/* エラーメッセージを表示するUI */}
                    <FormErrorMessage message={errorText} />
                    
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <SectionTitle>仮ログイン</SectionTitle>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="temp-login_id"
                            label="仮ログインID（招待メールに記載）"
                            name="temp-login_id"
                            type="e-mail"
                            autoComplete="new-email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="temp-password"
                            label="仮パスワード（招待メールに記載）"
                            name="temp-password"
                            type="password"
                            autoComplete="new-password"
                        />

                        <SectionTitle>ユーザー情報</SectionTitle>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="login_id"
                            label="ログインID（任意英数字）"
                            name="login_id"
                            autoComplete="new-login-id"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="お名前"
                            name="name"
                            autoComplete="new-name"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="パスワード（任意英数字）"
                            name="password"
                            type="password"
                            autoComplete="new-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isDisabledSubmit}
                        >
                            {" "}
                            招待受諾
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
