import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ArticleForm } from "../../components/ArticleForm";
import { ArticleForm as ArticleFormData } from "../../gateways/cms/article";
import { useCreateArticleMutation } from "../../gateways/cms/articleApi";
import { useGetDefaultUrlQuery } from "../../gateways/cms/schemaApi";
import MainLayout from "../../layouts/MainLayout";
import { selectOrganizationId } from "../../features/cms/organizationBreadcrumbSlice";

import { styled } from "@mui/system";

export const ArticleCreate = () => {
    const navigate = useNavigate();
    const [articleCreate] = useCreateArticleMutation();
    const GetDefaultUrl = useGetDefaultUrlQuery();
    const organizationId = useSelector(selectOrganizationId);
    const [checkSaveAs, setSaveAs] = useState(false);

    let navi = GetDefaultUrl.currentData || "/articles?status=50";
    const handleSubmit = (articleForm: ArticleFormData) => {
        setSaveAs(true);
        articleCreate(articleForm).then(() => navigate(String(navi)));
    };

    // style
    const PageTitle = styled("h1")`
        margin: 0;
        padding: 24px;
        font-size: 24px;
        font-weight: normal;

        & + form,
        & + article{
            flex: 1;
            overflow: auto;
        }
    `;

    return (
        <MainLayout title="コンテンツ作成">
            <PageTitle>コンテンツ作成</PageTitle>
            <ArticleForm organizationId={organizationId} checkSaveAs={checkSaveAs} onSubmit={handleSubmit} isEdit={false}/>
        </MainLayout>
    );
};
