import { createAxios } from "../axiosUtil";
import PermanentDataStore from "../../supports/PermanentDataStore";

export default function logout(): Promise<any> {
    // トークンの削除
    const store = PermanentDataStore.load();
    const token = store.getToken();
    store.removeToken().sync();

    return createAxios({ token }).post<any>("/logout");
}
