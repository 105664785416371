import { ChangeEvent } from "react";
import { useGetDisplayModelsQuery } from "../gateways/cms/displayModelApi";

import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value: string;
    required?: boolean;
    onChange: (value: string) => unknown;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

export function SelectDisplayModel({ onChange, ...props }: Props) {
    const displayModels = useGetDisplayModelsQuery();

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        onChange(e.target.value);
    };

    return (
        <SelectWithIcon {...props} onChange={handleChange}>
            <option value="">未選択</option>
            {displayModels.data?.map((m) => (
                // idでなくnameで選択
                <option key={m.id} value={m.name}>
                    {m.name}
                </option>
            ))}
        </SelectWithIcon>
    );
}
