import { baseUrl } from "../../config/api";
import { TerminalSettingsDownloadFormRequest } from "./terminal";

export const terminalSettingsDownload = async (token: string, request: TerminalSettingsDownloadFormRequest) => {
    return await fetch(baseUrl + "terminal/setting/download", {
        method: "post",
        body: JSON.stringify(request),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
