import * as React from "react";
// import { ReactNode, React.useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Popover from '@material-ui/core/Popover';
import ButtonMUI from "@material-ui/core/Button";

import { styled } from "@mui/system";
import iconEllipsis from "../assets/icons/icon_ellipsis.svg";

export interface Props {
    popTitle: React.ReactNode;
    popMain: React.ReactNode;
    linkDetail: String;
}

export const ItemSummaryPop = ({
    popTitle,
    popMain,
    linkDetail,
}: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const navigate = useNavigate();

    const divRef = React.useRef(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };

    // 各ボタンの処理
    const handleDetail = () => {
        navigate("" + linkDetail);
        close();
    };
    // const handleDelte = () => {
    //     onArticleDelte(article.id);
    //     close();
    // };


    // style
    const ButtonPop = styled(ButtonMUI)`
        height: 32px;
        width: 32px;
        min-width: 0;
        background-image: url(${iconEllipsis});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
    `;
    const PopoverOuter = styled(Popover)`
        .MuiPaper-root {
            border-radius: 0;
        }
    `;
    const PopoverMain = styled("section")`
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 144px;
        padding: 16px;
        font-size: 12px;

        .popTitle{
            margin: 0;

            select{
                margin-bottom: 8px;
                border: none;
                background-color: transparent;
                pointer-events: none;
                font-size: 14px;
                appearance: none;
            }
        }
        .popMain{
            flex: 1;
            margin-bottom: 16px;
        }
        .MuiButtonBase-root {
            width: 116px;
            align-self: end;
            color: #434343;
            border-color: #B6B8BF;
        }
    `;

    return (
        <div ref={divRef}>
            <ButtonPop 
                aria-describedby={id} 
                onClick={handleClick} 
            />
            <PopoverOuter
                id={id}
                open={open}
                // anchorEl={anchorEl}
                anchorEl={divRef.current}
                onClose={close}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <PopoverMain>
                    <h3 className="popTitle">
                        {popTitle}
                    </h3>
                    <section className="popMain">
                        {popMain}
                    </section>
                    <Button  variant="outlined" onClick={handleDetail}>
                        詳細を見る
                    </Button>
                </PopoverMain>
            </PopoverOuter>
        </div>
    );
};



                                        