import { FormEvent, ReactNode, useState } from "react";
import { InvitationForm as InvitationFormData } from "../gateways/cms/invitation";
import { SelectOrganization } from "./SelectOrganization";
import { SelectUserGroup } from "./SelectUserGroup";

import { styled } from "@mui/system";

export interface Props {
    onSubmit: (data: InvitationFormData) => unknown;
    users: { name: string; email: string }[];
    children: ReactNode;
}

export function InvitationResendForm({ children, users, onSubmit }: Props) {
    const [organizationId, setOrganizationId] = useState("");
    const [userGroupId, setUserGroupId] = useState("");
    const [comment, setComment] = useState("");

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            organizationId: Number(organizationId),
            usergroup: Number(userGroupId),
            email: users.map((u) => u.email),
            comment,
        });
    };

    // style
    const InputInvite = styled("ul")`
        /* display: grid;
        grid-template-columns: 12.5% 12.5% 25% 50% ; */
        /* padding: 0 24px 24px; */
        display:flex;
        flex-wrap: wrap;
        
        & > li{
            margin-bottom: 16px;

            &:nth-of-type(1){ width:35%; }
            &:nth-of-type(2){ flex: 1; }
            &:nth-of-type(3){ width: 100%;}
            &:nth-of-type(4){ width: 100%;}

            label{
                margin-bottom: 4px;
            }
            select,
            textarea{
                width: 12em;
                padding: 0.25em 0.5em;
            }
            select{
                width: 12em;
                height: 32px;
            }
            textarea{
                width: 100%;
                height: calc(1em * 1.5 * 3);
            }
            ul {
                padding-left: 0.5em;
                li{
                    display: inline;
                    word-break: break-all;
    
                    & + li::before{
                        content: "、";
                    }
                }
            }

            &:nth-of-type(2){
                select{
                    margin-right: 16px;
                }
            }
        }
    `;

    return (
        <form onSubmit={handleSubmit}>
            <InputInvite>
                <li>
                    <label htmlFor="organizationId">テナント</label>
                    <SelectOrganization value={organizationId} required onChange={setOrganizationId} />
                </li>
                <li>
                    <label htmlFor="userGroupId">権限</label>
                    <SelectUserGroup value={String(userGroupId)} required onChange={setUserGroupId} />
                    として招待する。
                </li>
                <li>
                    <textarea
                        placeholder="招待メールにコメントを追加する場合は入力してください。"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </li>
                <li>
                    <label htmlFor="email">再招待するユーザー ({users.length})</label>
                    <ul>
                        {users.map((user) => (
                            <li>${user.email} (${user.name})</li>
                        ))}
                    </ul>
                </li>
            </InputInvite>

            {children}
        </form>
    );
}
