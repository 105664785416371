import { useGetOrganizationsQuery } from "../gateways/cms/organizationApi";

// [コンテンツ編集] 請求情報 配信先指定

export interface Props {
    value?: string | number;
    disabled?: boolean;
    required?: boolean;
    exclude?: number[];
    onChange?: (value: string) => void;
}

/**
 * 組織のセレクトボックス（全ての組織）
 */
export function SelectOrganization({ value, disabled, required, exclude, onChange = () => {} }: Props) {
    const allOrganizations = useGetOrganizationsQuery();

    const organizations = allOrganizations.data?.data?.filter((o) => !exclude?.includes(o.id));

    return (
        <select value={value} required={required} disabled={disabled} onChange={(e) => onChange(e.target.value)}>
            <option value="">未選択</option>
            {organizations?.map((organization) => (
                <option key={organization.id} value={organization.id}>
                    {organization.name}
                </option>
            ))}
        </select>
    );
}
