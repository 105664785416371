import { Link, useParams, useNavigate } from "react-router-dom";
import { OrganizationForm } from "../../components/OrganizationForm";
import { OrganizationForm as OrganizationFormData } from "../../gateways/cms/organization";
import { useGetOrganizationQuery, useUpdateOrganizationMutation } from "../../gateways/cms/organizationApi";
import MainLayout from "../../layouts/MainLayout";
import ButtonMUI from "@material-ui/core/Button";

import { styled } from "@mui/system";

// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;
const OrganizationEditTab = styled("ul")`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 16px;
    border-bottom: 8px solid #3A3E5D;

    li {
        width: 240px;
        height: 40px;
        text-align: center;
        margin: 0 4px 0 0;
        border-radius: 8px 8px 0 0;
        background-color: #DADDDE;
        overflow: hidden;
        line-height: 40px;
        
        a{
            display:block;
            height: 100%;
            color: inherit;
            text-decoration: none;
        }

        &.current,
        &:hover a{
            color: #fff;
            background-color: #3A3E5D;
        }
    }
`;
const OrganizationFormFooter = styled("footer")`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 260px);
    padding: 16px 24px;
    background-color: #F4F4F4;
`;
const Button = styled(ButtonMUI)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #C9CBD3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3A3E5D;
        border-color: #3A3E5D;
    }
`;

export function OrganizationEditPage() {
    const { id } = useParams();
    const organization = useGetOrganizationQuery(Number(id));
    const [organizationUpdate] = useUpdateOrganizationMutation();

    const navigate = useNavigate();
    const handleSubmit = async (data: OrganizationFormData) => {
        await organizationUpdate(data);
        navigate(-1);
    };

    return (
        <MainLayout title="テナント管理（編集）">
            <PageTitle>
                <h1>テナント管理（編集）</h1>
            </PageTitle>

            <OrganizationEditTab>
                <li className="current">
                    テナント情報
                </li>
                <li>
                    <Link to={`/organizations/${id}/delivery-groups`}>配信グループ・端末管理</Link>
                </li>
            </OrganizationEditTab>

            {organization.data && (
                <OrganizationForm key={id} organization={organization.data} onSubmit={handleSubmit} >
                    <OrganizationFormFooter>
                        <div className="footerLeft">
                            {/* TODO:<確認> [テナント削除]ボタンが無い */}
                        </div>
                        <div className="footerRight">
                            <Button type="button" variant="outlined" href="/organizations">キャンセル</Button>
                            <Button type="submit" variant="contained" disableElevation >保存</Button>
                        </div>
                    </OrganizationFormFooter>
                </OrganizationForm>
            )}
        </MainLayout>
    );
}
