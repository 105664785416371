import { orderedArticlesSlice } from "./features/cms/orderedArticlesSlice";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import authSlice from "./features/auth/authSlice";
import invitationSlice from "./features/cms/invitationSlice";
import { organizationBreadcrumbSlice } from "./features/cms/organizationBreadcrumbSlice";
import { articleApi } from "./gateways/cms/articleApi";
import { billingRuleApi } from "./gateways/cms/billingRuleApi";
import { deliveryGroupApi } from "./gateways/cms/deliveryGroupApi";
import { deliveryRuleApi } from "./gateways/cms/deliveryRuleApi";
import { displayModelApi } from "./gateways/cms/displayModelApi";
import { invitationApi } from "./gateways/cms/invitationApi";
import { logApi } from "./gateways/cms/logApi";
import { organizationApi } from "./gateways/cms/organizationApi";
import { scheduleApi } from "./gateways/cms/scheduleApi";
import { schemaApi } from "./gateways/cms/schemaApi";
import { taskApi } from "./gateways/cms/taskApi";
import { terminalApi } from "./gateways/cms/terminalApi";
import { userApi } from "./gateways/cms/userApi";
import { userGroupApi } from "./gateways/cms/userGroupApi";
import { unauthenticatedMiddleware } from "./middleware/unauthenticatedMiddleware";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        invitation: invitationSlice,
        [organizationBreadcrumbSlice.name]: organizationBreadcrumbSlice.reducer,
        [orderedArticlesSlice.name]: orderedArticlesSlice.reducer,
        [articleApi.reducerPath]: articleApi.reducer,
        [scheduleApi.reducerPath]: scheduleApi.reducer,
        [organizationApi.reducerPath]: organizationApi.reducer,
        [invitationApi.reducerPath]: invitationApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [userGroupApi.reducerPath]: userGroupApi.reducer,
        [deliveryGroupApi.reducerPath]: deliveryGroupApi.reducer,
        [billingRuleApi.reducerPath]: billingRuleApi.reducer,
        [schemaApi.reducerPath]: schemaApi.reducer,
        [logApi.reducerPath]: logApi.reducer,
        [terminalApi.reducerPath]: terminalApi.reducer,
        [displayModelApi.reducerPath]: displayModelApi.reducer,
        [deliveryRuleApi.reducerPath]: deliveryRuleApi.reducer,
        [taskApi.reducerPath]: taskApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    // redux-persistのアクションはシリアライズ可能かどうかチェックしない
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
            .concat([unauthenticatedMiddleware])
            .concat([
                articleApi.middleware,
                scheduleApi.middleware,
                organizationApi.middleware,
                invitationApi.middleware,
                userApi.middleware,
                userGroupApi.middleware,
                deliveryGroupApi.middleware,
                billingRuleApi.middleware,
                schemaApi.middleware,
                logApi.middleware,
                terminalApi.middleware,
                displayModelApi.middleware,
                deliveryRuleApi.middleware,
                taskApi.middleware,
            ]),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
