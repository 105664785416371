import assert from "assert";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DeliveryScheduleListTemplate } from "../../components/DeliveryScheduleListTemplate";
import { useGetDeliveryGroupQuery } from "../../gateways/cms/deliveryGroupApi";
import { DeliveryRuleFormRequest, DeliverySchedule } from "../../gateways/cms/deliveryRule";
import {
    useDeleteDeliveryGroupDeliveryRuleMutation,
    useGetDeliveryGroupDeliveryRuleQuery,
    useUpdateDeliveryGroupDeliveryRuleMutation,
} from "../../gateways/cms/deliveryRuleApi";
import { ScheduleEditModal } from "./ScheduleEditModal";
import MainLayout from "../../layouts/MainLayout";

export function DeliveryGroupScheduleEditPage() {
    const { deliveryGroupId } = useParams();
    assert(deliveryGroupId);

    const deliveryGroup = useGetDeliveryGroupQuery(deliveryGroupId);
    const schedules = useGetDeliveryGroupDeliveryRuleQuery(deliveryGroupId);
    const [scheduleUpdate] = useUpdateDeliveryGroupDeliveryRuleMutation();
    const [scheduleDelete] = useDeleteDeliveryGroupDeliveryRuleMutation();
    const [selected, setSelected] = useState<DeliveryRuleFormRequest>();
    const [selectedIds, setSelectedIds] = useState([] as number[]);

    const handleCreate = () => {
        setSelected({
            type: 0,
            poweron: true,
        });
    };

    const handleEdit = (schedule: DeliverySchedule) => {
        setSelected(schedule);
    };

    const save = async (data: DeliveryRuleFormRequest) => {
        await scheduleUpdate({ deliveryGroupId: Number(deliveryGroupId), ...data });
        closeEditModal();
    };

    const handleUpdate = async (data: DeliveryRuleFormRequest) => {
        await scheduleUpdate({ deliveryGroupId: Number(deliveryGroupId), ...data });
    };

    const closeEditModal = () => {
        setSelected(undefined);
    };

    const handleDelete = async (scheduleId: number) => {
        await scheduleDelete({ id: Number(deliveryGroupId), scheduleIds: [scheduleId] });
        schedules.refetch();
    };

    const handleBulkDelete = async (scheduleIds: number[]) => {
        setSelectedIds([]);
        await scheduleDelete({ id: Number(deliveryGroupId), scheduleIds });
        schedules.refetch();
    };

    const subtitle = deliveryGroup.data?.terminals?.map((t) => t.name)?.join("、");

    return (
        <>
            <MainLayout title="配信グループスケジュール">
                {schedules.data && (
                    <DeliveryScheduleListTemplate
                        title={deliveryGroup.data?.name}
                        subtitle={subtitle ? `（${subtitle}）` : ""}
                        schedules={schedules.data}
                        selectedIds={selectedIds}
                        onCreate={handleCreate}
                        onEdit={handleEdit}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        onBulkDelete={handleBulkDelete}
                        onSelect={setSelectedIds}
                        scheduleTitle="配信グループスケジュール"
                    />
                )}
            </MainLayout>

            {selected && <ScheduleEditModal data={selected} onSubmit={save} onDismiss={closeEditModal} />}
        </>
    );
}
