import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { InvitationForm } from "./invitation";

export const invitationApi = createApi({
    reducerPath: "invitationApi",
    baseQuery,
    endpoints: (builder) => ({
        inviteUser: builder.mutation<void, InvitationForm>({
            query: (body) => ({ url: "users/invitation", method: "POST", body }),
        }),
    }),
});

export const { useInviteUserMutation } = invitationApi;
