import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { DeliveryGroup, DeliveryGroupCreateFormRequest, DeliveryGroupForm } from "./deliveryGroup";
import { DeliveryOrganizationUserQuery } from "./article";
import { Id } from "./id";

export const deliveryGroupApi = createApi({
    reducerPath: "deliveryGroupApi",
    baseQuery,
    tagTypes: ['DeliveryGroup', 'Article'],
    endpoints: (builder) => ({
        getDeliveryGroups: builder.query<DeliveryGroup[], Id>({
            query: (organizationId) => `deliverygroups/list/${organizationId}`,
            providesTags: ['DeliveryGroup'],
        }),
        getUserDeliveryGroups: builder.query<DeliveryGroup[], DeliveryOrganizationUserQuery>({
            query: (params) => ({
                url: `deliverygroups/userlist`,
                params,
            }),
            providesTags: ['DeliveryGroup'],
        }),
        getDeliveryGroup: builder.query<DeliveryGroup, Id>({
            query: (deliveryGroupId) => `deliverygroups/${deliveryGroupId}`,
            providesTags: ['DeliveryGroup'],
        }),
        createDeliveryGroup: builder.mutation<void, DeliveryGroupCreateFormRequest>({
            query: (body) => ({
                url: "deliverygroups",
                method: "post",
                body,
            }),
            invalidatesTags: ['DeliveryGroup'],
        }),
        updateDeliveryGroup: builder.mutation<void, DeliveryGroupForm>({
            query: ({ id, ...body }) => ({
                url: `deliverygroups/${id}`,
                method: "post",
                body,
            }),
            invalidatesTags: ['DeliveryGroup'],
        }),
        deleteDeliveryGroup: builder.mutation<void, Id>({
            query: (deliveryGroupId) => ({ url: `deliverygroups/${deliveryGroupId}`, method: "delete" }),
            invalidatesTags: ['DeliveryGroup'],
        }),
    }),
});

export const {
    useGetDeliveryGroupsQuery,
    useGetUserDeliveryGroupsQuery,
    useGetDeliveryGroupQuery,
    useCreateDeliveryGroupMutation,
    useUpdateDeliveryGroupMutation,
    useDeleteDeliveryGroupMutation,
} = deliveryGroupApi;
