import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Article } from "../gateways/cms/article";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useGetMyUserQuery } from "../gateways/cms/userApi";

export interface Props {
    article: Article;
    onArticleDuplicate: (id: number, modaloption: string, deliveryGroupId: number) => void;
    onArticleDraft: (id: number) => void;
    onArticleApprove: (id: number) => void;
    onArticleReject: (id: number) => void;
    onArticleDelte: (id: number) => void;
}

const MinimumMenu = styled((props: MenuProps) => (
    <Menu 
        {...props} 
    />
    ))(({ theme }) => ({
    "#schedul-article-menu-button": {
        minWidth: 24,
    },
    "& .MuiPaper-root": {
        borderRadius: 2,
        width: 200,
        fontSize: 14,
        maxHeight: "calc(100vh - 258px) !important",

        "& .MuiList-root": {
            padding: 0,
        },
        "& .MuiDivider-root": {
            marginTop: 0,
            marginBottom: 0,
        },
        "& .MuiList-root .delete": {
            color: "#FC6F6C",
        },
    },
}));

export const ArticleEditMenu = ({
    article,
    onArticleDuplicate,
    onArticleDraft,
    onArticleApprove,
    onArticleReject,
    onArticleDelte,
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };
    // 各ボタンの処理
    const handleDetail = () => {
        navigate("/articles/" + article.id + "/edit");
        close();
    };
    const handleCopy = () => {
        onArticleDuplicate(article.id, "article", 0);
        close();
    };
    const handleDraft = () => {
        onArticleDraft(article.id);
        close();
    };
    const handleApprove = () => {
        onArticleApprove(article.id);
        close();
    };
    const handleReject = () => {
        onArticleReject(article.id);
        close();
    };
    const handleDelte = () => {
        onArticleDelte(article.id);
        close();
    };
    const user = useGetMyUserQuery();
    const checkApprove = (article: Article, userId: number|undefined):boolean => {
        if(article.status !== "確認中"){
            return false;
        }
        let managers = article.manager;
        if(!managers) return false;
        if(!userId) return false;
        return managers.includes(userId);
    };
    const checkCreatedBy = (article: Article):boolean => {
        if(user.data?.checkAdmin) return true;
        if(article.createdBy.id === user.data?.id) return true; 
        return false;
    };
    const detailMenu = (article: Article):string => {
        if(checkCreatedBy(article)) return "詳細情報・編集"
        return "詳細情報"
    }
    const checkCopyEnable = (article: Article):boolean => {
        if(!user.data?.checkAgent) return true;
        if(article.createdBy.id === user.data?.id) return true; 
        return false;
    };

    return (
        <div>
            <Button
                id="schedul-article-menu-button"
                aria-controls={open ? "schedul-article--menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            ></Button>
            <MinimumMenu
                id="schedul-article--menu"
                MenuListProps={{
                    "aria-labelledby": "schedul-article-menu-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={close}
            >
                <MenuItem onClick={handleDetail} href={`/articles/${article.id}/edit`}>{detailMenu(article)}</MenuItem>
                <Divider sx={{ my: 0.5 }} />
                {checkCopyEnable(article) && <>
                    <MenuItem onClick={handleCopy}>コピー</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                </>}
                {(checkCreatedBy(article) && article.status !== "下書き") && <>
                    <MenuItem onClick={handleDraft}>下書きに移動</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                </>}
                {checkApprove(article, user.data?.id) && <>
                    <MenuItem onClick={handleApprove}>コンテンツを承認する</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={handleReject}>コンテンツを差し戻す</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                </>}
                {checkCreatedBy(article) && <>
                    <MenuItem onClick={handleDelte} className="delete">削除</MenuItem>
                </>}
            </MinimumMenu>
        </div>
    );
};
