import { DeliveryOrganizationForm } from "../gateways/cms/article";
// import { OrganizationItemAppendForm } from "./OrganizationItemAppendForm";
// import { SelectOrganization } from "./SelectOrganization";
import { SelectOrganizationDeliveryGroup } from "./SelectOrganizationDeliveryGroup";
import { UnSelectOrganizationDeliveryGroup } from "./SelectOrganizationDeliveryGroup";

import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import produce from "immer";

// [コンテンツ編集] 配信先
export interface Props {
    value: DeliveryOrganizationForm[];
    onChange: (value: DeliveryOrganizationForm[]) => void;
}

export function DeliveryOrganizationsFormGroup({ value, onChange }: Props) {
    // const addItem = (organizationId: number) => {
    //     onChange([...value, { organizationId, deliveryGroup: [] }]);
    // };

    // const deleteItem = (organizationId: number) => {
    //     onChange(value.filter((o) => o.organizationId !== organizationId));
    // };

    const changeItemDeliveryGroups = (organizationId: number, name: string, billingName: string, deliveryGroupIds: string[], articleType: boolean, index: number) => {
        onChange(
            produce(value, (draft) => {
                const i = draft.findIndex((o) => o.organizationId === organizationId);
                const newItem = {
                    organizationId,
                    name,
                    billingName,
                    deliveryGroup: deliveryGroupIds.map((id) => ({
                        deliveryGroupId: Number(id),
                        checked: true,
                    })),
                    articleType,
                    index,
                };
                if (i < 0) {
                    draft.push(newItem);
                } else {
                    draft[i] = newItem;
                }
            })
        );
    };

    const changeUnItemDeliveryGroups = (organizationId: number, name: string, billingName: string, deliveryGroupIds: string[], articleType: boolean, index: number) => {
        onChange(
            produce(value, (draft) => {
                const i = draft.findIndex((o) => o.organizationId === organizationId);
                const newItem = {
                    organizationId,
                    name,
                    billingName,
                    deliveryGroup: deliveryGroupIds = [],
                    articleType,
                    index,
                };
                if (i < 0) {
                    draft.push(newItem);
                } else {
                    draft[i] = newItem;
                }
            })
        );
    };

    // style
    const InputDeliveryGroup = styled(Grid)`
        width: 1000px;
        margin-bottom: 32px;
        padding-top: 8px;

        & > div.MuiGrid-item {
            padding-top: 16px;
        }
    `;
    const UnSelectLabel = styled("label")`
        color: silver
    `;

    return (
        <div>
            {value.map((item) => {if(item.articleType) {
                return(
                    <InputDeliveryGroup key={item.organizationId} container spacing={3}>

                    <Grid item xs={6}>
                        <label >{item.name} </label>
                    </Grid>
                    
                    <SelectOrganizationDeliveryGroup
                        organizationId={item.organizationId}
                        value={item.deliveryGroup.map((g) => String(g.deliveryGroupId))}
                        onChange={(ids) => changeItemDeliveryGroups(item.organizationId, item.name, item.billingName, ids, item.articleType, item.index)}
                    />

                    </InputDeliveryGroup>
            )}
            else{
                return(
                    <InputDeliveryGroup key={item.organizationId} container spacing={3}>

                    <Grid item xs={6}>
                        <UnSelectLabel>{item.name}</UnSelectLabel>
                    </Grid>
                    
                    <UnSelectOrganizationDeliveryGroup
                        organizationId={item.organizationId}
                        value={item.deliveryGroup.map((g) => String(g.deliveryGroupId))}
                        onChange={() => changeUnItemDeliveryGroups(item.organizationId, item.name, item.billingName, [], item.articleType, item.index)}
                    />

                    </InputDeliveryGroup>
            )}})}

        </div>
    );
}
