import { Link } from "react-router-dom";
import { Task } from "../gateways/cms/task";

import { EditMenuMinimum } from "../components/materials/EditMenuMinimum";

export interface Props {
    task: Task;
    selected: boolean;
    onSelect: (task: Task, selected: boolean) => unknown;
    onDelete: (task: Task) => unknown;
}

export function TaskListItem({ task, selected, onSelect, onDelete }: Props) {
    return (
        <tr>
            <td className="checkbox">
                <input type="checkbox" checked={selected} onChange={(e) => onSelect(task, e.target.checked)} />
                <span></span>
            </td>
            <td className="priority">{task.priority}</td>
            <td className="category">{task.category}</td>
            <td className="title">{task.title}</td>
            <td className="organization">{task.organization.name}</td>
            <td className="status">{task.status}</td>
            <td className="assignedUser">
                {task.assignedUser.map((user, i) => user.name).join("、")}
            </td>
            <td className="createdBy">{task.createdBy.name}</td>
            <td className="limitdate">{task.limitdate}</td>
            <td className="function">
                <EditMenuMinimum
                    menuEdit={<Link to={`/tasks/${task.id}`}>詳細を見る</Link>}
                    menuDelete={
                        <button type="button" onClick={() => onDelete(task)}>
                            削除
                        </button>
                    }
                />
            </td>
        </tr>
    );
}
