import { baseUrl } from "./../../config/api";
import { BillingDataDownloadRequest } from "./billingData";

export const billingDataDownload = async (token: string, request: BillingDataDownloadRequest) => {
    return await fetch(baseUrl + "billingdata", {
        method: "post",
        body: JSON.stringify(request),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
