import assert from "assert";
import produce from "immer";
import { ChangeEvent, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ModalLink } from "../../components/ModalLink";
import { useDownloadTerminalSettings } from "../../features/cms/useDownloadTerminalSettings";
import { Terminal } from "../../gateways/cms/terminal";
import { useDeleteTerminalMutation, useGetTerminalsQuery } from "../../gateways/cms/terminalApi";
import { EditMenuMinimum } from "../../components/materials/EditMenuMinimum";
import { confirmWithStyle } from "../../components/MyConfirm";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@material-ui/core/Button";
import iconCheckboxOff from "../../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../../assets/icons/icon_checkbox_on.svg";

export function DeliveryGroupTerminalListPage() {
    const { organizationId, deliveryGroupId } = useParams();
    assert(organizationId);
    assert(deliveryGroupId);

    const location = useLocation();
    const navigate = useNavigate();
    const terminals = useGetTerminalsQuery({ organization_id: organizationId, delivery_id: deliveryGroupId });
    const [terminalDelete] = useDeleteTerminalMutation();
    const [selectedTerminalIds, setSelectedTerminalIds] = useState([] as number[]);
    const settingsDownload = useDownloadTerminalSettings();

    const handleEdit = (terminalId: number) => () => {
        navigate(`/organizations/${organizationId}/terminals/${terminalId}/edit`, {
            state: { backgroundLocation: location },
        });
    };

    const handleDelete = (terminalId: number) => async () => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "ターミナルを削除します。よろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            await terminalDelete(terminalId);
            terminals.refetch();
        }
    };

    const handleSelect = (terminal: Terminal, selected: boolean) => {
        if (selected) {
            setSelectedTerminalIds([...selectedTerminalIds, terminal.id]);
        } else {
            setSelectedTerminalIds(
                produce(selectedTerminalIds, (draft) => {
                    draft.splice(draft.indexOf(terminal.id), 1);
                })
            );
        }
    };

    const downloadSettings = () => {
        settingsDownload({
            terminalId: selectedTerminalIds,
        });
    };

    const monitor_direction = (direction: Number) => {
        let str_direction = String(direction);
        if(str_direction === '0'){
            return '0度'
        }else if(str_direction === '1'){
            return '90度'
        }else if(str_direction === '2'){
            return '180度'
        }else if(str_direction === '3'){
            return '270度'
        }else{
            return ''
        }
    };

    const DeliveryGroupTable = styled("table")`
        & {
            width: 100%;
            font-size: 13px;

            thead tr {
                border-top: 1px solid #daddde;
            }
            tr {
                display: grid;
                grid-template-columns: 3em 3em 10em 30em 10em 10em 12em 1fr 3.5em;
                grid-template-rows: 1fr 1fr;

                border-left: 1px solid #daddde;

                & > * {
                    display: flex;
                    align-items: center;

                    padding-left: 8px;
                    padding-right: 8px;
                    border: 1px solid #daddde;
                    border-width: 0 1px 1px 0;
                    line-height: 1.2;

                    &:nth-of-type(1) {
                        grid-row: 1/3;
                        grid-column: 1/2;
                    }
                    &:nth-of-type(2) {
                        grid-row: 1/3;
                        grid-column: 2/3;
                    }
                    &:nth-of-type(3) {
                        grid-row: 1/3;
                        grid-column: 3/4;
                    }
                    &:nth-of-type(4) {
                        grid-row: 1/2;
                        grid-column: 4/5;
                    }
                    &:nth-of-type(5) {
                        grid-row: 2/3;
                        grid-column: 4/5;
                    }
                    &:nth-of-type(6) {
                        grid-row: 1/2;
                        grid-column: 5/6;
                    }
                    &:nth-of-type(7) {
                        grid-row: 2/3;
                        grid-column: 5/6;
                    }
                    &:nth-of-type(8) {
                        grid-row: 1/2;
                        grid-column: 6/7;
                    }
                    &:nth-of-type(9) {
                        grid-row: 2/3;
                        grid-column: 6/7;
                    }
                    &:nth-of-type(10) {
                        grid-row: 1/2;
                        grid-column: 7/8;
                    }
                    &:nth-of-type(11) {
                        grid-row: 2/3;
                        grid-column: 7/8;
                    }
                    &:nth-of-type(12) {
                        grid-row: 1/3;
                        grid-column: 8/9;
                    }
                    &:nth-of-type(13) {
                        grid-row: 1/3;
                        grid-column: 9/10;
                    }

                    &:nth-of-type(12) {
                        ul {
                            li {
                                font-size: 12px;
                                word-break: break-all;

                                & + li {
                                    margin-top: 4px;
                                }
                            }
                        }
                    }

                    &.checkbox{
                        position: relative;
                    }
                }

                th {
                    min-height: 32px;
                    font-weight: normal;
                    background-color: #f4f4f4;
                }

                input[type="checkbox"] {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                    width: 24px;
                    height: 24px;
                    margin: 0;
                    opacity: 0;
                    cursor: pointer;
                    transform: translate( -50%, -50%);

                    & + span{
                        display: flex;
                        align-items: center;

                        &::before {
                            content: "";
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            margin-right: 0.5em;
                            pointer-events: none;
                            background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                        }
                    }
                    &:checked + span::before {
                        background-image: url(${iconCheckboxOn});
                    }
                }

            }
        }
    `;
    const BarList = styled("section")`
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        & > div:last-child {
            a {
                color: #f4f4f4;
                background-color: #3a3e5d;
                border-color: #3a3e5d;
                border-radius: 2px;
                display: flex;
                height: 32px;
                width: 116px;
                text-decoration: none;
                justify-content: center;
                align-items: center;
            }
        }
    `;

    const handleToggleAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedTerminalIds(terminals.data?.map((t) => t.id) || []);
        } else {
            setSelectedTerminalIds([]);
        }
    };

    return (
        <div>
            <BarList>
                <Stack spacing={1} direction="row">
                    <Button type="button" onClick={downloadSettings}>
                        コンフィグをダウンロード
                    </Button>
                </Stack>

                <Stack spacing={1} direction="row">
                    <ModalLink to={`/organizations/${organizationId}/terminals/create`}>新規作成</ModalLink>
                </Stack>
            </BarList>

            <DeliveryGroupTable>
                <thead>
                    <tr>
                        <th className="cell_1 checkbox">
                            <input
                                type="checkbox"
                                checked={selectedTerminalIds.length > 0}
                                onChange={handleToggleAll}
                            /><span></span>
                        </th>
                        <th className="cell_2">ID</th>
                        <th className="cell_3">端末名</th>
                        <th className="cell_4">MANAGE ID</th>
                        <th className="cell_5">Token</th>
                        <th className="cell_6">IP</th>
                        <th className="cell_7">mask</th>
                        <th className="cell_8">GW</th>
                        <th className="cell_9">DNS</th>
                        <th className="cell_10">モニター型番</th>
                        <th className="cell_11">向き</th>
                        <th className="cell_12 monitorInfo">モニター</th>
                        <th className="cell_13">操作</th>
                    </tr>
                </thead>

                <tbody>
                    {terminals.data?.map((terminal) => (
                        <tr key={terminal.id}>
                            <td className="cell_1 checkbox">
                                <input
                                    type="checkbox"
                                    checked={selectedTerminalIds.includes(terminal.id)}
                                    onChange={(e) => handleSelect(terminal, e.target.checked)}
                                /><span></span>
                            </td>
                            <td className="cell_2">{terminal.id}</td>
                            <td className="cell_3">{terminal.name}</td>
                            <td className="cell_4">{terminal.manageId}</td>
                            <td className="cell_5">{terminal.token}</td>
                            <td className="cell_6" title={"IP:" + terminal.terminalIp}>
                                {terminal.terminalIp}
                            </td>
                            <td className="cell_7" title={"mask:" + terminal.terminalMask}>
                                {terminal.terminalMask}
                            </td>
                            <td className="cell_8" title={"GW:" + terminal.terminalGw}>
                                {terminal.terminalGw}
                            </td>
                            <td className="cell_9" title={"DNS:" + terminal.terminalDns}>
                                {terminal.terminalDns}
                            </td>
                            <td className="cell_10">{terminal.monitorModel}</td>
                            <td className="cell_11">{monitor_direction(terminal.direction)}</td>
                            <td className="cell_12 monitorInfo">
                                <ul>
                                    {terminal.monitorInfo?.map((monitor) => (
                                        <li key={monitor.id}>
                                            ID {monitor.id}: IP {monitor.ip}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            <td className="cell_13">
                                <EditMenuMinimum
                                    menuEdit={<button onClick={handleEdit(terminal.id)}>編集</button>}
                                    menuDelete={<button onClick={handleDelete(terminal.id)}>削除</button>}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </DeliveryGroupTable>
        </div>
    );
}
