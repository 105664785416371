import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../components/Copyright";
import { FormErrorMessage } from "../components/FormErrorMessage";
import login from "../api-clients/actions/login";
import { useDispatch } from "react-redux";
import { updated as tokenUpdated } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDisabledSubmit, setDisabledSubmit] = React.useState(false);
    const [errorText, setErrorText] = React.useState("");

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        setErrorText("");
        setDisabledSubmit(true);
        login(new FormData(e.currentTarget))
            .then((token) => {
                dispatch(tokenUpdated(token));
                navigate("/");
            })
            .catch((e: Error) => {
                setErrorText(e.message);
                setDisabledSubmit(false);
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" gutterBottom>
                        LOGIN
                    </Typography>

                    {/* エラーメッセージを表示するUI */}
                    <FormErrorMessage message={errorText} />

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="login-id"
                            label="ログインID"
                            name="login_id"
                            autoComplete="login-id"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="パスワード"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isDisabledSubmit}
                        >
                            {" "}
                            ログイン
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
