import produce from "immer";
import { FormEvent, useState } from "react";
import { SchemaDefinitionItemFormRequest } from "../gateways/cms/schema";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";
import iconPlus from "../assets/icons/icon_plus.svg";
import iconDelete from "../assets/icons/icon_delete.svg"
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

// style
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;
const ButtonSq = styled(Button)`
    &.MuiButtonBase-root{
        /* height: 32px; */
        width: 32px;
        min-width: 32px;
        border-width: 2px;
        /* border-radius: 2px; */
        
        color: transparent;
        overflow: hidden;
        user-select: none;
        cursor: pointer;

        background-color: #fff; 
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;

        *{
            color: transparent;
            white-space: nowrap;
        }
    }
`;
const ButtonDelete = styled(ButtonSq)`
    &.MuiButtonBase-root{
        border-width: 2px;
        background-image: url(${iconDelete});
        border-color: transparent;
    }
`;
const ButtonPlus = styled(ButtonSq)`
    &.MuiButtonBase-root{
        display: block;
        margin: 0 auto;
        background-image: url(${iconPlus});
        background-size: 62.5%;
        background-color: #3A3E5D;
    }
`;
const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;
const SchemaUL = styled('ul')`
    .SignageRadio {
        display: flex;
        flex-wrap: wrap;
        .SignageLabel {
            width: 100%;
            margin: 5px 0 5px 5px;
        }
        .SignageItem {
            margin-right: 20px;
        }
    }
`;

export interface Props {
    data: SchemaDefinitionItemFormRequest;
    onSubmit: (data: SchemaDefinitionItemFormRequest) => unknown;
    onDismiss: () => unknown;
}

export function SchemaDefinitionItemEditForm({ data, onSubmit, onDismiss }: Props) {
    const [fieldName, setFieldName] = useState(data.fieldName ?? "");
    const [title, setTitle] = useState(data.title ?? "");
    const [contentType, setContentType] = useState(data.content?.type ?? "signage");
    const [contentRequired, setContentRequired] = useState(data.content?.required ?? false);
    const [contentInfo, setContentInfo] = useState(data.content?.info ?? "");
    const [contentItems, setContentItems] = useState(Object.entries(data.content?.item || {}));

    const [signageVideo, setSignageVideo] = useState(data.content?.radioItem?.signageVideo ?? true);
    const [signageImage, setSignageImage] = useState(data.content?.radioItem?.signageImage ?? true);
    const [signageUrl, setSignageUrl] = useState(data.content?.radioItem?.signageUrl ?? true);
    const [signageText, setSignageText] = useState(data.content?.radioItem?.signageText ?? true);

    const hasContentItems = () => contentType && ["checkbox", "select", "radio"].includes(contentType);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            id: data.id,
            fieldName,
            title,
            content: {
                type: contentType,
                required: contentRequired,
                info: contentInfo,
                item: hasContentItems() ? Object.fromEntries(contentItems || []) : undefined,
                radioItem: {
                    signageVideo,
                    signageImage,
                    signageUrl,
                    signageText,
                },
            },
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <main>
                <SchemaUL>
                    <li>
                        <label htmlFor="fieldName">フィールド名</label>
                        <input type="text" value={fieldName} required onChange={(e) => setFieldName(e.target.value)} />
                    </li>
                    <li>
                        <label htmlFor="title">表示名</label>
                        <input type="text" value={title} required onChange={(e) => setTitle(e.target.value)} />

                    </li>
                    <li>
                        <textarea
                            value={contentInfo}
                            placeholder="注意事項など補足を記載する場合は入力してください"
                            onChange={(e) => setContentInfo(e.target.value)}
                        ></textarea>
                    </li>
                    <li>
                        <label htmlFor="contentType">入力・選択方法</label>
                        <SelectWithIcon value={contentType} required onChange={(e) => setContentType(e.target.value)}>
                            <option value="signage">サイネージ用</option>
                            <option value="file">ファイル</option>
                            <option value="text">短文記述式</option>
                            <option value="textarea">長文記述式</option>
                            <option value="checkbox">チェックボックス</option>
                            <option value="select">セレクトボックス</option>
                            <option value="radio">ラジオボタン</option>
                        </SelectWithIcon>

                    </li>
                    <li>
                        <label htmlFor="contentRequired">任意/必須</label>
                        <SelectWithIcon
                            value={Number(contentRequired)}
                            onChange={(e) => setContentRequired(Boolean(Number(e.target.value)))}
                        >
                            <option value="0">任意</option>
                            <option value="1">必須</option>
                        </SelectWithIcon>
                    </li>

                    {hasContentItems() && (
                        <li>
                            <label htmlFor="contentItems">選択肢</label>
                            {contentItems.map((item, i) => (
                                <div key={i}>
                                    <input
                                        type="text"
                                        value={item[1]}
                                        onChange={(e) =>
                                            setContentItems(
                                                produce(contentItems, (draft) => {
                                                    draft[i][1] = e.target.value;
                                                })
                                            )
                                        }
                                    />
                                    <input
                                        type="text"
                                        value={item[0]}
                                        required
                                        onChange={(e) =>
                                            setContentItems(
                                                produce(contentItems, (draft) => {
                                                    draft[i][0] = e.target.value;
                                                })
                                            )
                                        }
                                    />
                                    <ButtonDelete
                                        type="button"
                                        onClick={() =>
                                            setContentItems(
                                                produce(contentItems, (draft) => {
                                                    draft.splice(i, 1);
                                                })
                                            )
                                        }
                                    >
                                        削除
                                    </ButtonDelete>
                                </div>
                            ))}
                            <ButtonPlus type="button" onClick={() => setContentItems([...contentItems, ["", ""]])}>
                                追加
                            </ButtonPlus>
                        </li>
                    )}
                    {(contentType === "signage") && (
                        <li className="SignageRadio">
                            <label className="SignageLabel" htmlFor="checkItems">選択可能サイネージタイプ</label>
                            <label className="SignageItem" htmlFor="checkVideo">
                                <input type="checkbox" id="checkVideo" checked={signageVideo} onChange={(e) => setSignageVideo(e.target.checked)} />動画</label>
                            <label className="SignageItem" htmlFor="checkImage">
                                <input type="checkbox" id="checkImage" checked={signageImage} onChange={(e) => setSignageImage(e.target.checked)} />画像</label>
                            <label className="SignageItem" htmlFor="checkUrl">
                                <input type="checkbox" id="checkUrl" checked={signageUrl} onChange={(e) => setSignageUrl(e.target.checked)} />URL</label>
                            <label className="SignageItem" htmlFor="checkText">
                                <input type="checkbox" id="checkText" checked={signageText} onChange={(e) => setSignageText(e.target.checked)} />短文</label>
                        </li>
                    )}
                </SchemaUL>
            </main>

            <footer>
                <section className="buttons">
                    <Button type="button" variant="outlined" onClick={onDismiss}>
                        キャンセル
                    </Button>
                    <Button type="submit" variant="contained" disableElevation>
                        保存
                    </Button>
                </section>
            </footer>


        </form>
    );
}
