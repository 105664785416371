// import { Button } from "@mui/material";
import { getYear, getMonth } from "date-fns";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BillingDataDownloadRequest } from "../gateways/cms/billingData";
import { billingDataDownload } from "../gateways/cms/billingDataDownload";
import { RootState } from "../store";
import { SelectMonth } from "./SelectMonth";
import { SelectYear } from "./SelectYear";

import { styled } from "@mui/system";
import ButtonMUI from "@material-ui/core/Button";

export interface Props {
    organizationId: number;
    billingRuleId: number;
}

export function OrganizationBillingDataDownloadForm({ organizationId, billingRuleId }: Props) {
    const token = useSelector((state: RootState) => state.auth.token);
    const [year, setYear] = useState(getYear(new Date()));
    const [month, setMonth] = useState(getMonth(new Date()) + 1);

    const download = async (request: BillingDataDownloadRequest) => {
        const response = await billingDataDownload(token, request);
        if (response.ok) {
            window.open(URL.createObjectURL(await response.blob()));
        } else {
            alert("error");
        }
    };

    const handleSubmit = () => {
        download({
            year: String(year),
            month: String(month).padStart(2, "0"),
            organizationIds: [organizationId],
            billingRuleId,
        });
    };

    // style
    const BillingDataDownload = styled("div")`
        padding-left: 32px;

        select{
            min-width: 6em;
            margin-right: 16px;
            padding: 4px 8px;
        }
    `;
    const Button = styled(ButtonMUI)`
        &,
        &.MuiButton-outlined,
        &.MuiButton-contained {
            width: 116px;
            height: 32px;
            border-radius: 2px;
            padding: 0;
        }
        &.MuiButton-outlined {
            background-color: #fff;
            border: 2px solid #C9CBD3;
        }
        &.MuiButton-contained {
            color: #f4f4f4;
            background-color: #3A3E5D;
            border-color: #3A3E5D;
        }
    `;
    
    return (
        <BillingDataDownload>
            <SelectYear value={year} onChange={setYear} />
            <SelectMonth value={month} onChange={setMonth} />
            <Button variant="contained" disableElevation onClick={handleSubmit}>ダウンロード</Button>
        </BillingDataDownload>
    );
}
