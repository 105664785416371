import { useParams, useLocation } from "react-router-dom";
import { useGetArticleQuery } from "../../gateways/cms/articleApi";
import { ArticleDuplicateModal } from "../../components/ArticleDuplicateModal";

export const ArticleDuplicate = () => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const { id } = useParams() as { id: string };
    const type = query.get('type');
    const deliveryGroupId = query.get('deliveryGroupId');
    const article = useGetArticleQuery(id);

    if (!article.data) {
        return <div>loading...</div>;
    }

    return <ArticleDuplicateModal 
                article={article.data}
                type={String(type)}
                deliveryGroupId={Number(deliveryGroupId)}
            />;
};
