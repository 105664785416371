import { FormEvent, ReactNode, useState } from "react";
import { DeliveryGroupForm } from "../gateways/cms/deliveryGroup";

import { styled } from "@mui/system";

export interface Props {
    children: ReactNode;
    data?: DeliveryGroupForm;
    onSubmit: (data: DeliveryGroupForm) => unknown;
}

// style
const InputItem = styled("div")`
    margin-bottom: 24px;

    label {
        display: block;
        margin-bottom: 4px;
    }
    input[type="text"]{
        width: 100%;
        height: 32px;
    }
`;

export function DeliveryGroupEditForm({ children, data, onSubmit }: Props) {
    const [name, setName] = useState(data?.name ?? "");

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            ...data,
            name,
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <main>
                <InputItem>
                    <label htmlFor="name">配信グループ名</label>
                    <input type="text" value={name} required onChange={(e) => setName(e.target.value)} />
                </InputItem>
            </main>

            {children}
        </form>
    );
}
