import { useSearchParams } from "react-router-dom";
import { OrganizationTaskCreatePage } from "./OrganizationTaskCreatePage";
import { TerminalTaskCreatePage } from "./TerminalTaskCreatePage";

export function TaskCreatePage() {
    const [searchParams] = useSearchParams();

    const organizationId = searchParams.get("organizationId");
    if (organizationId) {
        return <OrganizationTaskCreatePage organizationId={organizationId} />;
    }

    const terminalId = searchParams.get("terminalId");
    if (terminalId) {
        return <TerminalTaskCreatePage terminalId={terminalId} />;
    }

    throw new Error("No match");
}
