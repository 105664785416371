//import { useState, useEffect, useLayoutEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
    selectOrganizationBreadcrumb,
    setFirstOrganization,
    setFourthOrganization,
    setSecondOrganization,
    setThirdOrganization,
} from "../features/cms/organizationBreadcrumbSlice";
import { SelectOrganizationChildren } from "./SelectOrganizationChildren";

export function OrganizationBreadcrumb() {
    const breadcrumb = useSelector(selectOrganizationBreadcrumb);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const getStorage = (key: string) => {
        if(localStorage.getItem(key)){
            return JSON.parse(localStorage.getItem(key) || "");
        }else{
            return null;
        }
    }

    const setBreadCrumb = (id: string, key: string) => {
        // CLASS1 変更
        if(key === "bc_first"){
            localStorage.removeItem("bc_second");
            localStorage.removeItem("bc_third");
            localStorage.removeItem("bc_fourth");
            if(!id){
                localStorage.removeItem(key);
                localStorage.removeItem("organization_id");
                //localStorage.setItem(key, "");
                //localStorage.setItem("organization_id", "");
            }
            dispatch(setFirstOrganization(id));
        // CLASS2 変更
        }else if(key === "bc_second"){
            localStorage.removeItem("bc_third");
            localStorage.removeItem("bc_fourth");
            if(!id){
                localStorage.setItem(key, (localStorage.getItem("bc_first") || ""));
                localStorage.setItem("organization_id", (localStorage.getItem("bc_first") || ""));
            }
            dispatch(setSecondOrganization(id));
        }
        // CLASS3 変更
        else if(key === "bc_third"){
            localStorage.removeItem("bc_fourth");
            if(!id){
                localStorage.setItem(key, (localStorage.getItem("bc_second") || ""));
                localStorage.setItem("organization_id", (localStorage.getItem("bc_second") || ""));
            }
            dispatch(setThirdOrganization(id));
        }
        // CLASS4 変更
        else if(key === "bc_fourth"){
            if(!id){
                localStorage.setItem(key, (localStorage.getItem("bc_third") || ""));
                localStorage.setItem("organization_id", (localStorage.getItem("bc_third") || ""));
            }
            dispatch(setFourthOrganization(id));
        }
        // ローカルストレージに値設定
        if(id){
            localStorage.setItem(key, JSON.stringify(id));
            localStorage.setItem("organization_id", JSON.stringify(id));
        }
        // テナント移動したらページを1に設定
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    }

    return (
        <>
            <SelectOrganizationChildren
                value={breadcrumb.first || getStorage('bc_first')}
                key="bc_first"
                onChange={(id) => setBreadCrumb(id, "bc_first")}
            />
            <span>&gt;</span>
            <SelectOrganizationChildren
                value={breadcrumb.second || getStorage('bc_second')}
                parent={breadcrumb.first || getStorage('bc_first')}
                disabled={!breadcrumb.first && !getStorage('bc_first')}
                key="bc_second"
                onChange={(id) => setBreadCrumb(id, "bc_second")}
            />
            <span>&gt;</span>
            <SelectOrganizationChildren
                value={breadcrumb.third || getStorage('bc_third')}
                parent={breadcrumb.second || getStorage('bc_second')}
                disabled={!breadcrumb.second && !getStorage('bc_second')}
                key="bc_third"
                onChange={(id) => setBreadCrumb(id, "bc_third")}
            />
            <span>&gt;</span>
            <SelectOrganizationChildren
                value={breadcrumb.fourth || getStorage('bc_fourth')}
                parent={breadcrumb.third || getStorage('bc_third')}
                disabled={!breadcrumb.third && !getStorage('bc_third')}
                key="bc_fourth"
                onChange={(id) => setBreadCrumb(id, "bc_fourth")}
            />
            {/* 
            <strong>{breadcrumb.id}</strong>,{breadcrumb.first},{breadcrumb.second},{breadcrumb.third},{breadcrumb.fourth}
             */}
        </>
    );
}
