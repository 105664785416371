// import { Button } from "@mui/material";
import produce from "immer";
import { DeliverySchedule } from "../gateways/cms/deliveryRule";
import { DeliveryRuleList } from "./DeliveryRuleList";
import { DeliveryRuleListItem } from "./DeliveryRuleListItem";
import ButtonMUI from "@material-ui/core/Button";

import { styled } from "@mui/system";
import VarConst from "../styles/variables.js";
// import Color from "../styles/colors.js";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";
import iconEdit from "../assets/icons/icon_pen.svg";
import assert from "assert";
import { DefaultDeliveryRuleEditModal } from "./DefaultDeliveryRuleEditModal";
import { useState } from "react";

// [稼働スケジュール管理] テナントスケジュール

// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;

const SectionDefaultTitle = styled("h2")`
    margin: 0 0 24px;
    padding: 10px 10px 10px 40px;
    height: 52px;
    font-weight: normal;
    font-size: 20px;
    background-color: #f4f4f4;
    line-height: 1;
    h3 {
        margin: 3px 0 0;
        font-size: 12px;
        font-weight: normal;
        flex-wrap: nowrap
    }

    & ~ div {
        margin: 24px 24px 48px;

        input,
        select {
            height: 32px;
            border: ${VarConst.border1};
        }

        input[type="date"],
        input[type="time"] {
            padding-left: 0.5em;
        }

        label {
            display: flex;
            margin-bottom: 8px;

            & ~ input[type="text"],
            & + select {
                width: 100%;
            }
            & input[type="date"] {
                width: 12em;
            }
            & input[type="time"] {
                width: 6em;
            }
        }

        table {
            border-collapse: collapse;
            width: 45%;
            min-width: 900px;

            th,
            td {
                border: 1px solid #daddde;
                text-align: left;
                padding: 0 8px;

                /* &:nth-of-type(1){
                    vertical-align:top;
                } */
                &.on,
                &.off {
                    width: 12em;
                }
                &.checkbox,
                &.function{
                    width: 3em;
                    min-width: 3em;
                    max-width: 3em;
                    text-align: center;
                }
                &.function{
                    padding: 0;
                }

                &.checkbox {
                    position: relative;

                    input[type="checkbox"] {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 1;
                        width: 24px;
                        height: 24px;
                        margin: 0;
                        opacity: 0;
                        cursor: pointer;
                        transform: translate( -50%, -50%);
                        
                        & + span::before {
                            content: "";
                            display: block;
                            width: 24px;
                            height: 24px;
                            pointer-events: none;
                            background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                        }
                        &:checked + span::before {
                            background-image: url(${iconCheckboxOn});
                        }
                    }
                }
            }
            td {
                height: 56px;
            }
            thead {
                th {
                    height: 32px;
                    font-weight: normal;
                    background-color: #f4f4f4;
                }
            }
        }
    }
`;
const SectionTitle = styled("h2")`
    margin: 0 0 24px;
    padding: 10px 10px 10px 40px;
    height: 40px;
    font-weight: normal;
    font-size: 20px;
    background-color: #f4f4f4;
    line-height: 1;

    & ~ div {
        margin: 24px 24px 48px;

        input,
        select {
            height: 32px;
            border: ${VarConst.border1};
        }

        input[type="date"],
        input[type="time"] {
            padding-left: 0.5em;
        }

        label {
            display: flex;
            margin-bottom: 8px;

            & ~ input[type="text"],
            & + select {
                width: 100%;
            }
            & input[type="date"] {
                width: 12em;
            }
            & input[type="time"] {
                width: 6em;
            }
        }

        table {
            border-collapse: collapse;
            width: 45%;
            min-width: 900px;

            th,
            td {
                border: 1px solid #daddde;
                text-align: left;
                padding: 0 8px;

                /* &:nth-of-type(1){
                    vertical-align:top;
                } */
                &.on,
                &.off {
                    width: 12em;
                }
                &.checkbox,
                &.function{
                    width: 3em;
                    min-width: 3em;
                    max-width: 3em;
                    text-align: center;
                }
                &.function{
                    padding: 0;
                }

                &.checkbox {
                    position: relative;

                    input[type="checkbox"] {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 1;
                        width: 24px;
                        height: 24px;
                        margin: 0;
                        opacity: 0;
                        cursor: pointer;
                        transform: translate( -50%, -50%);
                        
                        & + span::before {
                            content: "";
                            display: block;
                            width: 24px;
                            height: 24px;
                            pointer-events: none;
                            background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                        }
                        &:checked + span::before {
                            background-image: url(${iconCheckboxOn});
                        }
                    }
                }
            }
            td {
                height: 56px;
            }
            thead {
                th {
                    height: 32px;
                    font-weight: normal;
                    background-color: #f4f4f4;
                }
            }
        }
    }
`;
const BarList = styled("section")`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;
const Button = styled(ButtonMUI)`
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
        border-radius: 2px;
    }
`;
const ButtonEdit = styled(ButtonMUI)`
    &.MuiButtonBase-root {
        height: 24px;
        width: 24px;
        min-width: 24px;
        border: 2px solid #C9CBD3;;
        border-radius: 2px;

        color: transparent;
        overflow: hidden;
        user-select: none;
        cursor: pointer;

        background-color: #fff;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(${iconEdit});
        background-size: 80%;
    }
`;

export interface Props {
    title?: string;
    subtitle?: string;
    schedules: DeliverySchedule[];
    selectedIds: number[];
    onSelect: (selectedIds: number[]) => unknown;
    onCreate: () => unknown;
    onEdit: (schedule: DeliverySchedule, index: number) => unknown;
    onUpdate: (schedule: DeliverySchedule) => Promise<unknown>;
    onDelete: (scheduleId: number) => unknown;
    onBulkDelete: (scheduleIds: number[]) => unknown;
    scheduleTitle:string;
}

export function DeliveryScheduleListTemplate({
    title,
    subtitle,
    schedules,
    selectedIds,
    onCreate,
    onEdit,
    onUpdate,
    onSelect,
    onDelete,
    onBulkDelete,
    scheduleTitle,
}: Props) {
    // TODO: selectedは内部で完結してもいいかも
    const handleSelect = (schedule: DeliverySchedule, selected: boolean) => {
        onSelect(
            produce(selectedIds, (draft) => {
                if (selected) {
                    draft.push(schedule.id);
                } else {
                    draft.splice(
                        selectedIds.findIndex((id) => id === schedule.id),
                        1
                    );
                }
            })
        );
    };
    const [editingDefaultSchedule, editDefaultSchedule] = useState<DeliverySchedule>();

    const defaultSchedules = schedules.filter((s) => s.type === 2);
    const restSchedules = schedules.filter((s) => s.type !== 2);

    const handleEditDefaultSchedule = (schedule: DeliverySchedule) => () => {
        assert(schedule.type === 2);
        editDefaultSchedule(schedule);
    };

    const handleSelectDeliveryRule = (selecting: boolean) => {
        if (selecting) {
            onSelect(schedules.map((s) => s.id));
        } else {
            onSelect([]);
        }
    };

    const handleUpdateDefaultSchedule = async (data: DeliverySchedule) => {
        await onUpdate(data);
        editDefaultSchedule(undefined);
    };

    return (
        <div>
            <PageTitle>
                <h1>
                    {title}
                    <span>{subtitle}</span>
                </h1>

                <Button type="button" variant="contained" disableElevation onClick={onCreate}>
                    新規作成
                </Button>
            </PageTitle>

            <section>
                {scheduleTitle === "テナントスケジュール" ? (
                    <SectionTitle>標準の起動スケジュール</SectionTitle>
                ):(
                    <SectionDefaultTitle>
                        標準の起動スケジュール
                        <h3>※標準の起動スケジュールはテナントスケジュール編集時のみ編集できます</h3>
                    </SectionDefaultTitle>
                )}
                {/* TODO: 見た目の調整 */}
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>電源ON</th>
                                {scheduleTitle === "テナントスケジュール" && (
                                    <th className="function">操作</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {defaultSchedules.map((schedule) => (
                                <tr key={schedule.id}>
                                    <td>
                                        <span>{schedule.startTime}</span>
                                        〜 <span>{schedule.endTime}</span>
                                    </td>
                                    {scheduleTitle === "テナントスケジュール" && (
                                        <td className="function">
                                            <ButtonEdit type="button" onClick={handleEditDefaultSchedule(schedule)}>
                                                編集
                                            </ButtonEdit>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <section>
                <SectionTitle>{scheduleTitle}</SectionTitle>
                <div>
                    <BarList>
                        <Button type="button" onClick={() => onBulkDelete(selectedIds)}>
                            削除
                        </Button>
                    </BarList>

                    <DeliveryRuleList selected={selectedIds.length > 0} onSelect={handleSelectDeliveryRule}>
                        {restSchedules.map((s, i) => (
                            <DeliveryRuleListItem
                                key={s.id}
                                item={s}
                                selected={selectedIds.includes(s.id)}
                                onSelect={handleSelect}
                                onEdit={() => onEdit(s, i)}
                                onDelete={() => onDelete(s.id)}
                            />
                        ))}
                    </DeliveryRuleList>
                </div>
            </section>

            {editingDefaultSchedule && (
                <DefaultDeliveryRuleEditModal
                    defaultValues={editingDefaultSchedule}
                    onSubmit={handleUpdateDefaultSchedule}
                    onDismiss={() => editDefaultSchedule(undefined)}
                />
            )}
        </div>
    );
}
