import assert from "assert";

export async function getAddress(zipCode: string) {
    assert(zipCode);
    const data = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}&limit=1`).then((response) =>
        response.json()
    );
    if (data.results[0]) {
        return data.results[0].address1 + data.results[0].address2 + data.results[0].address3;
    }
}
