import { baseUrl } from "../../config/api";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { RootState } from "../../store";

export const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        headers.set("accept", "application/json");
        return headers;
    },
});
