import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: "",
    },
    reducers: {
        updated: (state, action: PayloadAction<string>) => {
            console.log("token updated", action.payload);
            state.token = action.payload;
        },
        revoked: (state) => {
            state.token = "";
        },
    },
});

export const { updated, revoked } = authSlice.actions;

export default persistReducer(
    {
        key: "auth",
        storage,
    },
    authSlice.reducer
);
