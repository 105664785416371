import { getYear } from "date-fns";

import { styled } from "@mui/system";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value: number;
    onChange: (value: number) => unknown;
}

const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;    
`;

const currentYear = getYear(new Date());
const years = Array.from(Array(100), (_, i) => currentYear - i);

export function SelectYear({ value, onChange }: Props) {
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(Number(e.target.value))}>
            {years.map((year) => (
                <option key={year} value={year}>
                    {year}年
                </option>
            ))}
        </SelectWithIcon>
    );
}
