import { AxiosResponse } from "axios";
import { createAxios, defaultCatch } from "../axiosUtil";
import CmsHttpError from "../../errors/CmsHttpError";

interface LoginResponse {
    token: string;
}

export default function resetMyPassword(data: FormData): Promise<any> {
    return createAxios()
        .post<LoginResponse>("/users/reset-my-password", data)
        .then((response: AxiosResponse<LoginResponse, any>) => {

            return Promise.resolve(response.data.token);
        })
        .catch<string>((reason: any) => {
            let message = "";
            let [status, defaultMessage] = defaultCatch(reason, {
                401: () => {
                    message = reason.response?.data?.message ?? "";
                },
            });

            // 401以外の時は、デフォルトメッセージを利用する
            if (401 !== status) {
                message = defaultMessage;
            }

            return Promise.reject(CmsHttpError.factory([status, message]));
        });
}
